export type StopAction = (whenS: number) => void;

export interface IPlayResult {
  sourceNode: AudioBufferSourceNode;
  gainNode: GainNode;
  stopAction: StopAction;
}

export function playBuffer(
  audioContext: BaseAudioContext,
  buffer: AudioBuffer,
  startTimeS: number,
  gain = 1.0,
  bufferOffsetTimeS = 0,
  durationS = buffer.duration,
  fadeInDurationS = 0.0,
  fadeOutDurationS = 0.05,
  onended: (() => void) | undefined = undefined,
): IPlayResult {
  // nodes
  const sourceNode = audioContext.createBufferSource();
  const gainNode = audioContext.createGain();
  // params
  sourceNode.buffer = buffer;
  sourceNode.onended = () => {
    gainNode.disconnect();
    sourceNode.disconnect();

    if (onended !== undefined) {
      onended();
    }
  };

  gainNode.gain.value = gain;

  // connections
  sourceNode.connect(gainNode);

  // scheduling
  if (fadeInDurationS > 0) {
    gainNode.gain.setValueAtTime(0.0, startTimeS);
    gainNode.gain.linearRampToValueAtTime(startTimeS + fadeInDurationS, gain);
  }
  sourceNode.start(startTimeS, bufferOffsetTimeS, durationS);

  const stopAction = (whenS: number) => {
    const now = audioContext.currentTime;
    const safeWhenS = Math.max(whenS, now);
    if (safeWhenS > now) {
      const safeFadeOutDurationS = Math.max(fadeOutDurationS, safeWhenS - now);
      gainNode.gain.setValueAtTime(1.0, safeWhenS);
      gainNode.gain.linearRampToValueAtTime(0.0, safeWhenS + safeFadeOutDurationS);
    }
    sourceNode.stop(safeWhenS);
  };

  return { sourceNode, gainNode, stopAction };
}
