import { midiToFrequency } from "../../../../../../midi/midiToFrequency";
import { shuffle } from "../../../../../../utils/shuffle";
import { IAppContext } from "../../../../app-main/api";
import { AudioHandler } from "../../../audio/AudioHandler";

export const playbackConfig = {
  controlsVisible: true,
};

// TODO move this to config
const tonic = 62;
const intervals = [0, 2, 3, 5, 7, 8, 10];
// const intervals = [0];

const NOTE_OFFSET_S = 0.05;
const GAIN = 1;

const choose = <T>(items: T[]) => items[Math.floor(Math.random() * items.length)];

const getNote = () => {
  const octaves = [0, 12];
  const note = tonic + choose(intervals) + choose(octaves);
  return note;
};
const getChord = (numNotes: number) => {
  const rootNotes = [0, 5, 7, 8];
  const rootNote = choose(rootNotes);
  const rootIndex = intervals.indexOf(rootNote);
  if (rootIndex === -1) {
    throw new Error("note not in scale");
  }
  const notes = Array(...Array(numNotes)).map((_, i) => {
    const rawIndex = rootIndex + i * 2;
    const wrappedIndex = rawIndex % intervals.length;
    const octave = rawIndex < intervals.length ? 0 : 12;
    const interval = intervals[wrappedIndex];

    return tonic + interval + octave;
  });

  return notes;
};

const ontouchstart = (e: TouchEvent) => {
  e.preventDefault();
  const el = e.target as HTMLButtonElement;
  if (el && el.click) {
    el.click();
  }
};

const timeouts = new Map<string, number>();
export const playback = (ctx: IAppContext, audioHandler: AudioHandler) => {
  const noteDurationS = ctx.state.deref().audio.noteDurationS;

  const buttonStyle = {
    width: "100px",
    height: "60px",
    background: "rgb(50, 50, 50)",
    "margin-top": "10px",
    color: "rgb(220, 220, 220)",
    "font-size": "15px",
    "text-align": "center",
    transition: "opacity 0.25s",
    opacity: playbackConfig.controlsVisible ? 1 : 0,
    "pointer-events": playbackConfig.controlsVisible ? "initial" : "none",
  };

  const flash = (event: Event, id: string) => {
    clearTimeout(timeouts.get(id));
    const button = event.target as HTMLButtonElement;
    button.classList.add("active");
    timeouts.set(
      id,
      window.setTimeout(() => {
        button.classList.remove("active");
      }, 175),
    );
  };

  return [
    "div.playButtons",
    {},
    [
      "button",
      {
        ontouchstart,
        onclick: (event: Event) => {
          const note = getNote();
          const gain = GAIN / 2;

          audioHandler.playNote(NOTE_OFFSET_S, midiToFrequency(note), gain, noteDurationS);

          flash(event, "note");
        },
        style: buttonStyle,
      },
      "Note",
    ],

    [
      "button",
      {
        ontouchstart,
        onclick: (event: Event) => {
          const numNotes = choose([3, 4, 5]);
          const notes = getChord(numNotes);
          const gain = GAIN / numNotes;
          notes.forEach((note) => {
            audioHandler.playNote(NOTE_OFFSET_S, midiToFrequency(note), gain, noteDurationS);
          });

          flash(event, "chord");
        },
        style: buttonStyle,
      },
      "Chord",
    ],
    [
      "button",
      {
        ontouchstart,
        onclick: (event: Event) => {
          const numNotes = 4 + Math.floor(Math.random() * 6);
          const chord = getChord(numNotes);
          const notes = shuffle(chord);
          const gain = GAIN / (numNotes + 1);
          const tempo = 100;

          audioHandler.playNote(NOTE_OFFSET_S, midiToFrequency(tonic), gain, noteDurationS);
          const chosen: number = choose([2, 4]);
          const gap = 60 / tempo / chosen;
          notes.forEach((note, i) => {
            const extraChance = chosen === 2 ? 0.5 : 0.1;
            const doExtra = Math.random() < extraChance;
            const count = doExtra ? 2 : 1;
            const thisGap = doExtra ? gap / count : 0;
            const groupStartTimeS = NOTE_OFFSET_S + i * gap;
            for (let n = 0; n < count; n++) {
              const thisNote = 12 + (n === 0 ? note : getNote());
              audioHandler.playNote(
                groupStartTimeS + n * thisGap,
                midiToFrequency(thisNote),
                gain,
                noteDurationS,
              );
            }

            flash(event, "melody");
          });
        },
        style: buttonStyle,
      },
      "Melody",
    ],
  ];
};
