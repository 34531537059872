import { IAudioState } from "../../app-main/api";

export const preset: IAudioState = {
  noteDurationS: 1.5,
  chorus: {
    voices: [
      {
        id: 2,
        gain: 0,
        depth: 0,
        speed: 0.500990099009901,
      },
      {
        id: 1,
        gain: 0,
        depth: 0,
        speed: 0.013861386138613862,
      },
      {
        id: 3,
        gain: 0,
        depth: 0,
        speed: 1,
      },
    ],
    startValues: [
      {
        id: 2,
        gain: 0,
        depth: 0,
        speed: 0.500990099009901,
      },
      {
        id: 1,
        gain: 0,
        depth: 0,
        speed: 0.013861386138613862,
      },
      {
        id: 3,
        gain: 0.09185441941074522,
        depth: 0.09185441941074522,
        speed: 1,
      },
    ],
  },
  harmonics: {
    gains: [
      0.5148938474870017, 0.14227686308492205, 0.13534445407279028, 0.11628032928942811,
      0.11628032928942811, 0.11628032928942811, 0.12321273830155977, 0,
    ],
    minValue: 0.1,
    maxGain: 0.5,
  },
  delayVerb: {
    delays: [
      {
        delayTimeS: 0.0,
        gain: 0.0,
        id: 0,
      },
    ],
    nextDelayId: 4,
    reverbGain: 0.8578314558058926,
    reverbDecayS: 1.8712871287128714,
    maxTimeS: 2,
    isSettingReverb: false,
    isCreatingDelay: false,
    canDeleteDelay: false,
  },
  envelope: {
    envelope: [
      [0, 0],
      [0, 0],
      [0.000824742268041237, 0.00495810055865924],
      [0.0014845360824742265, 0.023575418994413403],
      [0.0022185567010309273, 0.06901024208566107],
      [0.0038367010309278346, 0.11563798882681565],
      [0.008223999999999999, 0.17266491620111735],
      [0.012970952577319585, 0.22997232029795162],
      [0.016356143505154637, 0.2658465206703911],
      [0.019064296247422678, 0.2873710408938548],
      [0.02123081844123711, 0.3017755109422719],
      [0.02296403619628866, 0.31116307192849163],
      [0.024556795967340207, 0.3167956085202234],
      [0.02603718935119175, 0.320920009432432],
      [0.028664803027345153, 0.32488440789409606],
      [0.03221019293734004, 0.3287528048854334],
      [0.04432485538079986, 0.3340533589089137],
      [0.06659390492319658, 0.33723369132300185],
      [0.0938936806395882, 0.33914189077145473],
      [0.12645515069723756, 0.34028681044052644],
      [0.15889607932067665, 0.341718641199139],
      [0.19185913149777842, 0.3425777396543065],
      [0.23101307839409901, 0.343093198727407],
      [0.2775939678699184, 0.3434024741712673],
      [0.3247555866670687, 0.34433291839475294],
      [0.3727941600553044, 0.3448911849288443],
      [0.42029718371434666, 0.34522614484929914],
      [0.4659284686209618, 0.345427120801572],
      [0.5108871047936767, 0.34554770637293575],
      [0.5538643230101991, 0.345620057715754],
      [0.5921636233566129, 0.3449185895642755],
      [0.6256896615718882, 0.3444977086733884],
      [0.6586960591544178, 0.34424518013885613],
      [0.6945857133029156, 0.34409366301813676],
      [0.7282458902299613, 0.3440027527457052],
      [0.7615657843489175, 0.34394820658224623],
      [0.7958505656234639, 0.34391547888417084],
      [0.8255464318802144, 0.3424060843509867],
      [0.8509526094216973, 0.3407555686739067],
      [0.8735455926919969, 0.3382755013533198],
      [0.8938880205453501, 0.3345528240894593],
      [0.913048560766177, 0.33082945981680406],
      [0.92940792077789, 0.32636080438170256],
      [0.9424954087872605, 0.32144497424913326],
      [0.9527592136277465, 0.3035978970262025],
      [0.961382628634156, 0.28618574007791886],
      [0.9691061029073248, 0.26903453529442356],
      [0.9752848823258599, 0.24980526493829286],
      [0.9802279058606879, 0.2226252446240558],
      [0.9841823246885503, 0.20259283764966626],
      [0.9873458597508402, 0.1838694828505074],
      [0.9898766878006722, 0.1681661962279953],
      [0.9919013502405377, 0.15278519259713239],
      [0.9935210801924301, 0.13610780084692004],
      [0.9948168641539441, 0.11790769726792855],
      [0.9958534913231553, 0.09357981389148338],
      [0.9966827930585243, 0.06110598889354927],
      [0.9973462344468194, 0.03666359333612956],
      [0.9978769875574555, 0.021998156001677736],
      [0.9983015900459644, 0.013198893601006642],
      [0.9986412720367716, 0.007919336160603984],
      [0.9989130176294172, 0.00475160169636239],
      [0.9991304141035338, 0.0028509610178174337],
      [0.999304331282827, 0.0017105766106904601],
      [1, 0],
    ],
  },
  waveform: {
    handles: [
      {
        waveformPoint: [0, 1],
        size: 40,
        isGrabbed: false,
        isHovered: false,
      },
      {
        waveformPoint: [0.25, 1],
        size: 40,
        isGrabbed: false,
        isHovered: false,
      },
      {
        waveformPoint: [0.5, 1],
        size: 40,
        isGrabbed: false,
        isHovered: false,
      },
      {
        waveformPoint: [0.5, -1],
        size: 40,
        isGrabbed: false,
        isHovered: false,
      },
      {
        waveformPoint: [1, -1],
        size: 40,
        isGrabbed: false,
        isHovered: false,
      },
    ],
    segments: [
      {
        handleIdxA: 0,
        handleIdxB: 1,
        isHovered: true,
        isGrabbed: false,
        control: [0.125, 1],
      },
      {
        handleIdxA: 1,
        handleIdxB: 2,
        isHovered: true,
        isGrabbed: false,
        control: [0.375, 1],
      },
      {
        handleIdxA: 2,
        handleIdxB: 3,
        isHovered: true,
        isGrabbed: false,
        control: [0.5, -0.5],
      },
      {
        handleIdxA: 3,
        handleIdxB: 4,
        isHovered: true,
        isGrabbed: false,
        control: [0.75, -1],
      },
    ],
  },
  lfo: {
    frequency: 7.176017601760177,
    depth: 0,
    minFrequency: 1,
    maxFrequency: 20,
  },
  vowel: {
    vowels: [
      {
        name: "a",
        gain: 0,
      },
      {
        name: "e",
        gain: 0.011373483535528583,
      },
      {
        name: "i",
        gain: 0.9886265164644716,
      },
      {
        name: "o",
        gain: 0,
      },
      {
        name: "u",
        gain: 0,
      },
    ],
  },
};
