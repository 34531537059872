import { fit10, fitClamped } from "@thi.ng/math";
import { map, range } from "@thi.ng/transducers";
import { IRect } from "../../../../../../api";

const DEBUG = false;

export const chorusWave = (
  rect: IRect,
  normLevel: number,
  normWaves: number,
  state: "normal" | "hover" | "grab",
) => {
  const top = fit10(normLevel, rect.y, rect.y + rect.h);
  const right = rect.x + rect.w;

  const numWaves = fitClamped(normWaves, 0, 1, 2, 20);
  const step = rect.w / numWaves;

  const waveHeight = rect.h * 0.07;
  const curveMid = top + waveHeight * 0.5;

  return [
    [
      "defs",
      {},
      [
        "linearGradient",
        { id: "normal", from: [rect.x, rect.y], to: [rect.x, rect.y + rect.h] },
        [
          [0, "rgba(43, 156, 212, 0.2)"],
          [1, "rgba(43, 156, 212, 0.5)"],
        ],
      ],
      [
        "linearGradient",
        { id: "hover", from: [rect.x, rect.y], to: [rect.x, rect.y + rect.h] },
        [
          [0, "rgba(43, 156, 212, 0.3)"],
          [1, "rgba(43, 156, 212, 0.6)"],
        ],
      ],
      [
        "linearGradient",
        { id: "grab", from: [rect.x, rect.y], to: [rect.x, rect.y + rect.h] },
        [
          [0, "rgba(43, 156, 212, 0.4)"],
          [1, "rgba(43, 156, 212, 0.7)"],
        ],
      ],
    ],
    [
      "path",
      {
        fill: `$${state}`,

        stroke: {
          grab: "rgb(190, 190, 190)",
          hover: "rgb(170, 170, 170)",
          normal: "rgb(43, 156, 212)",
        }[state],
      },
      [
        ["M", [right, curveMid]],
        ...map((i: number) => {
          // right to left
          const idx = numWaves - i;
          const controlX = idx * step - step * 0.5;
          const controlY = curveMid + (i % 2) * 2 * waveHeight - waveHeight;
          const endX = (idx - 1) * step;
          const endY = curveMid;
          return ["Q", [controlX, controlY], [endX, endY]];
        }, range(numWaves)),
        ["V", rect.h],
        ["H", right],
        ["V", curveMid],
      ],
    ],

    DEBUG
      ? [
          ["rect", { stroke: "white" }, [rect.x, top], rect.w, 1],
          ["rect", { fill: "red" }, [rect.x, curveMid], rect.w, 2],
        ]
      : null,
  ];
};
