import { Attribs } from "@thi.ng/hiccup-html";

import { Point } from "../../../../../../api";

export const handle = (point: Point, radius: number, attribs: Partial<Attribs>) => {
  return ["circle", attribs, point, radius];

  // TODO iOS doesn't support svg as image source, need to parse using umbrella/sax and draw as path
  // const img = document.createElement("img");
  // img.src = "/assets/arrow-right.svg";
  // return ["img", attribs, scalePoint(handleEnvPoint), img, img.width * 0.2, img.height * 0.2];
};
