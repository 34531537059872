import { Attribs } from "@thi.ng/hiccup-html";
import { fitClamped } from "@thi.ng/math";

import { IRect } from "../../../../../../api";
import { IDelayVoice } from "../../../../app-main/api";

export const getStarRect = (
  delay: IDelayVoice,
  maxTimeS: number,
  parentRect: IRect,
  minSize?: number,
): IRect => {
  const maxSize = Math.max(parentRect.w, parentRect.h) * 0.1;
  const minTargetSize = 40;
  const targetSize = fitClamped(delay.gain, 0, 1, minTargetSize, maxSize);
  const size = minSize === undefined ? targetSize : Math.max(targetSize, minSize);
  const x = parentRect.x + ((delay.delayTimeS / maxTimeS) * parentRect.w - size / 2);
  const y = parentRect.y + ((1 - delay.gain) * parentRect.h - size / 2);

  return { x, y, w: size, h: size };
};

export const TOUCH_SIZE = 50;

const DEBUG = false;

export const star = (rect: IRect, attribs: Partial<Attribs>) => {
  const points = [
    [0.499, 0.0],
    [0.625, 0.353],
    [1.0, 0.353],
    [0.7, 0.579],
    [0.809, 0.937],
    [0.499, 0.719],
    [0.186, 0.937],
    [0.289, 0.579],
    [0.0, 0.353],
    [0.374, 0.353],
  ].map((point) => point.map((v, i) => v * rect.w - [rect.w / 2, rect.h / 2][i]));

  const translatedAttribs = {
    ...attribs,
    translate: [rect.x + rect.w / 2, rect.y + rect.h / 2],
  };

  return [
    [
      "path",
      translatedAttribs,
      [["M", points[0]], ...points.map((point) => ["L", point]), ["L", points[0]]],
    ],
    DEBUG
      ? [
          [
            "rect",
            { ...translatedAttribs, rotate: 0, fill: "rgba(255, 255, 255, 0.2)", stroke: "black" },
            [-TOUCH_SIZE / 2, -TOUCH_SIZE / 2],
            TOUCH_SIZE,
            TOUCH_SIZE,
          ],
          [
            "rect",
            { ...translatedAttribs, fill: "rgba(212, 100, 100, 0.5)" },
            [-rect.w / 2, -rect.h / 2],
            rect.w,
            rect.h,
          ],
        ]
      : [],
  ];
};
