import { FeedbackDelayNode } from "../../../../audio/FeedbackDelayNode";
import { ReverbNode } from "../../../../audio/ReverbNode";
import { constrain } from "../../../../utils/constrain";
import { IDelayNodes, IDelayVoice } from "../../app-main/api";

const reverbNodes = {
  reverbNode: undefined as ReverbNode | undefined,
  gainNode: undefined as GainNode | undefined,
};

export function onReverbGainChange(gain: number, whenS: number) {
  if (reverbNodes.gainNode === undefined) {
    return;
  }

  const safeGain = constrain(gain, 0, 1);
  if (safeGain === reverbNodes.gainNode.gain.value) {
    return;
  }

  reverbNodes.gainNode.gain.linearRampToValueAtTime(safeGain, whenS);
}

export function createReverb(
  audioContext: BaseAudioContext,
  inputNode: AudioNode,
  outputNode: AudioNode | AudioDestinationNode,
  decayS: number,
  gain: number,
) {
  reverbNodes.reverbNode = new ReverbNode(audioContext);
  reverbNodes.gainNode = audioContext.createGain();
  reverbNodes.reverbNode.decayS = decayS;
  onReverbGainChange(gain, 0);

  inputNode.connect(reverbNodes.reverbNode.inputNode);
  reverbNodes.reverbNode.connect(reverbNodes.gainNode);
  reverbNodes.gainNode.connect(outputNode);
}

export function disconnectReverb() {
  if (reverbNodes.reverbNode !== undefined) {
    reverbNodes.reverbNode.disconnect();
  }
  if (reverbNodes.gainNode !== undefined) {
    reverbNodes.gainNode.disconnect();
  }
}

export function onReverbDecaySChange(decayS: number) {
  if (reverbNodes.reverbNode === undefined) {
    return;
  }
  if (decayS === reverbNodes.reverbNode.decayS) {
    return;
  }
  reverbNodes.reverbNode.decayS = decayS;
}

const delayVoices = new Map<IDelayVoice["id"], IDelayNodes>();

function createDelayVoice(
  audioContext: BaseAudioContext,
  inputNode: AudioNode,
  dryOutputNode: AudioNode,
  id: IDelayVoice["id"],
  maxDelayTimeS: number,
) {
  const delayNode = new FeedbackDelayNode(audioContext, maxDelayTimeS);
  const gainNode = audioContext.createGain();

  inputNode.connect(delayNode.inputNode, 0, 0);
  delayNode.connect(gainNode);

  if (reverbNodes.reverbNode === undefined) {
    throw new Error("ReverbNode not created");
  }

  gainNode.connect(reverbNodes.reverbNode.inputNode);
  gainNode.connect(dryOutputNode);

  delayVoices.set(id, { delayNode, gainNode, inputNode });

  const newGain = 1.0 / delayVoices.size;
  Array.from(delayVoices.values()).forEach((chorusVoice) => {
    chorusVoice.gainNode.gain.linearRampToValueAtTime(newGain, audioContext.currentTime + 0.01);
  });
}

function updateDelayVoice(audioContext: BaseAudioContext, voice: IDelayVoice) {
  const voiceNodes = delayVoices.get(voice.id);
  if (voiceNodes === undefined) {
    return;
  }

  const timeS = audioContext.currentTime + 0.01;
  voiceNodes.delayNode.delayTimeS.linearRampToValueAtTime(voice.delayTimeS, timeS);
  voiceNodes.gainNode.gain.linearRampToValueAtTime(voice.gain, timeS);
}

function removeDelayVoice(id: IDelayVoice["id"]) {
  const voiceNodes = delayVoices.get(id);
  if (voiceNodes === undefined) {
    return;
  }

  // TODO fades etc
  voiceNodes.inputNode.disconnect(voiceNodes.delayNode.inputNode, 0, 0);
  voiceNodes.delayNode.disconnect();
  voiceNodes.gainNode.disconnect();
  delayVoices.delete(id);
}

export function onVoicesChange(
  audioContext: BaseAudioContext,
  inputNode: AudioNode,
  dryOutputNode: AudioNode,
  oldVoices: IDelayVoice[],
  newVoices: IDelayVoice[],
  maxDelayTimeS: number,
) {
  const toAdd = newVoices.filter((voice) => oldVoices.find((v) => v.id === voice.id) === undefined);
  toAdd.forEach((voice) =>
    createDelayVoice(audioContext, inputNode, dryOutputNode, voice.id, maxDelayTimeS),
  );

  const toRemove = oldVoices.filter(
    (voice) => newVoices.find((v) => v.id === voice.id) === undefined,
  );
  toRemove.forEach((voice) => removeDelayVoice(voice.id));

  newVoices.forEach((voice) => updateDelayVoice(audioContext, voice));
}
