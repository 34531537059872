export interface IRect {
  [key: string]: number;
  x: number;
  y: number;
  w: number;
  h: number;
}

export type Point = number[];

export interface IPosition {
  [key: string]: number;
  x: number;
  y: number;
}

export function isIPosition(thing: unknown): thing is IPosition {
  const cast = thing as IPosition;
  return cast !== undefined && typeof cast.x === "number" && typeof cast.y === "number";
}

export type Synth = "Triangle" | "Sine" | "Sampler";

export interface IRoomObject {
  id: string;
  rect: IRect;
  shape: "rect" | "circle";
  fill: string;
  stroke: string;
}

export interface IRoom {
  x_m: number;
  y_m: number;
  objects: IRoomObject[];
}

export type ClientMode = "normal" | "performance";
export const CLIENT_MODES: ClientMode[] = ["normal", "performance"];
