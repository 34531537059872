import { anchor } from "@thi.ng/hiccup-html";
import { map } from "@thi.ng/transducers";
import { IAppContext } from "../../../app-main/api";

export function credits(ctx: IAppContext) {
  return [
    "div",
    { style: { "line-height": "1.4em" } },
    ["h3", "Credits"],
    [
      "ul",
      ...Array.from(
        map(
          (credit) => [
            [
              "li",
              ["div", { style: { "font-weight": "bold" } }, credit.name],
              ["div", credit.title],
              [
                "div",
                credit.links.map((link) =>
                  anchor(
                    {
                      href: link.url,
                      style: {
                        display: "block",
                        color: "#ccc",
                      },
                      target: "_blank",
                    },
                    link.display,
                  ),
                ),
              ],
              ["br"],
            ],
          ],
          ctx.config.credits,
        ),
      ),
    ],
  ];
}
