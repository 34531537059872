import { fit01 } from "@thi.ng/math";
import { map, range, mapcat } from "@thi.ng/transducers";
import { IRect, Point } from "../../../../../../api";
import { ILFOState } from "../../../../app-main/api";

export const bottomComponent = (
  lfoState: ILFOState,
  bodyRect: IRect,
  lineWeight: number,
  halfLineWeight: number,
  clampPoint: (point: Point, bodyRect_: IRect) => Point,
) => {
  const pointPairs = Array.from(
    map((i) => {
      const step = 1 / lfoState.frequency;
      const width = 0.5;
      const stepWidth = step * width;
      const movePoint = clampPoint([i * step + stepWidth, lfoState.depth], bodyRect);
      const linePoint = clampPoint([(i + 1) * step, lfoState.depth], bodyRect);

      if (movePoint[0] >= bodyRect.x + bodyRect.w) {
        return [];
      }

      linePoint[0] -= halfLineWeight;

      const y = fit01(
        lfoState.depth,
        bodyRect.y + halfLineWeight,
        bodyRect.y + bodyRect.h - halfLineWeight,
      );

      movePoint[1] = y;
      linePoint[1] = y;

      return [movePoint, linePoint];
    }, range(lfoState.frequency)),
  );

  const lines = Array.from(
    mapcat(([movePoint, linePoint]) => {
      return movePoint === undefined || linePoint === undefined
        ? []
        : [
            ["M", movePoint],
            ["L", linePoint],
          ];
    }, pointPairs),
  );

  return ["path", { stroke: "rgb(43, 156, 212)", weight: lineWeight }, lines];
};
