import { Point } from "../../../../../../api";

export const eye = (
  x: number,
  y: number,
  radius: number,
  pupilRatio: number,
  pupilFill: string,
  focusPoint?: Point,
) => {
  const pupilRadius = radius * pupilRatio;
  const pupilPos =
    focusPoint !== undefined
      ? (() => {
          const movementRadius = radius - pupilRadius * 1.7;
          const inputRadians = Math.atan2(focusPoint[1] - y, focusPoint[0] - x);
          const pupilX = Math.cos(inputRadians) * movementRadius + x;
          const pupilY = Math.sin(inputRadians) * movementRadius + y;
          return [pupilX, pupilY];
        })()
      : [x, y];

  return [
    ["circle", { fill: "white", stroke: "black", weight: 1 }, [x, y], radius],
    ["circle", { fill: pupilFill }, pupilPos, pupilRadius],
  ];
};
