import { map } from "@thi.ng/transducers";

import { IRect, IRoom } from "../../api";

export const roomComponent = (room: IRoom, rect: IRect) => {
  const x = rect.x;
  const y = rect.y;

  const longestSide = Math.max(room.x_m, room.y_m);
  const rectSide = rect.w > rect.h ? Math.max(rect.w, rect.h) : Math.min(rect.w, rect.h);
  const normW = room.x_m / longestSide;
  const normH = room.y_m / longestSide;
  const w = rectSide * normW;
  const h = rectSide * normH;

  return [
    ["rect", { stroke: "rgb(255, 255, 255, 0.5)", weight: 1 }, [x, y], w, h],

    ...map((object) => {
      const scaledRect: IRect = {
        x: rect.x + object.rect.x * w,
        y: rect.y + object.rect.y * h,
        w: object.rect.w * w,
        h: object.rect.h * h,
      };

      const halfSide = Math.max(scaledRect.w, scaledRect.h) * 0.5;
      const center = [scaledRect.x + scaledRect.w * 0.5, scaledRect.y + scaledRect.h * 0.5];

      const attribs = { fill: object.fill, stroke: object.stroke, weight: 1 };
      const shape =
        object.shape === "rect"
          ? ["rect", attribs, [scaledRect.x, scaledRect.y], scaledRect.w, scaledRect.h]
          : ["circle", attribs, center, halfSide];

      return shape;
    }, room.objects),
  ];
};
