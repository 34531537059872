import { map } from "@thi.ng/transducers";
import { IRect } from "../../../../../../api";
import { font } from "../../../../../../utils/font";

export const frameComponent = (
  bodyRect: IRect,
  margin: number,
  relPos: (norm: number, axis: "x" | "y", bodyRect_: IRect) => number,
) => {
  const strokeStyle = { stroke: "rgb(255, 255, 255)", weight: 0.5 };
  const dashLength = margin * 0.2;
  const textStyle = {
    fill: strokeStyle.stroke,
    font: font(bodyRect.h * 0.025),
    baseline: "middle",
  };

  return [
    ["rect", { ...strokeStyle }, [bodyRect.x, bodyRect.y], bodyRect.w, bodyRect.h],
    ...Array.from(
      map(
        (normY) => {
          const y = relPos(1 - normY, "y", bodyRect);
          const leftX = relPos(0, "x", bodyRect);
          const rightX = relPos(1, "x", bodyRect);
          const gap = 5;

          return [
            [
              "path",
              { ...strokeStyle },
              [
                ["M", [leftX, y]],
                ["L", [leftX + dashLength, y]],
                ["M", [rightX, y]],
                ["L", [rightX - dashLength, y]],
              ],
            ],
            ["text", { ...textStyle, align: "right" }, [leftX - gap, y], `${normY}`],
            ["text", { ...textStyle, align: "left" }, [rightX + gap, y], `${normY}`],
          ];
        },
        [0, 0.5, 1],
      ),
    ),
  ];
};
