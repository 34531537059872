import { IObjectOf } from "@thi.ng/api";

import { ClientMode, IPosition, isIPosition, Synth } from "../api";
import { Device, DEVICES } from "../utils/getDevice";
import { isArrayOf } from "../utils/isArrayOf";

// Message Types
// -----------------------------------------------------------------------------

export type ServerMessageType = "SyncResponse";

export type SharedMessageType =
  | "Heartbeat"
  | "Sync"
  | "SyncDone"
  | "Note"
  | "ClientMode"
  | "LoadBuffer"
  | "PlayBuffer"
  | "StopBuffer"
  | "ControlsVisible"
  | "Channels"
  | "Reload";

export type MusicianMessageType = "Name" | "Update";

export type ConductorMessageType = "MusicianConnected" | "MusicianDisconnected" | "MusicianUpdated";

export type MessageType =
  | ServerMessageType
  | SharedMessageType
  | MusicianMessageType
  | ConductorMessageType;

const messageTypes: MessageType[] = [
  "SyncResponse",
  "Heartbeat",
  "Sync",
  "SyncDone",
  "Note",
  "ClientMode",
  "LoadBuffer",
  "PlayBuffer",
  "StopBuffer",
  "ControlsVisible",
  "Channels",
  "Reload",
  "Name",
  "Update",
  "MusicianConnected",
  "MusicianDisconnected",
  "MusicianUpdated",
];

function isValidMessageType(msgType: unknown): msgType is MessageType {
  return msgType !== undefined && messageTypes.includes(msgType as MessageType);
}

// Generic Message Interfaces
// -----------------------------------------------------------------------------

export interface IMessageDef<T_type extends MessageType, T_data = undefined> {
  type: T_type;
  data: T_data;
}

export interface IUnsentMessage {
  id: number;
  type: MessageType;
}

export interface IMessage extends IUnsentMessage {
  timestampMs: number;
}

export function isIMessage(message: unknown): message is IMessage {
  const castMessage = message as IMessage;
  return (
    castMessage !== undefined &&
    isValidMessageType(castMessage.type) &&
    typeof castMessage.id === "number" &&
    typeof castMessage.timestampMs === "number"
  );
}

// Sync Messages
// -----------------------------------------------------------------------------

// Sync
export type ISyncMessageDef = IMessageDef<"Sync">;

export type SyncMessage = IMessage & ISyncMessageDef;

export function isSyncMessage(message: unknown): message is SyncMessage {
  return isIMessage(message) && message.type === "Sync";
}

// SyncDone

export type ISyncDoneMessageDef = IMessageDef<"SyncDone", { serverOffsetMs: number }>;

export type SyncDoneMessage = IMessage & ISyncDoneMessageDef;

export function isSyncDoneMessage(message: unknown): message is SyncDoneMessage {
  const castMessage = message as SyncDoneMessage;
  return (
    isIMessage(castMessage) &&
    castMessage.type === "SyncDone" &&
    castMessage.data !== undefined &&
    typeof castMessage.data.serverOffsetMs === "number"
  );
}

// SyncResponse
export type ISyncResponseMessageDef = IMessageDef<
  "SyncResponse",
  {
    serverRequestTime: number;
    serverResponseTime: number;
  }
>;

export type SyncResponseMessage = IMessage & ISyncResponseMessageDef;

export function isSyncResponseMessage(message: unknown): message is SyncResponseMessage {
  const castMessage = message as SyncResponseMessage;
  return (
    isIMessage(castMessage) &&
    castMessage.type === "SyncResponse" &&
    castMessage.data !== undefined &&
    typeof castMessage.data.serverRequestTime === "number" &&
    typeof castMessage.data.serverResponseTime === "number"
  );
}

// Messages for 1+ Musicians
// -----------------------------------------------------------------------------
interface PayloadForMusicians {
  musicianIds: number[] | undefined;
}

export type IMessageDefForMusicians<
  T_type extends MessageType,
  T_data extends PayloadForMusicians = PayloadForMusicians,
> = IMessageDef<T_type, T_data>;

export function isIMessageDefForMusicians<
  T_type extends MessageType,
  T_data extends PayloadForMusicians,
>(message: unknown): message is IMessageDefForMusicians<T_type, T_data> {
  const castMessage = message as IMessageDefForMusicians<T_type, T_data>;
  return (
    castMessage !== undefined &&
    castMessage.data !== undefined &&
    (castMessage.data.musicianIds === undefined ||
      isArrayOf(castMessage.data.musicianIds, "number"))
  );
}

export type MessageForMusicians<
  T_type extends MessageType,
  T_data extends PayloadForMusicians,
> = IMessage & IMessageDefForMusicians<T_type, T_data>;

export function isMessageForMusicians<
  T_type extends MessageType,
  T_data extends PayloadForMusicians,
>(message: unknown): message is MessageForMusicians<T_type, T_data> {
  return isIMessage(message) && isIMessageDefForMusicians(message);
}

// Reload Message
export type IReloadMessageDef = IMessageDefForMusicians<"Reload">;

export function isIReloadMessageDef(message: unknown): message is IReloadMessageDef {
  const castMessage = message as IReloadMessageDef;
  return (
    castMessage !== undefined && castMessage.type === "Reload" && isIMessageDefForMusicians(message)
  );
}

export type ReloadMessage = IMessage & IReloadMessageDef;

export function isReloadMessage(message: unknown): message is ReloadMessage {
  return isIMessage(message) && isIReloadMessageDef(message);
}

// Note Messages
// -----------------------------------------------------------------------------

export interface INote {
  note: number;
  durationS: number;
  gain: number;
  synth: Synth;
}

export function isINote(note: unknown): note is INote {
  const castNote = note as INote;
  return (
    castNote !== undefined &&
    typeof castNote.note === "number" &&
    typeof castNote.durationS === "number" &&
    typeof castNote.gain === "number" &&
    (castNote.synth === "Sampler" || castNote.synth === "Sine" || castNote.synth === "Triangle")
  );
}

export type INoteMessageDef = IMessageDefForMusicians<
  "Note",
  {
    musicianIds: number[] | undefined;
    startTimeS: number;
    note: INote;
  }
>;

export function isINoteMessageDef(message: unknown): message is INoteMessageDef {
  const castMessage = message as INoteMessageDef;
  return (
    castMessage !== undefined &&
    isIMessageDefForMusicians(message) &&
    castMessage.type === "Note" &&
    castMessage.data !== undefined &&
    isINote(castMessage.data.note) &&
    typeof castMessage.data.startTimeS === "number"
  );
}

export type NoteMessage = IMessage & INoteMessageDef;

export function isNoteMessage(message: unknown): message is NoteMessage {
  return isIMessage(message) && isINoteMessageDef(message);
}

// Heartbeat Messages
// -----------------------------------------------------------------------------

export type IHeartBeatMessageDef = IMessageDef<"Heartbeat">;

export type HeartBeatMessage = IMessage & IHeartBeatMessageDef;

export function isHeartBeatMessage(message: unknown): message is HeartBeatMessage {
  return isIMessage(message) && message.type === "Heartbeat";
}

// Route Messages
// -----------------------------------------------------------------------------

export type IClientModeMessageDef = IMessageDefForMusicians<
  "ClientMode",
  {
    mode: ClientMode;
    musicianIds: number[] | undefined;
  }
>;

export type ClientModeMessage = IMessage & IClientModeMessageDef;

export function isClientModeMessage(message: unknown): message is ClientModeMessage {
  const castMessage = message as ClientModeMessage;
  return (
    isIMessage(castMessage) &&
    isIMessageDefForMusicians(message) &&
    castMessage.type === "ClientMode" &&
    castMessage.data !== undefined &&
    (castMessage.data.mode === "normal" || castMessage.data.mode === "performance")
  );
}

// Controls Visible Messages
// -----------------------------------------------------------------------------

export type IControlsVisibleMessageDef = IMessageDefForMusicians<
  "ControlsVisible",
  {
    visible: boolean;
    musicianIds: number[] | undefined;
  }
>;

export type ControlsVisibleMessage = IMessage & IControlsVisibleMessageDef;
export function isControlsVisibleMessage(message: unknown): message is ControlsVisibleMessage {
  const castMessage = message as ControlsVisibleMessage;
  return (
    isIMessage(castMessage) &&
    isIMessageDefForMusicians(message) &&
    castMessage.type === "ControlsVisible" &&
    castMessage.data !== undefined &&
    typeof castMessage.data.visible === "boolean"
  );
}

// Channels Messages
// -----------------------------------------------------------------------------

export interface IChannel {
  id: number;
  soundfile: string;
  position: IPosition;
}

export function isIChannel(data: unknown): data is IChannel {
  const castData = data as IChannel;
  return (
    castData !== undefined &&
    typeof castData.id === "number" &&
    typeof castData.soundfile === "string" &&
    isIPosition(castData.position)
  );
}

export type IChannelsMessageDef = IMessageDefForMusicians<
  "Channels",
  {
    channels: IChannel[];
    musicianIds: number[] | undefined;
  }
>;

export type ChannelsMessage = IMessage & IChannelsMessageDef;

export function isChannelsMessage(message: unknown): message is ChannelsMessage {
  const castMessage = message as ChannelsMessage;
  return (
    isIMessage(castMessage) &&
    isIMessageDefForMusicians(message) &&
    castMessage.type === "Channels" &&
    castMessage.data !== undefined &&
    castMessage.data.channels.every((x) => isIChannel(x))
  );
}

// Load Buffer Messages
// -----------------------------------------------------------------------------
export type ILoadBuffersMessageDef = IMessageDefForMusicians<
  "LoadBuffer",
  {
    urls: string[];
    musicianIds: number[] | undefined;
  }
>;

export type LoadBuffersMessage = IMessage & ILoadBuffersMessageDef;

export function isLoadBuffersMessage(message: unknown): message is LoadBuffersMessage {
  const castMessage = message as LoadBuffersMessage;
  return (
    isIMessage(castMessage) &&
    isIMessageDefForMusicians(message) &&
    castMessage.type === "LoadBuffer" &&
    castMessage.data !== undefined &&
    isArrayOf(castMessage.data.urls, "string")
  );
}

// Play Buffer Messages
// -----------------------------------------------------------------------------
export type IPlayBufferMessageDef = IMessageDefForMusicians<
  "PlayBuffer",
  {
    url: string;
    musicianIds: number[] | undefined;
    startTimeS: number;
    gain: number;
    refreshIdsBeforeSend: boolean;
    getTrackGain?: () => number;
  }
>;

export function isIPlayBufferMessageDef(message: unknown): message is IPlayBufferMessageDef {
  const castMessage = message as IPlayBufferMessageDef;
  return (
    isIMessageDefForMusicians(message) &&
    castMessage.type === "PlayBuffer" &&
    castMessage.data !== undefined &&
    typeof castMessage.data.url === "string" &&
    typeof castMessage.data.startTimeS === "number" &&
    typeof castMessage.data.gain === "number" &&
    typeof castMessage.data.refreshIdsBeforeSend === "boolean" &&
    (castMessage.data.getTrackGain === undefined ||
      typeof castMessage.data.getTrackGain === "function")
  );
}

export type PlayBufferMessage = IMessage & IPlayBufferMessageDef;

export function isPlayBufferMessage(message: unknown): message is PlayBufferMessage {
  return isIMessage(message) && isIPlayBufferMessageDef(message);
}

export type IStopBufferMessageDef = IMessageDefForMusicians<
  "StopBuffer",
  {
    musicianIds: number[] | undefined;
    playMessageId: IMessage["id"];
  }
>;

export function isIStopBufferMessageDef(message: unknown): message is IStopBufferMessageDef {
  const castMessage = message as IStopBufferMessageDef;
  return (
    isIMessageDefForMusicians(castMessage) &&
    castMessage.type === "StopBuffer" &&
    castMessage.data !== undefined &&
    typeof castMessage.data.playMessageId === "number"
  );
}

export type StopBufferMessage = IMessage & IStopBufferMessageDef;

export function isStopBufferMessage(message: unknown): message is StopBufferMessage {
  return isIMessage(message) && isIStopBufferMessageDef(message);
}

// Conductor
// -----------------------------------------------------------------------------

export interface IMusicianState {
  name: string;
  position: IPosition;
  channelId: number | undefined;
  serverOffsetMs: number;
  visualLatencyMs: number;
  audioLatencyMs: number;
  bufferLoadingStates: IObjectOf<number>;
  isArmed: boolean;
}

export function isIMusicianState(data: unknown): data is IMusicianState {
  const castData = data as IMusicianState;
  return (
    castData !== undefined &&
    typeof castData.name === "string" &&
    isIPosition(castData.position) &&
    (castData.channelId === undefined || typeof castData.channelId === "number") &&
    typeof castData.serverOffsetMs === "number" &&
    typeof castData.visualLatencyMs === "number" &&
    typeof castData.audioLatencyMs === "number" &&
    castData.bufferLoadingStates !== undefined &&
    isArrayOf(Object.values(castData.bufferLoadingStates), "number") &&
    typeof castData.isArmed === "boolean"
  );
}

export interface IMusician extends IMusicianState {
  id: number;
  device: Device;
  isSynced: boolean;
  serverOffsetMs: number;
}

export function isIMusician(data: unknown): data is IMusician {
  const castData = data as IMusician;
  return (
    castData !== undefined &&
    isIMusicianState(castData) &&
    typeof castData.id === "number" &&
    DEVICES.some((device) => device === castData.device) &&
    typeof castData.isSynced === "boolean" &&
    typeof castData.serverOffsetMs === "number"
  );
}

export type IConductorMessageDef = IMessageDef<ConductorMessageType, IMusician>;

export type ConductorMessage = IMessage & IConductorMessageDef;

export function isConductorMessage(message: unknown): message is ConductorMessage {
  const castMessage = message as ConductorMessage;
  return (
    isIMessage(castMessage) &&
    (castMessage.type === "MusicianConnected" ||
      castMessage.type === "MusicianDisconnected" ||
      castMessage.type === "MusicianUpdated") &&
    isIMusician(castMessage.data)
  );
}

// Musician
// -----------------------------------------------------------------------------

// Name
export type IMusicianNameMessageDef = IMessageDef<"Name", { name: string }>;
export type MusicianNameMessage = IMessage & IMusicianNameMessageDef;

export function isMusicianNameMessage(message: unknown): message is MusicianNameMessage {
  const castMessage = message as MusicianNameMessage;

  return (
    isIMessage(castMessage) &&
    castMessage.type === "Name" &&
    castMessage.data !== undefined &&
    typeof castMessage.data.name === "string"
  );
}

// Update
export type IMusicianUpdateMessageDef = IMessageDef<"Update", IMusicianState>;

export type MusicianUpdateMessage = IMessage & IMusicianUpdateMessageDef;

export function isMusicianUpdateMessage(message: unknown): message is MusicianUpdateMessage {
  const castMessage = message as MusicianUpdateMessage;

  return (
    isIMessage(castMessage) && castMessage.type === "Update" && isIMusicianState(castMessage.data)
  );
}
