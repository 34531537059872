import { fitClamped } from "@thi.ng/math";

import { IRect, Point } from "../../../../../../api";
import { GestureHandler } from "../../../../../../utils/gestureCanvasFactory";
import { IHarmonicsState, WidgetFactory } from "../../../../app-main/api";
import { defShouldRenderForPlayback, defWidget, WidgetRender } from "../defWidget";
import { eyes } from "../face/eyes";
import { mouth } from "../face/mouth";

const getWidth = (state: IHarmonicsState, canvasWidth: number) => {
  const numHarmonics = state.gains.length;
  const numGaps = numHarmonics + 1;
  const width = canvasWidth / (numHarmonics + numGaps);
  return width;
};

const body = (hrect: IRect, radius: number, fill: string) => {
  return [
    "path",
    { fill, stroke: "black" },
    [
      ["M", [hrect.x, hrect.y + hrect.h]],
      ["L", [hrect.x, hrect.y + radius]],
      ["C", [hrect.x, hrect.y + radius], [hrect.x, hrect.y], [hrect.x + radius, hrect.y]],
      ["L", [hrect.x + hrect.w - radius, hrect.y]],
      [
        "C",
        [hrect.x + hrect.w - radius, hrect.y],
        [hrect.x + hrect.w, hrect.y],
        [hrect.x + hrect.w, hrect.y + radius],
      ],
      ["L", [hrect.x + hrect.w, hrect.y + hrect.h]],
    ],
  ];
};

export const harmonicsFactory: WidgetFactory = (ctx, audio) => {
  const shouldRender = defShouldRenderForPlayback(audio);
  let eyesFocusPos: Point = [0, 0];

  const gestureHandler: GestureHandler = (gesture, canvasWidth, canvasHeight) => {
    const state = ctx.state.deref().audio.harmonics;
    const MAX_HARMONICS = state.gains.length;
    const MIN_VALUE = state.minValue;
    const width = getWidth(state, canvasWidth);

    eyesFocusPos = gesture.pos;

    if (gesture.type === "start" || gesture.type === "drag") {
      for (const info of gesture.active) {
        const partialIndex = Math.round(
          fitClamped(info.pos[0], width, canvasWidth - width, 0, MAX_HARMONICS - 1),
        );
        const normValue = 1.0 - info.pos[1] / canvasHeight;

        const newGains = state.gains;
        newGains[partialIndex] = normValue >= MIN_VALUE ? Math.min(normValue, 1) : 0;

        ctx.state.swapIn(["audio", "harmonics"], (swapState) => ({
          ...swapState,
          gains: newGains,
        }));

        shouldRender.set();
      }
    } else if (gesture.type === "move") {
      shouldRender.set();
    }
  };

  const render: WidgetRender = (canvas, canvasWidth, canvasHeight) => {
    const audioState = ctx.state.deref().audio;
    const state = audioState.harmonics;
    const MIN_VALUE = state.minValue;

    const width = getWidth(state, canvasWidth);
    const minMouthRadius = 1;
    const maxMouthRadius = width * 0.25;

    const getCanvasY = (partialValue: number) => canvasHeight - partialValue * canvasHeight;
    const mouthOffsetY = getCanvasY(0) - getCanvasY(MIN_VALUE) + maxMouthRadius * 0.666;

    const envGain_n = audio.getPlaybackInfo().level;

    return [
      canvas,
      { width: canvasWidth, height: canvasHeight },
      ctx.state.value.audio.harmonics.gains.map((gain, i) => {
        const clampedGain = Math.max(gain, MIN_VALUE);
        const height = clampedGain * canvasHeight;
        const x = width + i * width * 2;
        const y = getCanvasY(clampedGain);
        const fill = i === 0 ? "rgb(43, 156, 212)" : "rgba(255, 255, 255, 0.8)";
        const mouthY = mouthOffsetY + y;
        const mouthRadius = fitClamped(gain * envGain_n, 0, 1, minMouthRadius, maxMouthRadius);

        const eyesRect: IRect = {
          x,
          y: y + canvasHeight * 0.05,
          w: width,
          h: canvasHeight * 0.0333,
        };

        return [
          body({ x, y, w: width, h: height }, Math.max(1, width * 0.1666), fill),
          eyes(eyesRect, 0.4, "black", eyesFocusPos),
          mouth(x + width / 2, mouthY, mouthRadius),
        ];
      }),
    ];
  };

  return defWidget(gestureHandler, render, shouldRender);
};
