import { IAudioState } from "../../app-main/api";

export const preset: IAudioState = {
  noteDurationS: 1.5,
  chorus: {
    voices: [
      {
        id: 1,
        gain: 0,
        depth: 0,
        speed: 0,
      },
      {
        id: 2,
        gain: 0,
        depth: 0,
        speed: 0,
      },
      {
        id: 3,
        gain: 0,
        depth: 0,
        speed: 0,
      },
    ],
    startValues: [],
  },
  harmonics: {
    gains: [1, 0, 0, 0, 0, 0, 0],
    minValue: 0.1,
    maxGain: 0.5,
  },
  delayVerb: {
    delays: [
      {
        delayTimeS: 0.0,
        gain: 0.0,
        id: 0,
      },
    ],
    nextDelayId: 4,
    reverbGain: 0.5,
    reverbDecayS: 0.2,
    maxTimeS: 2,
    isSettingReverb: false,
    isCreatingDelay: false,
    canDeleteDelay: false,
  },
  envelope: {
    envelope: [
      [0, 0],
      [0, 0],
      [0.0026804123711340194, 0.006447858472998159],
      [0.005649484536082473, 0.014040968342644327],
      [0.008849484536082473, 0.025300744878957185],
      [0.01285278350515464, 0.05365810055865924],
      [0.017498721649484534, 0.10493694599627564],
      [0.02533918350515464, 0.19008041713221602],
      [0.03223010969072165, 0.2687270212290503],
      [0.03836140734020618, 0.3263432890875233],
      [0.04347263102680412, 0.36314768667411546],
      [0.04797398111010309, 0.3896995989690875],
      [0.05198743231076289, 0.410844899046257],
      [0.05561056440531134, 0.427256473878406],
      [0.058509070080950104, 0.43784829773486483],
      [0.06103406018847142, 0.4449482710059096],
      [0.06305405227448847, 0.4499531339257059],
      [0.06508241707732274, 0.45370093063475314],
      [0.06917933572371386, 0.4559496086601815],
      [0.07802388095010511, 0.45729881547543855],
      [0.09149126970853769, 0.45810833956459274],
      [0.10659507762250026, 0.4585940540180853],
      [0.12197709302583526, 0.4588854826901808],
      [0.13737548885365788, 0.4590603398934381],
      [0.15299317458808095, 0.45916525421539245],
      [0.16940484894881527, 0.4592282028085651],
      [0.18727645647863983, 0.45926597196446867],
      [0.2040479693066232, 0.4592886334580108],
      [0.22571260224942225, 0.4593022303541361],
      [0.24696183437685737, 0.4593103884918113],
      [0.2680849314190117, 0.4593152833744164],
      [0.2874576358568588, 0.45931822030397945],
      [0.3068733251803324, 0.4593199824617173],
      [0.3277667013813793, 0.45932103975636],
      [0.3488112992494333, 0.4593216741331456],
      [0.3695645033170724, 0.459322054759217],
      [0.3907031490454105, 0.45932228313485984],
      [0.4107068491332356, 0.45932242016024555],
      [0.4310397061107122, 0.45932250237547695],
      [0.4506049607648584, 0.4593225517046158],
      [0.4714118036634331, 0.4593225813020991],
      [0.48950057695136506, 0.4593225990605891],
      [0.505621080117793, 0.4585777307585136],
      [0.5193422249189767, 0.4581308097772683],
      [0.5309376974609545, 0.4578626571885211],
      [0.5404202610615472, 0.4577017656352728],
      [0.5492434253440831, 0.4568603517461544],
      [0.5585699980072253, 0.45412086654117495],
      [0.5676807406738215, 0.4517322964610178],
      [0.5755878915081293, 0.44955427545575405],
      [0.5833569111446477, 0.445267947023918],
      [0.591840168090976, 0.43524736039312173],
      [0.600276258184121, 0.4217862188429494],
      [0.610324099330802, 0.39806707581228734],
      [0.6247541248254663, 0.35850970545012845],
      [0.6391847431593422, 0.32509185678963015],
      [0.6546467635996387, 0.29237820532145037],
      [0.6686658644879584, 0.2682807406975257],
      [0.6815306297346966, 0.24935298818015414],
      [0.6938842976021903, 0.23352706292671443],
      [0.7066538298343296, 0.22030711298880334],
      [0.724292136032412, 0.19971220075417587],
      [0.747062574805311, 0.17543719009868802],
      [0.7708459361329086, 0.15491315204803965],
      [0.7931715942671517, 0.13738457651746439],
      [0.8147434609807316, 0.121653278498933],
      [0.8381865213619049, 0.1025310732446112],
      [0.8600337531719981, 0.08658847634900134],
      [0.8822538066613098, 0.0680843707256019],
      [0.9020917051228623, 0.05400239152288441],
      [0.9210548073972589, 0.04033905130106773],
      [0.9368438459178071, 0.026926894467791476],
      [0.9494750767342457, 0.016156136680674886],
      [0.9595800613873966, 0.009693682008404932],
      [1, 0],
    ],
  },
  waveform: {
    handles: [
      {
        waveformPoint: [0, 0],
        size: 40,
        isGrabbed: false,
        isHovered: false,
      },
      {
        waveformPoint: [0.25, 1],
        size: 40,
        isGrabbed: false,
        isHovered: false,
      },
      {
        waveformPoint: [0.5, 0],
        size: 40,
        isGrabbed: false,
        isHovered: false,
      },
      {
        waveformPoint: [0.75, -1],
        size: 40,
        isGrabbed: false,
        isHovered: false,
      },
      {
        waveformPoint: [1, 0],
        size: 40,
        isGrabbed: false,
        isHovered: false,
      },
    ],
    segments: [
      {
        handleIdxA: 0,
        handleIdxB: 1,
        isHovered: true,
        isGrabbed: false,
        control: [0.125, 0.5],
      },
      {
        handleIdxA: 1,
        handleIdxB: 2,
        isHovered: true,
        isGrabbed: false,
        control: [0.375, 0.5],
      },
      {
        handleIdxA: 2,
        handleIdxB: 3,
        isHovered: true,
        isGrabbed: false,
        control: [0.625, -0.5],
      },
      {
        handleIdxA: 3,
        handleIdxB: 4,
        isHovered: true,
        isGrabbed: false,
        control: [0.875, -0.5],
      },
    ],
  },
  lfo: {
    frequency: 5.144114411441144,
    depth: 0.8088235294117647,
    minFrequency: 1,
    maxFrequency: 20,
  },
  vowel: {
    vowels: [
      {
        name: "a",
        gain: 0,
      },
      {
        name: "e",
        gain: 0.5399696707105719,
      },
      {
        name: "i",
        gain: 0.46003032928942833,
      },
      {
        name: "o",
        gain: 0,
      },
      {
        name: "u",
        gain: 0,
      },
    ],
  },
};
