import { map } from "@thi.ng/transducers";

import { IAppContext } from "../../../app-main/api";

const presetComponent = (ctx: IAppContext) => {
  const presets = ctx.state.deref().presets;
  if (presets.length === 0) {
    return [];
  }

  return [
    "div",
    {},
    ["h3", "Presets"],
    [
      "div",
      {
        style: {
          display: "flex",
          "justify-content": "space-around",
          "flex-wrap": "wrap",
        },
      },
      ...Array.from(
        map(
          (preset) => [
            "button",
            {
              style: {
                width: "100px",
                height: "60px",
                background: "rgb(50, 50, 50)",
                "margin-bottom": "20px",
                color: "rgb(220, 220, 220)",
                "font-size": "15px",
                "text-align": "center",
              },
              onclick: () => {
                ctx.loadPreset(preset);
              },
            },
            preset.name,
          ],
          ctx.state.deref().presets,
        ),
      ),
    ],
  ];
};

export { presetComponent as presets };
