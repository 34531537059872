import { Point } from "../api";

export interface TouchCounter {
  point: Point;
  count: number;
}

export const DELTA_TOLERANCE_PX = 10;

const didHit = (pointA: Point, pointB: Point) =>
  Math.abs(pointA[0] - pointB[0]) <= DELTA_TOLERANCE_PX &&
  Math.abs(pointA[1] - pointB[1]) <= DELTA_TOLERANCE_PX;

export const defTouchCounter = (duration_ms: number) => {
  let timeout: ReturnType<typeof window.setTimeout>;
  let counters: TouchCounter[] = [];

  const onTouch = (gesturePoint: Point) => {
    let counter = counters.find((fcounter) => didHit(fcounter.point, gesturePoint));

    if (counter === undefined) {
      counter = { point: [...gesturePoint], count: 1 };
      counters.push(counter);
    } else {
      counter.count++;
    }

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (counter !== undefined) {
        const capCounter = counter;
        counters = counters.filter((fcounter) => didHit(fcounter.point, capCounter.point));
      }
    }, duration_ms);

    return counter.count;
  };

  return onTouch;
};
