import { rect } from "@thi.ng/geom";
import { clamp, clamp01, fit01, fit10, fitClamped, mix } from "@thi.ng/math";
import { GestureInfo } from "@thi.ng/rstream-gestures";
import { comp, map, maxCompare, mean, partition, range, transduce } from "@thi.ng/transducers";
import { clamp2 } from "@thi.ng/vectors";

import { IRect, Point } from "../../../../../../api";
import { findDo } from "../../../../../../utils/findDo";
import { didInputEnd, GestureHandler } from "../../../../../../utils/gestureCanvasFactory";
import { Envelope, WidgetFactory } from "../../../../app-main/api";
import { getEnvelopeValue } from "../../../audio/getEnvelopeValue";
import { defShouldRenderForPlayback, defWidget, WidgetRender } from "../defWidget";
import { envelope } from "./envelope";
import { face } from "./face";
import { handle } from "./handle";
import { playhead } from "./playhead";

const MARGIN = 20;

const getBodyRect = (canvasWidth: number, canvasHeight: number): IRect => {
  const bodyWidth = canvasWidth - MARGIN * 2;
  const bodyHeight = canvasHeight - MARGIN * 2;
  const bodyRect: IRect = {
    x: MARGIN,
    y: MARGIN,
    w: bodyWidth,
    h: bodyHeight,
  };
  return bodyRect;
};

const normPointToBodyPoint = (point_n: Point, bodyRect_px: IRect): Point => {
  const bodyRight = bodyRect_px.x + bodyRect_px.w;
  const bodyBottom = bodyRect_px.y + bodyRect_px.h;
  return [
    fit01(point_n[0], bodyRect_px.x, bodyRight),
    fit10(point_n[1], bodyRect_px.y, bodyBottom),
  ];
};

const bodyPointToNormPoint = (point_px: Readonly<Point>, bodyRect_px: Readonly<IRect>): Point => {
  const bodyRight = bodyRect_px.x + bodyRect_px.w;
  const bodyBottom = bodyRect_px.y + bodyRect_px.h;
  return [
    fitClamped(point_px[0], bodyRect_px.x, bodyRight, 0, 1),
    fitClamped(point_px[1], bodyRect_px.y, bodyBottom, 1, 0),
  ];
};

const smoothPoint = (point: Point, prevPoint: Point): Point => {
  const clampedPoint = [clamp(point[0], prevPoint[0], 1.0), clamp01(point[1])];

  // TODO magic numbers
  const smoothedPoint = [
    mix(clampedPoint[0], prevPoint[0], 0.8),
    mix(clampedPoint[1], prevPoint[1], 0.6),
  ];

  return smoothedPoint;
};

const createEnvelopeData = (...data: Point[]) => [...data, [1, 0]];

export const envelopeFactory: WidgetFactory = (ctx, audio) => {
  const shouldRender = defShouldRenderForPlayback(audio);

  let handlePoint: Point | undefined = undefined;
  let isFaceVisible = true;
  let infoId: GestureInfo["id"] | undefined = undefined;

  const handleRadius = MARGIN;

  const onInput = (inPoint: Readonly<Point>, bodyRect: Readonly<IRect>, shouldSmooth: boolean) => {
    const bodyRight = bodyRect.x + bodyRect.w;
    const bodyBottom = bodyRect.y + bodyRect.h;

    const safePoint = [...clamp2(null, inPoint, [bodyRect.x, bodyRect.y], [bodyRight, bodyBottom])];
    const envelopeData = ctx.state.deref().audio.envelope.envelope;
    const prevData = envelopeData.slice(0, envelopeData.length - 1);
    const prevPoint = prevData[prevData.length - 1];
    const normPoint = bodyPointToNormPoint(safePoint, bodyRect);
    const point = shouldSmooth ? smoothPoint(normPoint, prevPoint) : normPoint;
    ctx.state.resetIn(["audio", "envelope", "envelope"], createEnvelopeData(...prevData, point));
  };

  const gestureHandler: GestureHandler = (gesture, canvasWidth, canvasHeight) => {
    const bodyRect = getBodyRect(canvasWidth, canvasHeight);

    if (gesture.type === "start") {
      if (infoId === undefined) {
        infoId = gesture.active[0].id;
        handlePoint = gesture.active[0].pos;
        ctx.state.resetIn(
          ["audio", "envelope", "envelope"],
          createEnvelopeData([0, 0], bodyPointToNormPoint(handlePoint, bodyRect)),
        );
        onInput(handlePoint, bodyRect, false);
        isFaceVisible = false;
        shouldRender.set();
      }
    } else if (gesture.type === "drag") {
      findDo(
        gesture.active,
        (info) => info.id === infoId,
        (info) => {
          handlePoint = info.pos;
          onInput(info.pos, bodyRect, true);
          shouldRender.set();
        },
      );
    } else if (didInputEnd(gesture, infoId)) {
      infoId = undefined;
      handlePoint = undefined;
      isFaceVisible = true;
      shouldRender.set();
    }
  };

  const normPosToPoint = (pos_n: number, envelopeData: Envelope, bodyRect: IRect): Point => {
    const lerpedY_n = getEnvelopeValue(pos_n, envelopeData);
    const point = normPointToBodyPoint([pos_n, lerpedY_n], bodyRect);
    return point;
  };

  const getFaceRect = (envelopeData: Envelope, bodyRect: IRect): IRect => {
    const numSamples = 20;

    const facePoint_n = transduce(
      comp(
        // create interpolated points from intervals
        map((i): Point => {
          const x_n = i / numSamples;
          const y_n = getEnvelopeValue(x_n, envelopeData);
          return [x_n, y_n];
        }),
        // turn into overlapping partions
        partition(3, 2, true),
        // turn partitions into averages [meanX, meanY]
        map((window): Point => [...map((i) => mean(map((point) => point[i], window)), range(2))]),
      ),
      // find average with highest y value
      maxCompare(
        () => [0, 0],
        (a, b) => a[1] - b[1],
      ),
      range(numSamples),
    );

    const faceW_px = bodyRect.h * 0.25;
    const faceH_px = fit01(facePoint_n[1], 0.1, 0.35) * bodyRect.h;
    const minFaceY_px = bodyRect.y + bodyRect.h - faceH_px;

    const faceY_px = Math.min(
      minFaceY_px,
      fitClamped(fit01(0.5, 0, facePoint_n[1]), 0, 1, bodyRect.y + bodyRect.h, bodyRect.y) -
        faceH_px * 0.5,
    );

    const faceRect_px: IRect = {
      x: fit01(facePoint_n[0], bodyRect.x, bodyRect.x + bodyRect.w) - faceW_px * 0.5,
      y: faceY_px,
      w: faceW_px,
      h: faceH_px,
    };

    return faceRect_px;
  };

  const render: WidgetRender = (canvas, width, height: number) => {
    const bodyRect = getBodyRect(width, height);
    const envelopeData = ctx.state.deref().audio.envelope.envelope;
    const events = audio.getPlaybackEvents();

    const faceX = getFaceRect(envelopeData, bodyRect);

    const playback = audio.getPlaybackInfo();
    const focusPoint = normPointToBodyPoint([playback.pos, playback.level], bodyRect);

    return [
      canvas,
      { width, height },

      // frame
      rect([bodyRect.x, bodyRect.y], [bodyRect.w, bodyRect.h], {
        stroke: "rgb(255, 255, 255)",
        weight: 0.5,
      }),

      // envelope
      envelope(envelopeData, { fill: "rgb(43, 156, 212)", stroke: "black", weight: 1 }, (point) =>
        normPointToBodyPoint(point, bodyRect),
      ),

      // face
      isFaceVisible ? face(faceX, bodyRect, focusPoint) : null,

      // playheads
      map(
        (event) => playhead(normPosToPoint(event.elapsed_n, envelopeData, bodyRect), bodyRect),
        events,
      ),

      // handle
      handlePoint !== undefined
        ? handle(handlePoint, handleRadius, { fill: "rgba(212, 100, 100, 0.8)" })
        : null,
    ];
  };

  return defWidget(gestureHandler, render, shouldRender);
};
