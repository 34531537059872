import { IWatch } from "@thi.ng/api";
import { addThrottledWatch } from "./addThrottledWatch";

export interface ISavedState<T> {
  version: string;
  state: T;
}

export const saveLocalState = <T>(key: string, version: string, state: T) => {
  localStorage.setItem(
    key,
    JSON.stringify({
      version,
      state,
    }),
  );
};

export const loadLocalState = <T>(key: string, version: string, fn: (state: T) => void) => {
  const loadedJson = localStorage.getItem(key);
  if (loadedJson === null) {
    return false;
  }

  const loadedState: ISavedState<T> = JSON.parse(loadedJson);
  if (loadedState.version === version) {
    fn(loadedState.state);
    return true;
  }

  localStorage.removeItem(key);

  return false;
};

export const addSaveWatch = <T_State, T_Save>(
  toWatch: IWatch<T_State>,
  key: string,
  version: string,
  options?: {
    transform?: (state: T_State) => T_Save;
    waitMs?: number;
  },
) => {
  addThrottledWatch(toWatch, "save", options?.waitMs ?? 250, (_, __, state) => {
    saveLocalState(key, version, options?.transform?.(state) ?? state);
  });
};
