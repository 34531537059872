import { expStep } from "@thi.ng/math";
import { IAudioState } from "../../app-main/api";
import { initWaveformModel } from "../components/widgets/waveform-handles";

export const preset: IAudioState = {
  noteDurationS: 1.5,
  chorus: {
    voices: [
      {
        id: 1,
        gain: 0,
        depth: 0,
        speed: 0,
      },
      {
        id: 2,
        gain: 0,
        depth: 0,
        speed: 0,
      },
      {
        id: 3,
        gain: 0,
        depth: 0,
        speed: 0,
      },
    ],
    startValues: [],
  },
  harmonics: {
    gains: Array(...Array(7)).map((_, i) => (i === 0 ? 1 : 0)),
    minValue: 0.1,
    maxGain: 0.5,
  },
  delayVerb: {
    delays: [
      {
        delayTimeS: 0.0,
        gain: 0.0,
        id: 0,
      },
    ],
    nextDelayId: 1,
    reverbGain: 0.5,
    reverbDecayS: 0.2,
    maxTimeS: 2,
    isSettingReverb: false,
    isCreatingDelay: false,
    canDeleteDelay: false,
  },
  envelope: {
    envelope: Array(...Array(100)).map((_, i, arr) => {
      const peak = 0.25;
      const x = i / (arr.length - 1);
      const y = x < peak ? expStep(8, 4, x / peak) : expStep(5.2, 5.2, (1 - x) / (1 - peak));
      return [x, y];
    }),
  },
  waveform: initWaveformModel(),
  lfo: {
    frequency: 4,
    depth: 0.0,
    minFrequency: 1,
    maxFrequency: 20,
  },
  vowel: {
    vowels: [
      { name: "a", gain: 0.0 },
      { name: "e", gain: 0.0 },
      { name: "i", gain: 1.0 },
      { name: "o", gain: 0.0 },
      { name: "u", gain: 0.0 },
    ],
  },
};
