declare global {
  interface Window {
    BaseAudioContext: BaseAudioContext | undefined;
    webkitBaseAudioContext: BaseAudioContext | undefined;

    AudioContext: AudioContext | undefined;
    webkitAudioContext: AudioContext | undefined;

    OfflineAudioContext: OfflineAudioContext | undefined;
    webkitOfflineAudioContext: OfflineAudioContext | undefined;
  }
}

export function audioPolyfill() {
  const AudioContext = Object.hasOwnProperty.call(window, "AudioContext")
    ? window.AudioContext
    : Object.hasOwnProperty.call(window, "webkitAudioContext")
    ? window.webkitAudioContext
    : undefined;
  (window as Window).AudioContext = AudioContext;

  const OfflineAudioContext = Object.hasOwnProperty.call(window, "OfflineAudioContext")
    ? window.OfflineAudioContext
    : Object.hasOwnProperty.call(window, "webkitOfflineAudioContext")
    ? window.webkitOfflineAudioContext
    : undefined;
  (window as Window).OfflineAudioContext = OfflineAudioContext;

  const BaseAudioContext = Object.hasOwnProperty.call(window, "BaseAudioContext")
    ? window.BaseAudioContext
    : Object.hasOwnProperty.call(window, "OfflineAudioContext")
    ? Object.getPrototypeOf(window.OfflineAudioContext)
    : undefined;
  window.BaseAudioContext = BaseAudioContext;

  // copyToChannel polyfill
  // https://github.com/mohayonao/web-audio-api-shim

  if (!Object.hasOwnProperty.call(AudioBuffer, "copyToChannel")) {
    AudioBuffer.prototype.copyToChannel = function (source, channelNumber = 0, startInChannel = 0) {
      const end = Math.min(source.length, this.length - startInChannel);
      const clipped = source.subarray(0, end);
      this.getChannelData(channelNumber).set(clipped, startInChannel);
    };
  }

  if (!Object.hasOwnProperty.call(AudioBuffer, "copyFromChannel")) {
    AudioBuffer.prototype.copyFromChannel = function (
      destination,
      channelNumber = 0,
      startInChannel = 0,
    ) {
      const source = this.getChannelData(channelNumber).subarray(startInChannel);
      const end = Math.min(source.length, destination.length);
      const clipped = source.subarray(0, end);
      destination.set(clipped);
    };
  }

  const constantValues = new Float32Array(128);
  constantValues.fill(1);

  function createConstantSourceNode(
    audioContext: BaseAudioContext,
    options?: ConstantSourceOptions,
  ): AudioScheduledSourceNode {
    const bufferSource = audioContext.createBufferSource();
    const buffer = audioContext.createBuffer(1, constantValues.length, audioContext.sampleRate);
    const gain = audioContext.createGain();
    const offset = options === undefined || typeof options.offset !== "number" ? 1 : options.offset;
    const startFunc = bufferSource.start;

    gain.channelCount = 1;
    gain.channelCountMode = "explicit";
    gain.channelInterpretation = "discrete";
    gain.gain.value = offset;

    buffer.getChannelData(0).set(constantValues);

    bufferSource.buffer = buffer;
    bufferSource.loop = true;
    bufferSource.connect(gain);
    const bufferSourceDisconnect = bufferSource.disconnect.bind(bufferSource);

    Object.defineProperties(bufferSource, {
      offset: {
        value: gain.gain,
        enumerable: true,
        writable: false,
        configurable: true,
      },
      start: {
        value(when: number) {
          startFunc.call(bufferSource, when);
        },
        enumerable: false,
        writable: false,
        configurable: true,
      },
      connect: {
        value: AudioNode.prototype.connect.bind(gain),
        enumerable: false,
        writable: false,
        configurable: true,
      },
      disconnect: {
        /* eslint-disable @typescript-eslint/no-explicit-any */
        value: (args: any[]) => {
          bufferSourceDisconnect();
          AudioNode.prototype.disconnect.apply(gain, args as any);
        },
        /* eslint-enable @typescript-eslint/no-explicit-any */

        enumerable: false,
        writable: false,
        configurable: true,
      },
    });

    return bufferSource;
  }

  Object.defineProperty(BaseAudioContext.prototype, "createConstantSource", {
    value() {
      return createConstantSourceNode(this);
    },
    enumerable: false,
    writable: false,
    configurable: true,
  });

  if (typeof Symbol === "function" && typeof Symbol.hasInstance === "symbol") {
    Object.defineProperty(createConstantSourceNode, Symbol.hasInstance, {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      value(value: any) {
        // TODO use interface?
        return value instanceof AudioNode && (value as any).offset instanceof AudioParam;
      },
      /* eslint-enable @typescript-eslint/no-explicit-any */
    });
  }
}
