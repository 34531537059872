import { defTransacted } from "@thi.ng/atom";
import { clamp01, fit10 } from "@thi.ng/math";
import { GestureInfo } from "@thi.ng/rstream-gestures";
import { indexed } from "@thi.ng/transducers";
import { findDo } from "../../../../../../utils/findDo";
import { didInputEnd, GestureHandler } from "../../../../../../utils/gestureCanvasFactory";

import { IChorusState, IChorusVoice, WidgetFactory } from "../../../../app-main/api";
import { defShouldRender, defWidget, WidgetRender } from "../defWidget";
import { chorusOverlay } from "./chorus-overlay";
import { chorusWaves } from "./chorus-waves";

export const chorusFactory: WidgetFactory = (ctx, _audio) => {
  const shouldRender = defShouldRender();
  const infoIdToVoiceId = new Map<GestureInfo["id"], IChorusVoice["id"]>();
  let hoveredVoiceId: IChorusVoice["id"] | undefined = undefined;

  const getHeights = (canvasHeight: number) => {
    const creation = (canvasHeight * 0.15) | 0;
    const waves = canvasHeight - creation;
    return { creation, waves };
  };

  const getVoiceDist = (inputY: number, voice: IChorusVoice, canvasHeight: number) => {
    const margin = 20;
    const heights = getHeights(canvasHeight);
    const waveTop = fit10(voice.gain, 0, heights.waves);

    if (inputY < waveTop - margin) {
      return undefined;
    }

    const dist = Math.abs(inputY - waveTop);
    return dist;
  };

  const gestureHandler: GestureHandler = (gesture, canvasWidth, canvasHeight) => {
    const state: Readonly<IChorusState> = ctx.state.deref().audio.chorus;

    const getVoiceAtInput = (y: number) => {
      let min: { voice: IChorusVoice; voiceIndex: number; dist: number } | undefined;
      for (const [voiceIndex, voice] of indexed(state.voices)) {
        const dist = getVoiceDist(y, voice, canvasHeight);
        if (dist !== undefined) {
          if (min === undefined || dist < min.dist) {
            min = { voice, voiceIndex, dist };
          }
        }
      }

      return min;
    };

    const updateHovered = () => {
      const [x, y] = gesture.pos;

      if (x < 0 || x >= canvasWidth || y < 0 || y >= canvasHeight) {
        hoveredVoiceId = undefined;
      } else if (y < getHeights(canvasHeight).waves) {
        hoveredVoiceId = getVoiceAtInput(y)?.voice.id;
      }
    };

    if (gesture.type === "start") {
      const isAlreadySelected = (() => {
        const voiceIds = Array.from(infoIdToVoiceId.values());
        return (voiceId: IChorusVoice["id"]) => voiceIds.some((value) => value === voiceId);
      })();

      const tx = defTransacted(ctx.state);
      tx.begin();
      for (const info of gesture.active) {
        const infoState = getVoiceAtInput(info.pos[1]);
        if (infoState !== undefined) {
          const isSelected = isAlreadySelected(infoState.voice.id);
          if (!isSelected) {
            infoIdToVoiceId.set(info.id, infoState.voice.id);
            tx.resetIn(
              ["audio", "chorus", "startValues", infoState.voiceIndex],
              structuredClone(infoState.voice),
            );
          }
        }
      }
      tx.commit();
      shouldRender.set();
    } else if (gesture.type === "move") {
      updateHovered();
      shouldRender.set();
    } else if (gesture.type === "drag") {
      const tx = defTransacted(ctx.state);
      tx.begin();

      for (const info of gesture.active) {
        findDo(
          [...infoIdToVoiceId.entries()],
          ([infoId]) => infoId === info.id,
          ([, voiceId]) => {
            const matchesVoiceId = (voice: IChorusVoice) => voice.id === voiceId;
            findDo(tx.current?.audio.chorus.voices ?? [], matchesVoiceId, (voice, i) => {
              findDo(state.startValues, matchesVoiceId, (start) => {
                if (info.delta !== undefined) {
                  const normX = -info.delta[0] / canvasWidth;
                  const normY = -info.delta[1] / canvasHeight;

                  tx.resetIn(["audio", "chorus", "voices", i], {
                    id: voice.id,
                    gain: clamp01(start.gain + normY),
                    depth: clamp01(start.gain + normY),
                    speed: clamp01(start.speed - normX),
                  });
                }
              });
            });
          },
        );
      }

      tx.commit();
      shouldRender.set();
    } else if (gesture.type === "end") {
      for (const infoId of infoIdToVoiceId.keys()) {
        if (didInputEnd(gesture, infoId)) {
          infoIdToVoiceId.delete(infoId);
        }
      }
      updateHovered();
      shouldRender.set();
    }
  };

  const render: WidgetRender = (canvas, canvasWidth, canvasHeight) => {
    const state = ctx.state.deref().audio.chorus;
    const heights = getHeights(canvasHeight);

    return [
      canvas,
      { width: canvasWidth, height: canvasHeight },
      chorusWaves(
        state.voices,
        { x: 0, y: 0, w: canvasWidth, h: heights.waves },
        Array.from(infoIdToVoiceId.values()).map((voiceId) => voiceId),
        hoveredVoiceId,
      ),
      chorusOverlay(canvasWidth, heights),
    ];
  };

  return defWidget(gestureHandler, render, shouldRender);
};
