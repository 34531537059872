import { ensureAction } from "../utils/ensureAction";

export function ensureMuteSwitchIsBypassed() {
  ensureAction(() => {
    // Play silent audio using an Audio Element to bypass iOS mute switch
    const audioEl = document.createElement("audio");
    audioEl.controls = false;
    audioEl.preload = "auto";
    audioEl.loop = false;
    audioEl.src =
      "data:audio/mp3;base64,//MkxAAHiAICWABElBeKPL/RANb2w+yiT1g/gTok//lP/W/l3h8QO/OCdCqCW2Cw//MkxAQHkAIWUAhEmAQXWUOFW2dxPu//9mr60ElY5sseQ+xxesmHKtZr7bsqqX2L//MkxAgFwAYiQAhEAC2hq22d3///9FTV6tA36JdgBJoOGgc+7qvqej5Zu7/7uI9l//MkxBQHAAYi8AhEAO193vt9KGOq+6qcT7hhfN5FTInmwk8RkqKImTM55pRQHQSq//MkxBsGkgoIAABHhTACIJLf99nVI///yuW1uBqWfEu7CgNPWGpUadBmZ////4sL//MkxCMHMAH9iABEmAsKioqKigsLCwtVTEFNRTMuOTkuNVVVVVVVVVVVVVVVVVVV//MkxCkECAUYCAAAAFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";
    void audioEl.play();
  });
}

export function ensureAudioContextIsResumed(audioContext: AudioContext) {
  ensureAction(() => {
    return new Promise<void>((resolve, reject) => {
      if (audioContext.state !== "suspended") {
        resolve();
      }

      audioContext
        .resume()
        .then(() => {
          if (audioContext.state === "running") {
            resolve();
          }
        })
        .catch(() => {
          reject("Cannot resume AudioContext, as it has been closed");
        });
    });
  });
}
