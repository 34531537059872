export type Device = "iOS" | "Android" | "Desktop";

export const DEVICES: Device[] = ["iOS", "Android", "Desktop"];

export function getDevice(userAgent?: string): Device {
  if (userAgent === undefined) {
    return "Desktop";
  }

  if (userAgent.match(/Android/i)) {
    return "Android";
  }

  const iDevices = [/iPhone/i, /iPad/i, /iPod/i];
  const isApple = iDevices.some((device) => userAgent.match(device) !== null);
  if (isApple) {
    return "iOS";
  }

  return "Desktop";
}

function getAndroidVersion(userAgent: string) {
  const match = userAgent.toLowerCase().match(/android\s([0-9.]*)/);
  if (match === null || match.length < 2) {
    return undefined;
  }

  return match[1].split(".")[0];
}

export function getDeviceLatencyMs(userAgent: string, type: "audio" | "visual") {
  const device = getDevice(userAgent);

  // const desktopLatency = 0.160; // TODO test this
  // const iOSLatencyS = 0.150;
  // const defaultLatencyS = 0.160;

  const desktopLatencyS = 0;
  const iOSLatencyS = 0;
  const defaultLatencyS = 0;

  const androidAudioLatenciesS: { [key: string]: number } = {
    4: 300,
    5: 290,
    6: 290,
    7: 100,
    8: 100,
    9: 50, // originally this was 0.50, seems like mistake...
    unknown: 300,
  };

  const androidVisualLatenciesS: { [key: string]: number } = {
    4: 100,
    5: 100,
    6: 100,
    7: 100,
    8: 50,
    9: 50,
    unknown: 100,
  };

  if (device === "iOS") {
    return iOSLatencyS;
  }
  if (device === "Desktop") {
    return desktopLatencyS;
  }
  if (device === "Android") {
    const version = getAndroidVersion(userAgent);
    const lookup = type === "audio" ? androidAudioLatenciesS : androidVisualLatenciesS;

    if (version === undefined || !(version in lookup)) {
      return lookup.unknown;
    }

    return lookup[version];
  }

  return defaultLatencyS;
}

export function getDeviceAudioLatencyMs(userAgent: string) {
  return getDeviceLatencyMs(userAgent, "audio");
}

export function getDeviceVisualLatencyMs(userAgent: string) {
  return getDeviceLatencyMs(userAgent, "visual");
}

export const isTouchDevice = () => {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
};
