import { IWatch, Watch } from "@thi.ng/api";

export const addThrottledWatch = <T>(
  toWatch: IWatch<T>,
  watchId: string,
  durationMs: number,
  fn: Watch<T>,
) => {
  let timeout: number;

  return toWatch.addWatch(watchId, (id, oldState, newState) => {
    clearTimeout(timeout);
    timeout = window.setTimeout(() => fn(id, oldState, newState), durationMs);
  });
};
