// TODO split necessary polyfill parts into modules and import as needed
import { audioPolyfill } from "../audio/audioPolyfill";

audioPolyfill();

export class ChorusNode {
  public context: BaseAudioContext;

  protected lfoNode: OscillatorNode;
  protected mulNode: GainNode;
  protected addNode: ConstantSourceNode;
  protected delayNode: DelayNode;

  protected _depth: number;
  protected _delayTimeMs: number;

  constructor(context: BaseAudioContext) {
    this.context = context;

    this.lfoNode = context.createOscillator();
    this.lfoNode.type = "sine";
    this.lfoNode.frequency.value = 1.3;

    this.addNode = context.createConstantSource();
    this.addNode.offset.value = 1;
    this.mulNode = context.createGain();
    this.delayNode = context.createDelay();

    this._depth = 0.3;
    this._delayTimeMs = 3.5;

    this.addNode.connect(this.mulNode); // signal is mulNode.gain.value
    this.lfoNode.connect(this.mulNode); // signal is 0.0 - mulNode.gain.value * 2
    this.mulNode.connect(this.delayNode.delayTime);

    this.updateLFO();
    this.lfoNode.start();
    this.addNode.start();
  }

  get inputNode() {
    return this.delayNode;
  }
  get outputNode() {
    return this.delayNode;
  }

  get frequency() {
    return this.lfoNode.frequency;
  }

  get depth() {
    return this._depth;
  }
  set depth(depth) {
    if (depth === this.depth) {
      return;
    }
    this._depth = depth;
    this.updateLFO();
  }

  get delayTimeMs() {
    return this._delayTimeMs;
  }
  set delayTimeMs(centerMs) {
    if (centerMs === this.delayTimeMs) {
      return;
    }
    this._delayTimeMs = centerMs;
    this.updateLFO();
  }

  public connect(destination: AudioNode | AudioParam, output?: number, input?: number) {
    if (destination instanceof AudioNode) {
      this.outputNode.connect(destination, output, input);
      return destination;
    }

    this.outputNode.connect(destination, output);
    return undefined;
  }

  public disconnect(
    outputOrDestination?: number | AudioNode | AudioParam,
    output?: number,
    input?: number,
  ): void {
    this.lfoNode.stop();
    this.addNode.stop();

    this.lfoNode.disconnect();
    this.mulNode.disconnect();
    this.addNode.disconnect();
    // this.delayNode.disconnect(); // this.outputNode

    if (outputOrDestination === undefined) {
      this.outputNode.disconnect();
    } else if (typeof outputOrDestination === "number") {
      this.outputNode.disconnect(outputOrDestination);
    } else if (outputOrDestination instanceof AudioParam) {
      this.outputNode.disconnect(outputOrDestination);
    } else if (outputOrDestination instanceof AudioNode) {
      if (output !== undefined && input !== undefined) {
        this.outputNode.disconnect(outputOrDestination, output, input);
      } else if (output !== undefined && input === undefined) {
        this.outputNode.disconnect(outputOrDestination, output);
      } else {
        this.outputNode.disconnect(outputOrDestination);
      }
    }
  }

  protected updateLFO() {
    const delayTimeS = this.delayTimeMs / 1000;
    // osc has range of 2, so need to halve it
    const deviationS = delayTimeS * this.depth * 0.5;
    this.mulNode.gain.setValueAtTime(deviationS, 0.001);
  }
}
