import { INamedPreset } from "../../app-main/api";
import { preset as fluteOverrides } from "./flute";
import { preset as initial } from "./initial";
import { preset as organOverrides } from "./organ";
import { preset as stringPluckOverrides } from "./string-pluck";
import { preset as violinOverrides } from "./violin";

const stringPluck = { ...initial, ...stringPluckOverrides };
const violin = { ...initial, ...violinOverrides };
const flute = { ...initial, ...fluteOverrides };
const organ = { ...initial, ...organOverrides };

const presets: INamedPreset[] = [
  { name: "Default", preset: initial },
  { name: "Pluck", preset: stringPluck },
  { name: "Blow", preset: flute },
  { name: "Stops", preset: organ },
  { name: "Bow", preset: violin },
];

export default presets;
