/* eslint-disable no-console */
import { IHeartBeatMessageDef, IMessage, IMessageDef, IUnsentMessage, MessageType } from "./api";

export class StayAliveSocket {
  public isDebugEnabled: boolean;

  public readonly serverUrl: string;
  protected socket?: WebSocket;
  protected isConnectionDesired: boolean;
  protected heartbeatIntervalId: number;
  protected heartbeatIntervalMs: number;
  protected reconnectTimeoutId: number;
  protected attemptReconnectionAfterMs: number;
  protected nextMessageId: number;

  constructor(serverUrl: string) {
    this.serverUrl = serverUrl;
    this.isConnectionDesired = false;
    this.isDebugEnabled = false;

    this.heartbeatIntervalMs = 20 * 1000;
    this.heartbeatIntervalId = -1;
    this.reconnectTimeoutId = -1;
    this.attemptReconnectionAfterMs = 3000;
    this.nextMessageId = 0;
  }

  public get isConnected() {
    return this.socket !== undefined && this.socket.readyState === WebSocket.OPEN;
  }

  // Public Methods
  // ---------------------------------------------------------------------------
  public openConnection() {
    this.isConnectionDesired = true;
    this.connect();
  }

  public closeConnection() {
    this.isConnectionDesired = false;
    this.disconnect();
  }

  public createUnsentMessage<T_type extends MessageType, T_data = undefined>(
    def: IMessageDef<T_type, T_data>,
  ): IUnsentMessage & IMessageDef<T_type, T_data> {
    return { ...def, id: this.getNextMessageId() };
  }

  public sendMessage<T_type extends MessageType, T_data = undefined>(
    def: IMessageDef<T_type, T_data>,
  ) {
    return this.sendUnsentMessage(this.createUnsentMessage<T_type, T_data>(def));
  }

  public sendUnsentMessage<T extends IUnsentMessage>(unsentMessage: T): IMessage & T {
    try {
      if (this.socket === undefined) {
        throw new Error("Socket is not connected");
      }

      if (!this.isConnected) {
        const state = {
          [WebSocket.CONNECTING]: "CONNECTING",
          [WebSocket.CLOSING]: "CLOSING",
          [WebSocket.CLOSED]: "CLOSED",
        }[this.socket.readyState];

        throw new Error(`Socket is ${state}`);
      }

      const message = { ...unsentMessage, timestampMs: Date.now() };
      const json = JSON.stringify(message);
      this.socket.send(json);

      this.log(`sending ${json}`);

      return message;
    } catch (error) {
      this.onError(error);
      throw error;
    }
  }

  // overrides
  /* eslint-disable @typescript-eslint/no-empty-function */
  public onClose(_event: CloseEvent) {}
  public onError(_event: Event | unknown) {}
  public onMessage(_event: MessageEvent) {}
  public onOpen(_event: Event) {}
  /* eslint-enable @typescript-eslint/no-empty-function */

  // Protected Methods
  // ---------------------------------------------------------------------------

  protected connect() {
    try {
      this.socket = new WebSocket(this.serverUrl);

      this.socket.onopen = (event) => {
        this.heartbeatIntervalId = window.setInterval(() => {
          this.sendMessage<IHeartBeatMessageDef["type"], IHeartBeatMessageDef["data"]>({
            type: "Heartbeat",
            data: undefined,
          });
        }, this.heartbeatIntervalMs);

        this.onOpen(event);
      };

      this.socket.onerror = (event) => this.onError(event);

      this.socket.onmessage = (event) => {
        this.onMessage(event);
        this.log(`received: ${event.data}`);
      };

      this.socket.onclose = (event) => {
        this.onClose(event);
        if (this.isConnectionDesired) {
          this.reconnect();
        }
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error:", error);
    }
  }

  protected disconnect() {
    if (this.socket !== undefined) {
      this.socket.close();
      this.socket = undefined;
    }

    clearInterval(this.heartbeatIntervalId);
    clearTimeout(this.reconnectTimeoutId);
  }

  protected reconnect() {
    this.disconnect();

    this.reconnectTimeoutId = window.setTimeout(() => {
      this.connect();

      this.log("reconnecting");
    }, this.attemptReconnectionAfterMs);
  }

  protected getNextMessageId() {
    return this.nextMessageId++;
  }

  protected log(message: string) {
    if (this.isDebugEnabled) {
      // eslint-disable-next-line no-console
      console.log(message);
    }
  }
}
