import { Attribs } from "@thi.ng/hiccup-html";
import { map } from "@thi.ng/transducers";

import { Point } from "../../../../../../api";
import { Envelope } from "../../../../app-main/api";

export const envelope = (
  data: Envelope,
  attribs: Partial<Attribs>,
  scalePoint: (point: Point) => Point,
) => {
  return [
    [
      "path",
      attribs,
      [["M", scalePoint(data[0])], ...Array.from(map((point) => ["L", scalePoint(point)], data))],
    ],
  ];
};
