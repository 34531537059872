import { defTransacted } from "@thi.ng/atom";
import { clamp, fit01, fit11, fitClamped, inRange } from "@thi.ng/math";
import { GestureInfo } from "@thi.ng/rstream-gestures";
import { indexed, map } from "@thi.ng/transducers";
import { add2, mix2, sub2 } from "@thi.ng/vectors";

import { IRect, Point } from "../../../../../../api";
import { didInputEnd, GestureHandler } from "../../../../../../utils/gestureCanvasFactory";
import { isInsideRect } from "../../../../../../utils/isInsideRect";
import { defTouchCounter } from "../../../../../../utils/touchCounter";
import {
  IAppContext,
  IWaveformHandle,
  IWaveformSegment,
  IWaveformState,
  WidgetFactory,
} from "../../../../app-main/api";
import { defShouldRender, defWidget, WidgetRender } from "../defWidget";

const DEBUG = false;
const NUM_HANDLES = 5;
const NUM_SEGMENTS = NUM_HANDLES - 1;
const SNAP_THRESHOLD = 0.0666;
const MARGIN = 20;

const X_POSITIONS = Array.from(Array(NUM_HANDLES), (_, i) => i / NUM_SEGMENTS);
const X_SNAPS = Array(...Array(NUM_SEGMENTS * 2)).map((_, i, arr) => i * (1 / arr.length));

export const initWaveformModel = (): IWaveformState => {
  return {
    handles: X_POSITIONS.map((x, i) => ({
      waveformPoint: [x, Math.sin((i / X_POSITIONS.length - 1) * Math.PI * 2)],
      size: MARGIN * 2,
      isGrabbed: false,
      isHovered: false,
    })),
    segments: Array(...Array(NUM_SEGMENTS)).map(
      (_, i): IWaveformSegment => ({
        handleIdxA: i,
        handleIdxB: i + 1,
        isHovered: false,
        isGrabbed: false,
        control: [0.5 / NUM_SEGMENTS + i / NUM_SEGMENTS, i < NUM_SEGMENTS * 0.5 ? 1.0 : -1.0],
      }),
    ),
  };
};

const getCanvasY = (gain: number, rect: IRect) => fit11(gain, rect.y + rect.h, rect.y);

const getCanvasPoint = (waveformPoint: Point, rect: IRect): Point => {
  const x = fit01(waveformPoint[0], rect.x, rect.x + rect.w);
  const y = getCanvasY(waveformPoint[1], rect);
  return [x, y];
};

const handleComponent = (model: IWaveformHandle, rect: IRect) => {
  const point = getCanvasPoint(model.waveformPoint, rect);
  return [
    [
      "circle",
      {
        // prettier-ignore
        /* eslint-disable no-multi-spaces */
        fill : model.isGrabbed ? "rgba(43, 212, 156, 1.0)"
             : model.isHovered ? "rgba(43, 156, 212, 0.9)"
             :                   "rgba(43, 156, 212, 0.7)",
        /* eslint-enable no-multi-spaces */
      },
      point,
      model.size / 2,
    ],
  ];
};

const handlesComponent = (ctx: IAppContext, rect: IRect) => {
  const handles = ctx.state.deref().audio.waveform.handles;
  return handles.map((handle) => handleComponent(handle, rect));
};

const waveformComponent = (ctx: IAppContext, rect: IRect) => {
  const { handles, segments } = ctx.state.deref().audio.waveform;

  return [
    ...map((segment) => {
      const pointA = handles[segment.handleIdxA].waveformPoint;
      const pointB = handles[segment.handleIdxB].waveformPoint;
      const controlPoint = [
        fit01(segment.control[0], rect.x, rect.x + rect.w),
        fit11(segment.control[1], rect.y + rect.h, rect.y),
      ];

      return [
        [
          "path",
          {
            weight: 5,
            lineCap: "round",
            // prettier-ignore
            /* eslint-disable no-multi-spaces */
            stroke : segment.isGrabbed ? "rgba(43, 212, 156, 1.0)"
                   : segment.isHovered ? "rgba(43, 156, 212, 0.6)"
                   :                     "rgb(150, 150, 150)",
            /* eslint-enable no-multi-spaces */
          },
          [
            ["M", getCanvasPoint(pointA, rect)],
            ["Q", controlPoint, getCanvasPoint(pointB, rect)],
          ],
        ],
        DEBUG ? ["circle", { fill: "rgba(212, 100, 100, 0.5)" }, controlPoint, 10] : [],
      ];
    }, segments),
  ];
};

const LINE_ATTRIBS = { stroke: "rgba(255, 255, 255, 0.5)", weight: 0.5 };

const midlineComponent = (rect: IRect) => {
  const y = rect.y + rect.h / 2;
  return ["line", LINE_ATTRIBS, [rect.x, y], [rect.x + rect.w, y]];
};

const GAIN_SNAPS = [-1.0, -0.5, 0, 0.5, 1.0];
const GAIN_THRESHOLD = 0.0666;
const TIME_THRESHOLD = 0.01666;

const gainLinesComponent = (positions: number[], gainSnaps: number[], rect: IRect) => {
  const halfMarkerWidth = 5;
  return [
    ...map((normX) => {
      const x = fit01(normX, rect.x, rect.x + rect.w);
      return [
        // vertical lines
        ["line", LINE_ATTRIBS, [x, rect.y], [x, rect.y + rect.h]],
        // y axis markers
        [
          ...map((snap) => {
            const y = getCanvasY(snap, rect);
            return [["line", LINE_ATTRIBS, [x - halfMarkerWidth, y], [x + halfMarkerWidth, y]]];
          }, gainSnaps),
        ],
      ];
    }, positions),
  ];
};

const getSnapped = (value: number, snaps: number[], threshold: number) => {
  for (const snap of snaps) {
    if (inRange(value, snap - threshold, snap + threshold)) {
      return snap;
    }
  }
  return value;
};

const getBodyRect = (canvasWidth: number, canvasHeight: number) => {
  const bodyWidth = canvasWidth - MARGIN * 2;
  const bodyHeight = canvasHeight - MARGIN * 2;
  const bodyRect: IRect = {
    x: MARGIN,
    y: MARGIN,
    w: bodyWidth,
    h: bodyHeight,
  };
  return bodyRect;
};

const containsPoint = (handle: IWaveformHandle, point: Point, bodyRect: IRect): boolean => {
  const halfSize = handle.size / 2;
  const canvasPoint = getCanvasPoint(handle.waveformPoint, bodyRect);
  const rect: IRect = {
    x: canvasPoint[0] - halfSize,
    y: canvasPoint[1] - halfSize,
    w: handle.size,
    h: handle.size,
  };
  return isInsideRect(point, rect);
};

const isInsideSegment = (
  point: Point,
  handleA: IWaveformHandle,
  handleB: IWaveformHandle,
  bodyRect: IRect,
) => {
  const minX = getCanvasPoint(handleA.waveformPoint, bodyRect)[0];
  const maxX = getCanvasPoint(handleB.waveformPoint, bodyRect)[0];
  const isInside = point[0] >= minX && point[0] <= maxX;
  return isInside;
};

const clampAndSnapControlPoint = (
  controlPoint: Readonly<Point>,
  handleA: Readonly<IWaveformHandle>,
  handleB: Readonly<IWaveformHandle>,
): Point => {
  const handlePointA = handleA.waveformPoint;
  const handlePointB = handleB.waveformPoint;

  const clampedX = clamp(controlPoint[0], handlePointA[0], handlePointB[0]);
  const clampedY = clamp(controlPoint[1], -1, 1);
  const x = getSnapped(clampedX, X_SNAPS, TIME_THRESHOLD);
  const y = getSnapped(clampedY, GAIN_SNAPS, GAIN_THRESHOLD);

  return [x, y];
};

const getPos_n = (pos: Point, bodyRect: IRect): Point => [
  fitClamped(pos[0], bodyRect.x, bodyRect.x + bodyRect.w, 0, 1),
  fitClamped(pos[1], bodyRect.y, bodyRect.y + bodyRect.h, 1, -1),
];

const MULTI_TAP_TIMEOUT_MS = 400;

export const waveformHandlesFactory: WidgetFactory = (ctx, _audio) => {
  const shouldRender = defShouldRender();

  const doUpdate = (update: () => void) => {
    update();
    shouldRender.set();
  };

  const controlStartPoints_n: Map<number, Point> = new Map(
    ctx.state.deref().audio.waveform.segments.map((segment, i) => [i, segment.control]),
  );
  const infoStates = new Map<
    GestureInfo["id"],
    { type: keyof IWaveformState; index: number; start_n: Point }
  >();
  // const gestureStartPositions_n = new Map<SimpleInput["id"], Point>();

  const touchCounter = defTouchCounter(MULTI_TAP_TIMEOUT_MS);

  const gestureHandler: GestureHandler = (gesture, canvasWidth, canvasHeight) => {
    const bodyRect = getBodyRect(canvasWidth, canvasHeight);

    const {
      handles,
      segments,
    }: {
      handles: ReadonlyArray<Readonly<IWaveformHandle>>;
      segments: ReadonlyArray<Readonly<IWaveformSegment>>;
    } = ctx.state.deref().audio.waveform;

    // Input Start
    // -----------------------------------------------------------------------
    if (gesture.type === "start") {
      const tx = defTransacted(ctx.state);
      tx.begin();

      const grabbedHandleIndices: number[] = [];
      // check handles
      for (const gestureInfo of gesture.active) {
        for (const [handleIndex, handle] of indexed(handles)) {
          const isGrabbed = !handle.isGrabbed && containsPoint(handle, gestureInfo.pos, bodyRect);

          if (isGrabbed) {
            grabbedHandleIndices.push(handleIndex);
            infoStates.set(gestureInfo.id, {
              type: "handles",
              index: handleIndex,
              start_n: getPos_n(gestureInfo.pos, bodyRect),
            });

            const newHandle: IWaveformHandle = structuredClone(handle);
            newHandle.isGrabbed = true;

            tx.resetIn(["audio", "waveform", "handles", handleIndex], newHandle);
          }
        }
      }

      for (const gestureInfo of gesture.active) {
        const touchCount = touchCounter(gesture.pos);

        for (const [segmentIndex, segment] of indexed(segments)) {
          const isInUse = infoStates.has(gestureInfo.id);

          const isParentGrabbed = grabbedHandleIndices.some(
            (index) => index === segment.handleIdxA || index === segment.handleIdxB,
          );

          const isInside = isInsideSegment(
            gestureInfo.pos,
            handles[segment.handleIdxA],
            handles[segment.handleIdxB],
            bodyRect,
          );

          const isGrabbed = !isInUse && !segment.isGrabbed && !isParentGrabbed && isInside;

          if (isGrabbed) {
            infoStates.set(gestureInfo.id, {
              type: "segments",
              index: segmentIndex,
              start_n: getPos_n(gestureInfo.pos, bodyRect),
            });

            controlStartPoints_n.set(segmentIndex, structuredClone(segment.control));

            const newSegment: IWaveformSegment = structuredClone(segment);
            newSegment.isGrabbed = true;
            if (touchCount >= 2) {
              newSegment.control = [
                ...mix2(
                  [],
                  [...handles[segment.handleIdxA].waveformPoint],
                  [...handles[segment.handleIdxB].waveformPoint],
                  [0.5, 0.5],
                ),
              ];
            }
            tx.resetIn(["audio", "waveform", "segments", segmentIndex], newSegment);
          }
        }
      }

      doUpdate(() => tx.commit());
    }

    // Input Move
    // -----------------------------------------------------------------------
    else if (gesture.type === "move") {
      const tx = defTransacted(ctx.state);
      tx.begin();

      for (const gestureInfo of gesture.active) {
        let anyHandleHovered = false;
        let anyHandleChanged = false;
        const newHandles = handles.map((handle) => {
          const newHandle: IWaveformHandle = structuredClone(handle);
          const isHovered = !anyHandleHovered && containsPoint(handle, gestureInfo.pos, bodyRect);

          newHandle.isHovered = isHovered;
          if (isHovered) {
            anyHandleHovered = true;
          }

          if (handle.isHovered !== isHovered) {
            anyHandleChanged = true;
          }

          return newHandle;
        });

        if (anyHandleChanged) {
          tx.resetIn(["audio", "waveform", "handles"], newHandles);
        }

        if (anyHandleHovered) {
          return;
        }

        let anySegmentHovered = false;
        let anySegmentChanged = false;
        const newSegments = anyHandleHovered
          ? structuredClone([...segments])
          : segments.map((segment) => {
              const handleA = handles[segment.handleIdxA];
              const handleB = handles[segment.handleIdxB];
              const newSegment: IWaveformSegment = structuredClone(segment);
              const isHovered =
                !anySegmentHovered && isInsideSegment(gestureInfo.pos, handleA, handleB, bodyRect);

              newSegment.isHovered = isHovered;

              if (isHovered) {
                anySegmentHovered = true;
              }

              if (segment.isHovered !== isHovered) {
                anySegmentChanged = true;
              }

              return newSegment;
            });

        if (anySegmentChanged) {
          tx.resetIn(["audio", "waveform", "segments"], newSegments);
        }
      }
      doUpdate(() => tx.commit());
    }

    // Input Drag
    // -----------------------------------------------------------------------
    else if (gesture.type === "drag") {
      const tx = defTransacted(ctx.state);
      tx.begin();

      for (const gestureInfo of gesture.active) {
        const infoState = infoStates.get(gestureInfo.id);
        if (infoState !== undefined) {
          const pos_n = getPos_n(gestureInfo.pos, bodyRect);

          if (infoState.type === "handles") {
            const newHandle: IWaveformHandle = structuredClone(handles[infoState.index]);

            if (!newHandle.isGrabbed) {
              throw new Error("handle is not grabbed");
            }

            // update x
            for (const xPosition of X_POSITIONS) {
              const isAtInterval = inRange(
                pos_n[0],
                xPosition - SNAP_THRESHOLD,
                xPosition + SNAP_THRESHOLD,
              );

              const isLessOrEqualToNext =
                infoState.index !== 0 &&
                (infoState.index === NUM_SEGMENTS ||
                  xPosition <= handles[infoState.index + 1].waveformPoint[0]);

              const isGreaterOrEqualToPrev =
                infoState.index !== NUM_SEGMENTS &&
                (infoState.index === 0 ||
                  xPosition >= handles[infoState.index - 1].waveformPoint[0]);

              if (isAtInterval && isLessOrEqualToNext && isGreaterOrEqualToPrev) {
                newHandle.waveformPoint[0] = xPosition;
              }
            }

            // update y
            newHandle.waveformPoint[1] = getSnapped(pos_n[1], GAIN_SNAPS, GAIN_THRESHOLD);

            tx.resetIn(["audio", "waveform", "handles", infoState.index], newHandle);
          } else if (infoState.type === "segments") {
            // Update segments
            const newSegment: IWaveformSegment = structuredClone(segments[infoState.index]);

            if (!newSegment.isGrabbed) {
              throw new Error("segment is not grabbed");
            }

            const controlStartPoint_n = structuredClone(controlStartPoints_n.get(infoState.index));
            if (controlStartPoint_n === undefined) {
              throw new Error("no start point for segment");
            }

            const delta_n = sub2([], pos_n, infoState.start_n);
            const point_n: Point = [...add2([], controlStartPoint_n, delta_n)];

            newSegment.control = clampAndSnapControlPoint(
              point_n,
              handles[newSegment.handleIdxA],
              handles[newSegment.handleIdxB],
            );

            tx.resetIn(["audio", "waveform", "segments", infoState.index], newSegment);
          }
        }
      }

      doUpdate(() => {
        tx.swapIn(["audio", "waveform"], (state) => ({
          ...state,
          segments: state.segments.map((newSegment) => ({
            ...newSegment,
            control: clampAndSnapControlPoint(
              newSegment.control,
              state.handles[newSegment.handleIdxA],
              state.handles[newSegment.handleIdxB],
            ),
          })),
        }));
        tx.commit();
      });
    }

    // Input End
    // -----------------------------------------------------------------------
    else if (gesture.type === "end") {
      const tx = defTransacted(ctx.state);
      tx.begin();
      for (const [gestureId, infoState] of infoStates) {
        if (didInputEnd(gesture, gestureId)) {
          tx.swapIn(["audio", "waveform", infoState.type, infoState.index], (thing) => ({
            ...thing,
            isGrabbed: false,
          }));
          infoStates.delete(gestureId);
        }
      }
      doUpdate(() => tx.commit());
    }
  };

  const render: WidgetRender = (canvas, width, height) => {
    const bodyRect = getBodyRect(width, height);
    return [
      canvas,
      { width, height },
      midlineComponent(bodyRect),
      gainLinesComponent(X_POSITIONS, GAIN_SNAPS, bodyRect),
      waveformComponent(ctx, bodyRect),
      handlesComponent(ctx, bodyRect),
    ];
  };

  return defWidget(gestureHandler, render, shouldRender);
};
