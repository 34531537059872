import { Point } from "../../../../api";

export const quadTo = (pointA: Point, pointB: Point, control: Point, t: number) => {
  // https://javascript.info/bezier-curve
  const [x1, y1] = pointA;
  const [x2, y2] = control;
  const [x3, y3] = pointB;
  const x = Math.pow(1 - t, 2) * x1 + 2 * (1 - t) * t * x2 + Math.pow(t, 2) * x3;
  const y = Math.pow(1 - t, 2) * y1 + 2 * (1 - t) * t * y2 + Math.pow(t, 2) * y3;

  return [x, y];
};
