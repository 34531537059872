import { fitClamped } from "@thi.ng/math";

import { ChorusNode } from "../../../../audio/ChorusNode";
import { IChorusVoice, IChorusVoiceNodes } from "../../app-main/api";

const chorusVoices = new Map<IChorusVoice["id"], IChorusVoiceNodes>();

// TODO this needs output node
const createChorusVoice = (
  audioContext: BaseAudioContext,
  inputNode: AudioNode,
  outputNode: AudioNode,
  id: IChorusVoice["id"],
) => {
  const chorusNode = new ChorusNode(audioContext);
  const gainNode = audioContext.createGain();

  inputNode.connect(chorusNode.inputNode);
  chorusNode.connect(gainNode);
  gainNode.connect(outputNode);

  chorusVoices.set(id, { chorusNode, gainNode, inputNode });

  const newGain = 1.0 / chorusVoices.size;
  Array.from(chorusVoices.values()).forEach((chorusVoice) => {
    chorusVoice.gainNode.gain.linearRampToValueAtTime(newGain, audioContext.currentTime + 0.01);
  });
};

function updateChorusVoice(audioContext: BaseAudioContext, voice: IChorusVoice) {
  const voiceNodes = chorusVoices.get(voice.id);
  if (voiceNodes === undefined) {
    return;
  }

  voiceNodes.chorusNode.depth = voice.depth;
  const freq = fitClamped(voice.speed, 0, 1, 1, 10);

  const timeS = audioContext.currentTime + 0.01;
  voiceNodes.chorusNode.frequency.linearRampToValueAtTime(freq, timeS);
  voiceNodes.gainNode.gain.linearRampToValueAtTime(voice.gain, timeS);
}

function removeChorusVoice(id: IChorusVoice["id"]) {
  const voiceNodes = chorusVoices.get(id);
  if (voiceNodes === undefined) {
    return;
  }
  voiceNodes.gainNode.gain.linearRampToValueAtTime(0, 0.01);

  // TODO fades etc
  // voiceNodes.inputNode.disconnect(voiceNodes.chorusNode.inputNode, 0, 0);
  // voiceNodes.chorusNode.disconnect();
  // voiceNodes.gainNode.disconnect();
  // chorusVoices.delete(id);
}

export function onVoicesChange(
  audioContext: BaseAudioContext,
  inputNode: AudioNode,
  outputNode: AudioNode,
  oldVoices: IChorusVoice[],
  newVoices: IChorusVoice[],
) {
  const toAdd = newVoices.filter((voice) => !chorusVoices.has(voice.id));
  toAdd.forEach((voice) => createChorusVoice(audioContext, inputNode, outputNode, voice.id));

  const toRemove = oldVoices.filter((vOld, i) => {
    const vNew = newVoices[i];
    if (vNew.gain !== vOld.gain && vNew.gain === 0) {
      return true;
    }
    return false;
  });
  toRemove.forEach((voice) => removeChorusVoice(voice.id));

  newVoices.forEach((voice) => updateChorusVoice(audioContext, voice));
}
