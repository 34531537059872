import { defCursor } from "@thi.ng/atom";
import { stream } from "@thi.ng/rstream/stream";

import { LFONode } from "../../../../audio/LFONode";
import { loadAudioFile } from "../../../../audio/loadAudioFile";
import { playBuffer } from "../../../../audio/playBuffer";
import { WaveformFunction, WaveformNode } from "../../../../audio/WaveformNode";
import { IAppContext, IWaveformHandle } from "../../app-main/api";
import { onVoicesChange as onChorusVoicesChange } from "./chorus";
import {
  createReverb as createDelayverb,
  onReverbDecaySChange,
  onReverbGainChange,
  onVoicesChange as onDelayVoicesChange,
} from "./delay-verb";
import { quadTo } from "./waveform-handles";

import { clamp01 } from "@thi.ng/math";
import { debounce, fromView } from "@thi.ng/rstream";
import { minCompare } from "@thi.ng/transducers";
import { getEnvelopeValue } from "./getEnvelopeValue";
import { createVowelGraph, updateVowelGraph } from "./vowel";

interface ITiming {
  startTimeS: number;
  durationS: number;
  underhangS: number;
}

export const remotePlaybackEventCounter = stream<number>();
remotePlaybackEventCounter.next(0);

const countPlaybackEvent = (startTimeS: number, durationS: number) => {
  window.setTimeout(() => {
    remotePlaybackEventCounter.next(remotePlaybackEventCounter.deref() ?? 0 + 1);
  }, (startTimeS - 0.2) * 1000);

  window.setTimeout(() => {
    remotePlaybackEventCounter.next(Math.max(0, remotePlaybackEventCounter.deref() ?? 0 - 1));
  }, (startTimeS + durationS) * 1000);
};

interface PlaybackEvent {
  start_s: number;
  duration_s: number;
}

export interface ActivePlaybackEvent extends PlaybackEvent {
  id: number;
  elapsed_n: number;
  isActive: boolean;
}

export class AudioHandler {
  public readonly ctx: IAppContext;

  protected readonly audioContext: BaseAudioContext;
  protected buffers: Map<string, Promise<AudioBuffer>>;

  // Sources
  protected sourceOutputNode: GainNode;
  protected waveformFunction: WaveformFunction;
  protected waveformData!: Float32Array;

  // Effects
  protected mixerNode: GainNode;
  protected amLfoNode: LFONode;
  protected amGainNode: GainNode;

  // playback
  protected nextPlaybackId: number;
  protected playbackTimes_s: Map<number, PlaybackEvent>;

  constructor(ctx: IAppContext) {
    const initialState = ctx.state.value.audio;

    this.ctx = ctx;
    this.audioContext = this.ctx.audioContext;
    this.buffers = new Map();

    this.nextPlaybackId = 0;
    this.playbackTimes_s = new Map();

    this.waveformFunction = (i, length) => {
      const segments = this.ctx.state.value.audio.waveform.segments;
      const handles = this.ctx.state.value.audio.waveform.handles;

      const segmentHandles: IWaveformHandle[][] = segments.map((segment) => [
        handles[segment.handleIdxA],
        handles[segment.handleIdxB],
      ]);

      const x = i / length;
      const sIdx = segmentHandles.findIndex((handle) => {
        return handle[1].waveformPoint[0] >= x && handle[0].waveformPoint[0] <= x;
      });

      const s = segments[sIdx];
      const handleA = segmentHandles[sIdx][0];
      const handleB = segmentHandles[sIdx][1];
      const sXLength = handleB.waveformPoint[0] - handleA.waveformPoint[0];
      const t = sXLength ? (x - handleA.waveformPoint[0]) / sXLength : 1;
      const output = quadTo(handleA.waveformPoint, handleB.waveformPoint, s.control, t)[1];

      return output;
    };

    const updateDurationMs = 210;
    fromView(this.ctx.state, { path: ["audio", "waveform"] })
      .subscribe(debounce(updateDurationMs))
      .subscribe({
        next: () => {
          this.updateWaveform();
        },
      });
    this.updateWaveform();

    this.sourceOutputNode = this.audioContext.createGain();
    this.mixerNode = this.audioContext.createGain();
    this.amLfoNode = new LFONode(this.audioContext);
    this.amLfoNode.frequency.value = initialState.lfo.frequency;
    this.amLfoNode.setRange(Math.pow(1 - initialState.lfo.depth, 3), 1, this.now);
    this.amGainNode = this.audioContext.createGain();
    this.amLfoNode.connect(this.amGainNode.gain);

    // sources -> vowel
    // vowel -> chorus
    // -----------------
    const vowelInputNode = this.sourceOutputNode;
    const vowelOutputNode = this.mixerNode;
    createVowelGraph(this.audioContext, vowelInputNode, vowelOutputNode, initialState.vowel);
    fromView(this.ctx.state, { path: ["audio", "vowel"] }).subscribe({
      next: (state) => updateVowelGraph(this.audioContext, state),
    });

    // onset -> chorus
    // -----------------
    // const onsetOutputNode = this.mixerNode;

    // mixer -> am
    // -----------
    this.mixerNode.connect(this.amGainNode);

    // chorus -> am
    // ------------
    const chorusVoicesCursor = defCursor(this.ctx.state, ["audio", "chorus", "voices"]);
    chorusVoicesCursor.addWatch("watch", (_id, oldVoices, newVoices) =>
      onChorusVoicesChange(
        this.audioContext,
        this.mixerNode,
        this.amGainNode,
        oldVoices,
        newVoices,
      ),
    );
    chorusVoicesCursor.notifyWatches([], this.ctx.state.deref().audio.chorus.voices);

    // am -> speakers
    this.amGainNode.connect(this.audioContext.destination);

    // am updates
    // ----------
    fromView(this.ctx.state, { path: ["audio", "lfo"] }).subscribe({
      next: (newState) => {
        this.amLfoNode.setRange(Math.pow(1 - newState.depth, 3), 1, this.nowish);
        this.amLfoNode.frequency.linearRampToValueAtTime(newState.frequency, this.nowish);
      },
    });

    // am -> delayVerb
    // ---------------
    const delayVerbInputNode = this.amGainNode;
    const delayVerbOutputNode = this.audioContext.destination;
    createDelayverb(
      this.audioContext,
      delayVerbInputNode,
      delayVerbOutputNode,
      initialState.delayVerb.reverbDecayS,
      initialState.delayVerb.reverbGain,
    );

    const delayVoicesCursor = defCursor(this.ctx.state, ["audio", "delayVerb", "delays"]);
    delayVoicesCursor.addWatch("change", (_id, oldVoices, newVoices) => {
      onDelayVoicesChange(
        this.audioContext,
        delayVerbInputNode,
        delayVerbOutputNode,
        oldVoices,
        newVoices,
        initialState.delayVerb.maxTimeS,
      );
    });
    onDelayVoicesChange(
      this.audioContext,
      delayVerbInputNode,
      delayVerbOutputNode,
      [],
      initialState.delayVerb.delays,
      initialState.delayVerb.maxTimeS,
    );

    const reverbDecayCursor = defCursor(this.ctx.state, ["audio", "delayVerb", "reverbDecayS"]);
    reverbDecayCursor.addWatch("change", (_id, _oldValue, decayS) => onReverbDecaySChange(decayS));

    const reverbMixCursor = defCursor(this.ctx.state, ["audio", "delayVerb", "reverbGain"]);
    reverbMixCursor.addWatch("mix", (_id, _oldValue, mix) => onReverbGainChange(mix, this.nowish));
  }

  protected storePlaybackStartTime_s(start_s: number, duration_s: number) {
    const id = this.nextPlaybackId;
    this.nextPlaybackId++;
    this.playbackTimes_s.set(id, { start_s, duration_s });
    return id;
  }

  protected clearPlaybackStartTime_s(id: number) {
    this.playbackTimes_s.delete(id);
  }

  public getPlaybackEvents(): ActivePlaybackEvent[] {
    const now_s = this.audioContext.currentTime;
    const events = [...this.playbackTimes_s.entries()].map(([id, event]): ActivePlaybackEvent => {
      const elapsed_s = now_s - event.start_s;
      const elapsed_n = clamp01(elapsed_s / event.duration_s);
      return { ...event, id, elapsed_n, isActive: now_s >= event.start_s };
    });
    return events;
  }

  public getPlaybackInfo = () => {
    const events = this.getPlaybackEvents();
    const first = minCompare(
      () => events[0],
      (a, b) => {
        if (a.isActive && b.isActive) {
          return a.elapsed_n * a.duration_s - b.elapsed_n * b.duration_s;
        }

        if (a.isActive && b.isActive) {
          return -1;
        }

        if (!b.isActive && b.isActive) {
          return 1;
        }

        return 0;
      },
      events,
    );

    const pos = first?.elapsed_n ?? 0.0;

    const level = getEnvelopeValue(pos, this.ctx.state.deref().audio.envelope.envelope);

    return { pos, level };
  };

  public playNote(
    noteStartTimeS: number,
    frequency: number,
    gain: number,
    durationS: number,
    isRemote = false,
  ) {
    if (this.audioContext.state !== "running") {
      return;
    }

    if (isRemote) {
      countPlaybackEvent(noteStartTimeS, durationS);
    }

    const baseTimeS = this.now + noteStartTimeS;

    const timing = this.calculateNoteTiming(baseTimeS, durationS);
    if (timing === undefined) {
      return;
    }

    const id = this.storePlaybackStartTime_s(timing.startTimeS, durationS);

    const envelopeNode = this.audioContext.createGain();

    const state = this.ctx.state.value.audio.harmonics;
    state.gains.forEach((harmonicGain, i) => {
      if (harmonicGain < state.minValue) {
        return;
      }

      const waveformNode = new WaveformNode(this.audioContext, this.waveformData);
      const gainNode = this.audioContext.createGain();

      waveformNode.frequency = frequency * (i + 1);
      gainNode.gain.value = harmonicGain * state.maxGain;

      waveformNode.connect(gainNode);
      gainNode.connect(envelopeNode);

      waveformNode.onended = () => {
        waveformNode.disconnect();
        gainNode.disconnect();
        if (i === 0) {
          envelopeNode.disconnect();
        }
        this.clearPlaybackStartTime_s(id);
      };

      waveformNode.start(timing.startTimeS);
      waveformNode.stop(timing.startTimeS + timing.durationS);

      // return { waveformNode, gainNode };
    });

    envelopeNode.connect(this.sourceOutputNode);

    const envelope = this.ctx.state.deref().audio.envelope.envelope;
    envelope.forEach((point) => {
      // larger base makes the time scaling more exaggerated
      // smaller base makes the time scaling less pronounced
      // works where base > 1
      const base = Math.max(1 + 0.001, 3);
      const x = point[0];
      const scaledX = (Math.pow(base, x) - 1) * (1 / (base - 1));
      const timeS = scaledX * timing.durationS;

      const envGain = point[1] * gain;
      envelopeNode.gain.linearRampToValueAtTime(envGain, timing.startTimeS + timeS);
    });
  }

  public loadBuffer(url: string, onprogress?: (event: ProgressEvent) => void) {
    let promise = this.buffers.get(url);

    if (promise === undefined) {
      promise = loadAudioFile(url, this.audioContext, onprogress);
      this.buffers.set(url, promise);
    }

    // TODO handle knowing when all buffers are loaded
    return promise;
  }

  public async playBuffer(url: string, absoluteStartTimeS: number, gain = 1.0, isRemote = false) {
    if (this.audioContext.state !== "running") {
      return undefined;
    }

    const buffer = await this.buffers.get(url);
    if (buffer === undefined) {
      // console.error(`No buffer for ${url}`);
      // Load the buffer and skip this note
      // TODO test this
      // buffer = await this.loadBuffer(url);
      return undefined;
    }

    const noteDurationS = buffer.duration;
    if (isRemote) {
      countPlaybackEvent(absoluteStartTimeS - this.audioContext.currentTime, noteDurationS);
    }

    const timing = this.calculateNoteTiming(absoluteStartTimeS, noteDurationS);
    if (timing === undefined) {
      return undefined;
    }

    const id = this.storePlaybackStartTime_s(absoluteStartTimeS, timing.durationS);

    const fadeInDurationS = timing.underhangS !== undefined && timing.underhangS > 0 ? 0.05 : 0;

    const result = playBuffer(
      this.audioContext,
      buffer,
      timing.startTimeS,
      gain,
      timing.underhangS,
      timing.durationS,
      fadeInDurationS,
      0.05,
      () => this.clearPlaybackStartTime_s(id),
    );
    result.gainNode.connect(this.audioContext.destination);
    // TODO do something with stopAction?
    return result;
  }

  protected get now() {
    return this.audioContext.currentTime;
  }
  protected get nowish() {
    return this.now + 0.02;
  }

  protected updateWaveform() {
    this.waveformData = WaveformNode.createWaveformData(
      this.waveformFunction,
      this.audioContext.sampleRate,
    );
    // TODO update waveforms on sustained notes
  }

  protected calculateNoteTiming(startTimeS: number, durationS: number): ITiming | undefined {
    const noteEndTimeS = startTimeS + durationS;
    if (noteEndTimeS < this.now) {
      return undefined;
    }

    let newStartTimeS = startTimeS;
    let newDurationS = durationS;
    let underhangS = 0;

    if (startTimeS < this.now) {
      underhangS = this.now - startTimeS;
      const waitS = 0.01;
      newStartTimeS = this.now + waitS;
      newDurationS -= underhangS + waitS;
      if (newDurationS <= 0) {
        return undefined;
      }
    }

    return { startTimeS: newStartTimeS, durationS: newDurationS, underhangS };
  }
}
