import { IRect } from "../../../../../../api";
import { IAppContext } from "../../../../app-main/api";
import { moon } from "./moon";
import { posToDuration_s } from "./posToDuration";

export const sky = (ctx: IAppContext, rect: IRect, moonRect: IRect) => {
  const state = ctx.state.deref().audio.delayVerb;
  const gain = state.reverbGain;
  const durationS = posToDuration_s(moonRect.x + moonRect.w * 0.5, rect, state.maxTimeS);

  const [reverbWidth, reverbTop] = [moonRect.x, moonRect.y];

  // const sunPos = [0.8, 0.2].map(v => v * bodyRect.w);
  // const sunRadius = bodyRect.w * 0.1;
  const radius1 = rect.w * 0.3;
  const radius2 = rect.w * 1.3;
  const gradientPos = [reverbWidth + rect.w, reverbTop - rect.h];

  return [
    [
      "defs",
      {},
      [
        "radialGradient",
        {
          id: "night",
          from: gradientPos,
          to: gradientPos,
          r1: radius1,
          r2: radius2,
        },
        [
          [0, "rgba(0, 0, 0, 0.0)"],
          [1, `rgba(0, 0, 0, ${gain})`],
        ],
        // [[0, "red"], [1, "green"]],
      ],
      // ["radialGradient",
      //   {
      //     id: "night",
      //     from: [bodyRect.w * 0.5 * normDecay, bodyRect.h],
      //     to: [bodyRect.w * 0.5 * normDecay, bodyRect.h],
      //     r1: Math.max(reverbWidth, reverbHeight) * 0.2,
      //     r2: Math.max(reverbWidth, reverbHeight) * 1.1,
      //   },
      //   [[0, "rgba(0, 0, 0, 1.0)"], [1, "rgba(0, 0, 0, 0.5)"]],
      //   // [[0, "red"], [1, "green"]],
      // ],
      // ["linearGradient",
      //   {
      //     id: "vert",
      //     from: [bodyRect.w / 2, bodyRect.h],
      //     to: [bodyRect.w / 2, bodyRect.h * (1 - gain)],
      //   },
      //   // [[0, "red"], [0.6, "red"], [1.0, "green"]],
      //   [[0, "rgba(0, 0, 0, 1.0)"], [0.9, "rgba(0, 0, 0, 0.5)"],  [1, "rgba(0, 0, 0, 0.0)"]],
      // ],
      // ["linearGradient",
      //   {
      //     id: "horz",
      //     from: [0, bodyRect.h / 2],
      //     to: [bodyRect.w * normDecay, bodyRect.h / 2],
      //   },
      //   // [[0, "red"], [0.6, "red"], [1.0, "green"]],
      //   [[0, "rgba(0, 0, 0, 1.0)"], [0.9, "rgba(0, 0, 0, 0.5)"],  [1, "rgba(0, 0, 0, 0.0)"]],
      // ],
    ],

    ["rect", { fill: "rgb(43, 156, 212)" }, [0, 0], rect.w, rect.h],
    // ["circle", { fill: "yellow" }, sunPos, sunRadius],
    // ["rect", { fill: "$vert" }, [0, 0], bodyRect.w, bodyRect.h],
    // ["rect", { fill: "$horz" }, [0, 0], bodyRect.w, bodyRect.h],
    ["rect", { fill: "$night" }, [0, 0], rect.w, rect.h],
    moon(moonRect, durationS),
  ];
};
