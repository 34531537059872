import { fit01, fitClamped } from "@thi.ng/math";
import { GestureInfo } from "@thi.ng/rstream-gestures";

import { IRect, Point } from "../../../../../api";
import { roomComponent } from "../../../../../components/room";
import { font } from "../../../../../utils/font";
import { didInputEnd, GestureHandler } from "../../../../../utils/gestureCanvasFactory";
import { reset } from "../../../../../utils/reset";
import { IAppContext, WidgetFactory } from "../../../app-main/api";
import { defShouldRender, defWidget, WidgetRender } from "../widgets/defWidget";
import { findDo } from "../../../../../utils/findDo";

const personComponent = (ctx: IAppContext, bodyRect: IRect, shouldDisplayInstructions: boolean) => {
  const currentPosition = ctx.musician?.state.deref().position ?? { x: 0, y: 0 };
  const bodyRight = bodyRect.x + bodyRect.w;
  const bodyBottom = bodyRect.y + bodyRect.h;

  const pos = [
    fit01(currentPosition.x, bodyRect.x, bodyRight),
    fit01(currentPosition.y, bodyRect.y, bodyBottom),
  ];

  const radius = bodyRect.w * 0.03;
  const textSize = Math.max(radius, 18.72);

  const textAttribs = {
    fill: "rgba(212, 100, 100, 0.9)",
    align: "center",
    baseline: "top",
    font: font(textSize),
  };

  return [
    ["circle", { fill: "rgba(212, 100, 100, 0.9)" }, pos, radius],
    shouldDisplayInstructions
      ? [
          ["text", textAttribs, [pos[0], pos[1] + radius], "Drag to where you"],
          ["text", textAttribs, [pos[0], pos[1] + radius + textSize], "are in the room"],
        ]
      : null,
  ];
};

export const roomPositionFactory: WidgetFactory = (ctx, _audio) => {
  let moveCount = 0;
  const shouldRender = defShouldRender();
  const isBelowThreshold = () => moveCount < 2;

  const getBodyRect = (canvasWidth: number, canvasHeight: number): IRect => {
    const margin = Math.max(canvasWidth, canvasHeight) * 0.02;
    const margin2 = margin * 2;
    const bodyRect: IRect = {
      x: margin,
      y: margin,
      w: canvasWidth - margin2,
      h: canvasHeight - margin2,
    };

    return bodyRect;
  };

  let infoId: GestureInfo["id"] | undefined = undefined;

  const gestureHandler: GestureHandler = (gesture, canvasWidth, canvasHeight) => {
    const bodyRect = getBodyRect(canvasWidth, canvasHeight);
    const bodyRight = bodyRect.x + bodyRect.w;
    const bodyBottom = bodyRect.y + bodyRect.h;

    const move = (pos: Point) => {
      const position = {
        x: fitClamped(pos[0], bodyRect.x, bodyRight, 0, 1),
        y: fitClamped(pos[1], bodyRect.y, bodyBottom, 0, 1),
      };

      if (ctx.musician !== undefined) {
        reset(ctx.musician.state, { position });
      }

      shouldRender.set();
    };

    if (gesture.type === "start") {
      if (infoId === undefined) {
        const info = gesture.active[0];
        infoId = info.id;
        move(info.pos);
      }
    } else if (gesture.type === "drag") {
      findDo(
        gesture.active,
        (info) => info.id === infoId,
        (info) => move(info.pos),
      );
    } else if (didInputEnd(gesture, infoId)) {
      if (isBelowThreshold()) {
        moveCount++;
      }
      shouldRender.set();
      infoId = undefined;
    }
  };

  const render: WidgetRender = (canvas, width, height) => {
    const bodyRect = getBodyRect(width, height);

    return [
      canvas,
      { width, height },
      roomComponent(ctx.config.room, bodyRect),
      personComponent(ctx, bodyRect, isBelowThreshold()),
    ];
  };

  const widget = defWidget(gestureHandler, render, shouldRender);

  return widget;
};
