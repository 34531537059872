import { ICredit } from "../api";

export const creditData: ICredit[] = [
  {
    name: "Arthur Carabott",
    title: "Designer, Programmer",
    links: [
      { url: "https://www.arthurcarabott.com", display: "arthurcarabott.com" },
      { url: "https://www.twitter.com/acarabott", display: "@acarabott" },
    ],
  },
  {
    name: "Oscar Dub",
    title: "Programmer",
    links: [{ url: "https://twitter.com/oscardub", display: "@oscardub" }],
  },
  {
    name: "Built using thi.ng",
    title: "by Karsten Schmidt",
    links: [{ url: "https://github.com/thi-ng/umbrella", display: "github.com/thi-ng/umbrella" }],
  },
];
