import { IAudioState } from "../../app-main/api";

export const preset: IAudioState = {
  noteDurationS: 1.5,
  chorus: {
    voices: [
      {
        id: 1,
        gain: 0,
        depth: 0,
        speed: 0,
      },
      {
        id: 2,
        gain: 0,
        depth: 0,
        speed: 0,
      },
      {
        id: 3,
        gain: 0,
        depth: 0,
        speed: 0,
      },
    ],
    startValues: [],
  },
  harmonics: {
    gains: [
      0.9630090987868284, 0.5176018197573657, 0.3286936741767764, 0.29749783362218374,
      0.22470753899480067, 0.13631932409012126, 0.10512348353552858,
    ],
    minValue: 0.1,
    maxGain: 0.5,
  },
  delayVerb: {
    delays: [
      {
        delayTimeS: 0.021011673151750974,
        gain: 0.542494769874477,
        id: 0,
      },
    ],
    nextDelayId: 5,
    reverbGain: 0.7449090121317158,
    reverbDecayS: 0.8920792079207921,
    maxTimeS: 2,
    isSettingReverb: true,
    isCreatingDelay: false,
    canDeleteDelay: false,
  },
  envelope: {
    envelope: [
      [0, 0],
      [0, 0],
      [0, 0],
      [0, 0.0009429400386847143],
      [0, 0.018529980657640246],
      [0, 0.03914023210831725],
      [0, 0.062338104448742764],
      [0, 0.08631485493230176],
      [0, 0.11772218181818181],
      [0, 0.16364588162475824],
      [0, 0.22833743226305608],
      [0, 0.28804211119535783],
      [0, 0.3493968334483559],
      [0, 0.4179311368194971],
      [0, 0.4776203842193578],
      [0, 0.5265867373014406],
      [0, 0.5760826033092976],
      [0, 0.6181592331654625],
      [0, 0.6642949596284845],
      [0, 0.7059028945391796],
      [0, 0.7355098218298908],
      [0, 0.7602372277207584],
      [0, 0.7797158375995731],
      [0, 0.7999136418247342],
      [0, 0.8174481850948405],
      [0, 0.8364795493547766],
      [0, 0.8610511725529046],
      [0, 0.8935891174582418],
      [0, 0.9216225226993164],
      [0, 0.9438584265789709],
      [0, 0.9571999689067636],
      [0, 0.9636575055220079],
      [0, 0.9652109443190074],
      [0, 0.9638219244250602],
      [0, 0.9591200405351135],
      [0, 0.9431461055589792],
      [0.002, 0.9072561352889659],
      [0.007178947368421052, 0.8609639326240566],
      [0.012795789473684208, 0.8146199456479349],
      [0.020026105263157887, 0.7589605553964979],
      [0.027705094736842097, 0.7093173390406067],
      [0.036585128421052625, 0.6555468831148863],
      [0.04558389221052631, 0.6031685554008467],
      [0.05362500850526315, 0.5601361429116879],
      [0.06047895417263158, 0.5273534459017516],
      [0.06680421596968421, 0.4983994950072018],
      [0.07291705698627368, 0.46864801421902125],
      [0.0782283824311242, 0.44073909866680927],
      [0.0826879691027941, 0.4162568054283254],
      [0.08646616475591949, 0.38996201362450006],
      [0.09054135285736717, 0.36335341707218555],
      [0.09485413491747269, 0.3365565376707775],
      [0.09914646582871499, 0.31351516051349165],
      [0.10342243582086673, 0.29427447348411045],
      [0.10747479076195654, 0.2773142005314721],
      [0.1121903589253547, 0.25708000968058536],
      [0.11806807661396797, 0.23565516248146534],
      [0.12403340865959543, 0.21738439342698362],
      [0.13112146376978162, 0.19945868247785362],
      [0.13868664470003583, 0.18251370078265028],
      [0.1472651052337129, 0.16615715663980293],
      [0.16549629471328609, 0.13545348160477153],
      [0.1842917726127341, 0.11084172145802734],
      [0.20627552335334517, 0.08446924950924582],
      [0.23270462920899193, 0.05394557291637921],
      [0.264374229682983, 0.032367343749827526],
      [0.300867804799018, 0.019420406249896514],
      [0.341220559628688, 0.011652243749937908],
      [0.3842396055976872, 0.006991346249962745],
      [0.4333916844781498, 0.004194807749977647],
      [0.48366071600357247, 0.002516884649986588],
      [0.5396654149081211, 0.0015101307899919529],
      [0.5975218056107074, 0.0009060784739951717],
      [0.6465437602780396, 0.000543647084397103],
      [0.689129745064537, 0.00032618825063826176],
      [0.7246722171042611, 0.00019571295038295705],
      [1, 0],
    ],
  },
  waveform: {
    handles: [
      {
        waveformPoint: [0, 0],
        size: 40,
        isGrabbed: false,
        isHovered: false,
      },
      {
        waveformPoint: [0.25, 1],
        size: 40,
        isGrabbed: false,
        isHovered: false,
      },
      {
        waveformPoint: [0.5, 0],
        size: 40,
        isGrabbed: false,
        isHovered: false,
      },
      {
        waveformPoint: [0.75, -1],
        size: 40,
        isGrabbed: false,
        isHovered: false,
      },
      {
        waveformPoint: [1, 0],
        size: 40,
        isGrabbed: false,
        isHovered: false,
      },
    ],
    segments: [
      {
        handleIdxA: 0,
        handleIdxB: 1,
        isHovered: true,
        isGrabbed: false,
        control: [0.125, 0.5],
      },
      {
        handleIdxA: 1,
        handleIdxB: 2,
        isHovered: true,
        isGrabbed: false,
        control: [0.375, 0.5],
      },
      {
        handleIdxA: 2,
        handleIdxB: 3,
        isHovered: true,
        isGrabbed: false,
        control: [0.625, -0.5],
      },
      {
        handleIdxA: 3,
        handleIdxB: 4,
        isHovered: true,
        isGrabbed: false,
        control: [0.875, -0.5],
      },
    ],
  },
  lfo: {
    frequency: 3.8954895489548953,
    depth: 0,
    minFrequency: 1,
    maxFrequency: 20,
  },
  vowel: {
    vowels: [
      {
        name: "a",
        gain: 0,
      },
      {
        name: "e",
        gain: 0,
      },
      {
        name: "i",
        gain: 0,
      },
      {
        name: "o",
        gain: 1,
      },
      {
        name: "u",
        gain: 0,
      },
    ],
  },
};
