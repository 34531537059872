import { IRect } from "../../../../../../api";

export const moon = (rect: IRect, durationS: number) => {
  const radius = rect.w * 0.5;
  const pos = [rect.x + radius, rect.y + radius];
  return [
    [
      "defs",
      {},
      [
        "radialGradient",
        {
          id: "moon",
          from: [pos[0] + 5, pos[1] - 5],
          to: [pos[0] + 10, pos[1] - 10],
          r1: radius * 0.6,
          r2: radius,
        },
        [
          [0, "rgb(200, 200, 200)"],
          [1, `rgb(150, 150, 150)`],
        ],
      ],
      [
        "radialGradient",
        {
          id: "crater",
          from: [pos[0] + 5, pos[1] - 5],
          to: [pos[0] + 10, pos[1] - 10],
          r1: radius * 0.2,
          r2: radius * 0.1,
        },
        [
          [0, "rgb(180, 180, 180)"],
          [1, `rgb(220, 220, 220)`],
        ],
      ],
    ],
    ["circle", { fill: "$moon", stroke: "black" }, pos, radius],
    ...[
      { polarAngle: 0.38, polarRadius: 0.7, normRadius: 0.1 },
      { polarAngle: 0.6, polarRadius: 0.7, normRadius: 0.07 },
      { polarAngle: 0.8, polarRadius: 0.8, normRadius: 0.03 },
      { polarAngle: 0.2, polarRadius: 0.8, normRadius: 0.03 },
      { polarAngle: 0.1, polarRadius: 0.6, normRadius: 0.04 },
    ].map((dot) => {
      return [
        "circle",
        { fill: "$crater", stroke: "black" },
        [
          pos[0] + Math.cos(dot.polarAngle * Math.PI * 2) * radius * dot.polarRadius,
          pos[1] + Math.sin(dot.polarAngle * Math.PI * 2) * radius * dot.polarRadius,
        ],
        radius * dot.normRadius,
      ];
    }),
    [
      "text",
      {
        fill: "white",
        baseline: "bottom",
        align: "center",
        font: "17px -apple-system, BlinkMacSystemFont, sans-serif",
      },
      [pos[0], pos[1] - radius * 1.2],
      `${durationS.toFixed(2)}s`,
    ],
  ];
};
