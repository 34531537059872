import { fit01 } from "@thi.ng/math";
import { IRect } from "../../../../../../api";
import { IVowelState } from "../../../../app-main/api";
import { eyes } from "../face/eyes";

const aspectRatios: { [key: string]: [number, number] } = {
  a: [0.81, 0.64],
  e: [0.94, 0.42],
  i: [1.0, 0.23],
  o: [0.35, 0.8],
  u: [0.21, 0.3],
};

const vowelMouth = (model: IVowelState, rect: IRect, bodyRect: IRect) => {
  const currentVowel = model.vowels.reduce((accum, cur) => {
    return cur.gain > accum.gain ? cur : accum;
  }, model.vowels[0]);

  // wobbly version
  // const scalar = 0.8 + Math.sin(Date.now() * 0.02) * 0.05; // make this based on LFO
  const scalar = 1.0;
  const aspectRatio = aspectRatios[currentVowel.name];
  const w = rect.w * aspectRatio[0] * scalar;
  const h = scalar * rect.h * aspectRatio[1] + bodyRect.h * 0.05;
  const x = bodyRect.w / 2 - w / 2;
  const y = rect.y + (rect.h - h) * 0.5;

  return [["rect", { fill: "black" }, [x, y], w, h, 100]];
};

export const blob = (
  model: IVowelState,
  rect: IRect,
  sliderY_n: number,
  playbackLevel_n: number,
) => {
  const width = rect.w * 0.9;
  const height = rect.h * 0.75;
  const x = rect.x + (rect.w - width) / 2;
  const y = rect.y + rect.h - height;
  const topOffset = 15;
  const borderRadius = rect.w * 0.08;

  const eyesHeight = height * 0.3;
  const eyesY = y - eyesHeight * 0.5;
  const eyesWidth = width * 0.5;
  const pupilRatio = 0.35;
  const eyesRect: IRect = {
    x: x + (width - eyesWidth) * 0.5,
    y: eyesY,
    w: eyesWidth,
    h: height * 0.25,
  };

  const mouthW = fit01(playbackLevel_n, 0.5, 0.666) * width;
  const mouthH = fit01(playbackLevel_n, 0.333, 0.666) * height;

  const mouthRect: IRect = {
    x: x + (width - mouthW) * 0.5,
    y: y + (height - mouthH) * 0.5,
    // w: fit01(playbackLevel_n, 0.5, 0.666) * width,
    w: mouthW,
    h: mouthH,
  };

  return [
    [
      "path",
      { fill: "rgb(43, 156, 212)", stroke: "black" },
      [
        ["M", [x, y + height]],
        ["L", [x + topOffset, y + borderRadius]],
        [
          "C",
          [x + topOffset, y + borderRadius],
          [x + topOffset, y],
          [x + topOffset + borderRadius, y],
        ],
        ["L", [x + width - topOffset - borderRadius, y]],
        [
          "C",
          [x + width - topOffset - borderRadius, y],
          [x + width - topOffset, y],
          [x + width - topOffset, y + borderRadius],
        ],
        ["L", [x + width, y + height]],
      ],
    ],
    eyes(eyesRect, pupilRatio, "black", [rect.x + rect.w, sliderY_n * rect.h]),
    vowelMouth(model, mouthRect, rect),
  ];
};
