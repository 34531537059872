import { canvas as hdomCanvas } from "@thi.ng/hdom-canvas";
import { fromDOMEvent, ISubscription } from "@thi.ng/rstream";
import { GestureEvent, GestureInfo, gestureStream } from "@thi.ng/rstream-gestures";
import { Point } from "../api";
import { isTouchDevice } from "./getDevice";

export type GestureHandler = (
  gesture: GestureEvent,
  canvasWidth: number,
  canvasHeight: number,
) => void;

export const gestureCanvasFactory = (gestureHandler: GestureHandler) => {
  let elRef: HTMLCanvasElement | undefined = undefined;
  let subscription: ISubscription<GestureEvent, GestureEvent> | undefined = undefined;

  const clearCanvasCache = () => {
    if (elRef !== undefined) {
      elRef.width = 0;
      elRef.height = 0;
    }
  };

  window.addEventListener("beforeunload", (_event) => {
    clearCanvasCache();
  });

  return {
    ...hdomCanvas,
    init: (canvasEl: HTMLCanvasElement) => {
      elRef = canvasEl;

      subscription = gestureStream(canvasEl, {
        preventScrollOnZoom: false,
        preventDefault: isTouchDevice(),
      }).subscribe({
        next: (event) =>
          gestureHandler(
            event,
            canvasEl.width / devicePixelRatio,
            canvasEl.height / devicePixelRatio,
          ),
      });

      fromDOMEvent(canvasEl, "mouseleave").subscribe({
        next: (event) => {
          const bounds = canvasEl.getBoundingClientRect();
          const pos: Point = [event.clientX - bounds.left, event.clientY - bounds.top];
          const type = event.buttons === 0 ? "move" : "drag";

          gestureHandler(
            {
              active: [],
              buttons: 0,
              event,
              isTouch: false,
              pos,
              type,
              zoom: 1,
              zoomDelta: 0,
            },
            canvasEl.width / devicePixelRatio,
            canvasEl.height / devicePixelRatio,
          );
        },
      });
    },
    release: () => {
      clearCanvasCache();

      if (subscription !== undefined) {
        subscription.unsubscribe();
      }
    },
  };
};

export type GestureCanvas = ReturnType<typeof gestureCanvasFactory>;

export const didInputEnd = (gesture: GestureEvent, id: GestureInfo["id"] | undefined) =>
  gesture.type === "end" && !gesture.active.some((info) => info.id === id);
