import { remotePlaybackEventCounter } from "./audio/AudioHandler";

// init
(() => {
  const root = document.getElementById("app");
  if (!root) {
    return;
  }
  root.classList.add("flash");
})();

remotePlaybackEventCounter.subscribe({
  next: (count) => {
    const root = document.getElementById("app");
    if (!root) {
      return;
    }

    const active = root.classList.contains("flash-active");

    if (count && !active) {
      root.classList.add("flash-active");
    }

    if (!count && active) {
      root.classList.remove("flash-active");
    }
  },
});
