import { fitClamped } from "@thi.ng/math";
import { IRect, Point } from "../../../../../../api";
import { eyes } from "../face/eyes";
import { mouth } from "../face/mouth";

export const face = (faceRect: IRect, bodyRect: IRect, focusPos: Point) => {
  const eyesRect: IRect = {
    ...faceRect,
    h: Math.min(faceRect.h * 0.666, 30),
  };

  const focusRangeY = [bodyRect.y + bodyRect.h, bodyRect.y] as const;

  const mouthX = faceRect.x + faceRect.w * 0.5;
  const mouthY = faceRect.y + faceRect.h * 0.766;

  const mouthRadiusRangeX = [bodyRect.w * 0.03, bodyRect.w * 0.022] as const;
  const mouthRadiusX = fitClamped(focusPos[1], ...focusRangeY, ...mouthRadiusRangeX);

  const mouthRadiusRangeY = [Math.max(1, bodyRect.h * 0.001), mouthRadiusX * 1.3333] as const;
  const mouthRadiusY = fitClamped(focusPos[1], ...focusRangeY, ...mouthRadiusRangeY);

  return [
    // ["rect", { stroke: "red" }, [faceRect.x, faceRect.y], faceRect.w, faceRect.h],
    eyes(eyesRect, 0.4, "black", focusPos),
    mouth(mouthX, mouthY, mouthRadiusY, mouthRadiusX),
  ];
};
