import { clamp, clamp01, inRange } from "@thi.ng/math";
import { GestureInfo } from "@thi.ng/rstream-gestures";

import { IRect, Point } from "../../../../../../api";
import { findDo } from "../../../../../../utils/findDo";
import { didInputEnd, GestureHandler } from "../../../../../../utils/gestureCanvasFactory";
import { WidgetFactory } from "../../../../app-main/api";
import { defShouldRender, defWidget, WidgetRender } from "../defWidget";
import { bottomComponent } from "./bottomCmp";
import { frameComponent } from "./frameCmp";
import { freqLabelCmp } from "./freqLabelCmp";
import { topComponent } from "./topCmp";
import { vertComponent } from "./vertCmp";

const MARGIN_W_PCT = 0.05;
const FREQ_INT_SNAP_THRESH = 0.05;
const FREQ_NORM_SNAP_THRESH = 0.07;
const DEPTH_SNAP_THRESH = 0.01;

export const lfoFactory: WidgetFactory = (ctx, _audio) => {
  const shouldRender = defShouldRender();
  const getShapes = (canvasWidth: number, canvasHeight: number) => {
    const margin = canvasWidth * MARGIN_W_PCT;
    const bodyWidth = canvasWidth - margin * 2;
    const bodyHeight = canvasHeight - margin * 2;
    const lineWeight = Math.max(3, bodyWidth * 0.005);
    const halfLineWeight = lineWeight / 2;
    const bodyRect: IRect = {
      x: margin,
      y: margin,
      w: bodyWidth,
      h: bodyHeight,
    };

    return { bodyRect, lineWeight, halfLineWeight, margin };
  };

  let { frequency: startingFreq, depth: startingDepth } = ctx.state.deref().audio.lfo;
  let shouldSnapToDepthStart = true;
  let shouldSnapToFreqStart = true;
  let infoId: GestureInfo["id"] | undefined = undefined;

  const gestureHandler: GestureHandler = (gesture, canvasWidth, canvasHeight) => {
    const { bodyRect } = getShapes(canvasWidth, canvasHeight);
    const state = ctx.state.deref().audio.lfo;

    // Input Start
    // -----------------------------------------------------------------------
    if (gesture.type === "start") {
      if (infoId === undefined) {
        const info = gesture.active[0];
        infoId = info.id;

        startingFreq = state.frequency;
        shouldSnapToFreqStart = true;

        startingDepth = state.depth;
        shouldSnapToDepthStart = true;
      }
    }

    // Input Drag
    // -----------------------------------------------------------------------
    else if (gesture.type === "drag") {
      findDo(
        gesture.active,
        (info) => info.id === infoId,
        (info) => {
          const deltaX = info.delta?.[0] ?? 0;
          const deltaY = info.delta?.[1] ?? 0;

          // Frequency
          const freqAmount = -(deltaX / bodyRect.w) * 5;
          let frequency = startingFreq;

          // only adjust if moved enough, to allow for depth adjustments without
          // affecting frequency

          const newFrequency = clamp(
            startingFreq + freqAmount,
            state.minFrequency,
            state.maxFrequency,
          );

          if (
            !shouldSnapToFreqStart ||
            newFrequency < startingFreq - FREQ_NORM_SNAP_THRESH ||
            newFrequency > startingFreq + FREQ_NORM_SNAP_THRESH
          ) {
            frequency = newFrequency;
            shouldSnapToFreqStart = false;
          }

          // snapping to integers
          const roundedFreq = Math.round(frequency);
          if (
            inRange(
              frequency,
              roundedFreq - FREQ_INT_SNAP_THRESH,
              roundedFreq + FREQ_INT_SNAP_THRESH,
            )
          ) {
            frequency = roundedFreq;
          }

          // Depth
          const depthAmount = deltaY / bodyRect.h;
          // only adjust if moved enough, to allow for depth adjustments without
          // affecting frequency
          let depth = state.depth;
          const newDepth = clamp01(startingDepth + depthAmount);
          if (
            !shouldSnapToDepthStart ||
            newDepth < startingDepth - DEPTH_SNAP_THRESH ||
            newDepth > startingDepth + DEPTH_SNAP_THRESH
          ) {
            depth = newDepth;
            shouldSnapToDepthStart = false;
          }

          // Update
          ctx.state.resetIn(["audio", "lfo"], { ...state, depth, frequency });
          shouldRender.set();
        },
      );
    } else if (didInputEnd(gesture, infoId)) {
      infoId = undefined;
    }
  };

  const relPos = (norm: number, axis: "x" | "y", bodyRect: IRect) =>
    bodyRect[axis] + norm * bodyRect[{ x: "w", y: "h" }[axis]];

  const getRelPoint = (point: Point, bodyRect: IRect) => [
    relPos(point[0], "x", bodyRect),
    relPos(point[1], "y", bodyRect),
  ];

  const clampPoint = (point: Point, bodyRect: IRect) => {
    const newPoint = getRelPoint(point, bodyRect);
    newPoint[0] = clamp(newPoint[0], bodyRect.x, bodyRect.x + bodyRect.w);
    newPoint[1] = clamp(newPoint[1], bodyRect.y, bodyRect.y + bodyRect.h);
    return newPoint;
  };

  const render: WidgetRender = (canvas, canvasWidth, canvasHeight) => {
    const state = ctx.state.deref().audio.lfo;
    const { bodyRect, lineWeight, halfLineWeight, margin } = getShapes(canvasWidth, canvasHeight);

    return [
      canvas,
      { width: canvasWidth, height: canvasHeight },
      frameComponent(bodyRect, margin, relPos),
      topComponent(state, bodyRect, lineWeight, halfLineWeight, clampPoint),
      vertComponent(state, bodyRect, lineWeight, halfLineWeight, getRelPoint),
      bottomComponent(state, bodyRect, lineWeight, halfLineWeight, clampPoint),
      freqLabelCmp(state, bodyRect),
    ];
  };

  return defWidget(gestureHandler, render, shouldRender);
};
