import { IRoom } from "../api";

export const appleCoventGarden: IRoom = {
  x_m: 13.45,
  y_m: 12.2,
  objects: [
    {
      id: "screen",
      rect: { x: 0.2, y: 0.0, w: 0.61, h: 0.06 },
      shape: "rect",
      fill: "black",
      stroke: "rgb(255, 255, 255, 0.5)",
    },
    {
      id: "tree-1",
      rect: { x: 0.04, y: 0.18, w: 0.15, h: 0.16 },
      shape: "circle",
      fill: "black",
      stroke: "rgb(255, 255, 255, 0.5)",
    },
    {
      id: "tree-2",
      rect: { x: 0.04, y: 0.55, w: 0.15, h: 0.16 },
      shape: "circle",
      fill: "black",
      stroke: "rgb(255, 255, 255, 0.5)",
    },
    {
      id: "tree-3",
      rect: { x: 0.26, y: 0.8, w: 0.15, h: 0.16 },
      shape: "circle",
      fill: "black",
      stroke: "rgb(255, 255, 255, 0.5)",
    },
    {
      id: "tree-4",
      rect: { x: 0.59, y: 0.8, w: 0.15, h: 0.16 },
      shape: "circle",
      fill: "black",
      stroke: "rgb(255, 255, 255, 0.5)",
    },
    {
      id: "tree-5",
      rect: { x: 0.83, y: 0.55, w: 0.15, h: 0.16 },
      shape: "circle",
      fill: "black",
      stroke: "rgb(255, 255, 255, 0.5)",
    },
    {
      id: "tree-6",
      rect: { x: 0.83, y: 0.18, w: 0.15, h: 0.16 },
      shape: "circle",
      fill: "black",
      stroke: "rgb(255, 255, 255, 0.5)",
    },
  ],
};
