import { IRect, Point } from "../../../../../../api";
import { eye } from "./eye";

export const eyes = (rect: IRect, pupilRatio: number, pupilFill: string, focusPoint: Point) => {
  const radius = Math.min(rect.h * 0.5, rect.w * 0.1666);
  const both = [0.25, 0.75].map((v) => {
    return eye(rect.x + rect.w * v, rect.y + radius, radius, pupilRatio, pupilFill, focusPoint);
  });

  return [
    // ["rect", { stroke: "black" }, [rect.x, rect.y], rect.w, rect.h],
    both,
  ];
};
