import { fitClamped } from "@thi.ng/math";
import { Envelope } from "../../app-main/api";

export const getEnvelopeValue = (pos_n: number, envelopeData: Envelope) => {
  // assumes sorted by x position
  const index2 = Math.max(
    0,
    envelopeData.findIndex((point) => point[0] >= pos_n),
  );

  const index1 = Math.max(0, index2 - 1);
  const point1 = envelopeData[index1];
  const point2 = envelopeData[index2];

  const interpolatedY_n = fitClamped(pos_n, point1[0], point2[0], point1[1], point2[1]);

  return interpolatedY_n;
};
