import { IAppContext } from "../../../app-main/api";

export const nameComponent = (ctx: IAppContext) => {
  if (ctx.musician === undefined) {
    return [];
  }

  const name = ctx.musician.state.deref().name;

  return [
    "div",
    { style: { "margin-bottom": "10px" } },
    name === undefined
      ? ""
      : [
          ["span", "You are: "],
          ["span", { style: { "font-weight": "bold" } }, `${name}`],
        ],
  ];
};
