import { defTransacted } from "@thi.ng/atom";
import { clamp, fit01, fit10, fitClamped } from "@thi.ng/math";
import { GestureInfo } from "@thi.ng/rstream-gestures";

import { IRect, Point } from "../../../../../../api";
import { didInputEnd, GestureHandler } from "../../../../../../utils/gestureCanvasFactory";
import { isInsideRect } from "../../../../../../utils/isInsideRect";
import { WidgetFactory, WIDGET_HEIGHT, WIDGET_WIDTH } from "../../../../app-main/api";
import { defShouldRender, defWidget, WidgetRender } from "../defWidget";
import { posToDuration_s } from "./posToDuration";
import { sky } from "./sky";
import { getStarRect, TOUCH_SIZE } from "./star";
import { stars } from "./stars";

const MARGIN = 20;

const getShapes = (canvasWidth: number, canvasHeight: number) => {
  const bodyWidth = canvasWidth - MARGIN * 2;
  const bodyHeight = canvasHeight - MARGIN * 2;
  const bodyRect: IRect = {
    x: MARGIN,
    y: MARGIN,
    w: bodyWidth,
    h: bodyHeight,
  };
  const bodyRight = bodyRect.x + bodyRect.w;
  const bodyBottom = bodyRect.y + bodyRect.h;

  return { bodyRect, bodyRight, bodyBottom };
};

export const delayVerbFactory: WidgetFactory = (ctx, _audio) => {
  const shouldRender = defShouldRender();

  let moonGestureId: GestureInfo["id"] | undefined = undefined;
  const selectedDelayIndices = new Map<GestureInfo["id"], number>();

  let moonPos: [number, number] = (() => {
    const state = ctx.state.deref().audio.delayVerb;
    const { bodyRect, bodyRight, bodyBottom } = getShapes(WIDGET_WIDTH, WIDGET_HEIGHT); // TODO lol

    return [
      fitClamped(state.reverbDecayS, 0, state.maxTimeS, bodyRect.x, bodyRight),
      fit10(state.reverbGain, bodyRect.y, bodyBottom),
    ];
  })();

  const getMoonRect = () => {
    const gain = ctx.state.deref().audio.delayVerb.reverbGain;
    const radius = fit01(gain, 30, 60);
    const size = radius * 2;

    const moonRect: IRect = {
      x: moonPos[0] - radius,
      y: moonPos[1] - radius,
      w: size,
      h: size,
    };

    return moonRect;
  };

  const gestureHandler: GestureHandler = (gesture, canvasWidth, canvasHeight) => {
    const state = ctx.state.deref().audio.delayVerb;
    const { bodyRect, bodyRight, bodyBottom } = getShapes(canvasWidth, canvasHeight);

    const maxTimeS = state.maxTimeS;

    const getIndexOfDelayAtPos = (pos: Point) => {
      const index = state.delays.findIndex((delay) =>
        isInsideRect(pos, getStarRect(delay, maxTimeS, bodyRect, TOUCH_SIZE)),
      );
      return index !== -1 ? index : undefined;
    };

    if (gesture.type === "start") {
      for (const info of gesture.active) {
        // reverb
        if (moonGestureId === undefined && isInsideRect(info.pos, getMoonRect())) {
          moonGestureId = info.id;
        }

        // delay
        const delayIndex = getIndexOfDelayAtPos(info.pos);
        const delayAlreadySelected = Array.from(selectedDelayIndices.values()).some(
          (index) => index === delayIndex,
        );

        if (delayIndex !== undefined && !delayAlreadySelected) {
          selectedDelayIndices.set(info.id, delayIndex);
        }
      }
    } else if (gesture.type === "drag") {
      const tx = defTransacted(ctx.state);
      tx.begin();

      for (const info of gesture.active) {
        if (info.id === moonGestureId) {
          tx.resetIn(
            ["audio", "delayVerb", "reverbGain"],
            fitClamped(info.pos[1], bodyRect.y, bodyBottom, 1, 0),
          );
          // decay isn't updated until gesture end event, so we update the view model immediately
          moonPos = [
            clamp(info.pos[0], bodyRect.x, bodyRight),
            clamp(info.pos[1], bodyRect.y, bodyBottom),
          ];

          shouldRender.set();
        }

        const delayIndex = selectedDelayIndices.get(info.id);
        if (delayIndex !== undefined) {
          tx.swapIn(["audio", "delayVerb", "delays", delayIndex], (delay) => ({
            ...delay,
            delayTimeS: fitClamped(info.pos[0], bodyRect.x, bodyRight, 0, maxTimeS),
            gain: fitClamped(info.pos[1], bodyRect.y, bodyBottom, 1, 0),
          }));
        }
      }

      tx.commit();
      shouldRender.set();
    } else if (gesture.type === "end") {
      const tx = defTransacted(ctx.state);
      tx.begin();

      if (didInputEnd(gesture, moonGestureId)) {
        tx.resetIn(
          ["audio", "delayVerb", "reverbDecayS"],
          posToDuration_s(moonPos[0], bodyRect, maxTimeS),
        );
        moonGestureId = undefined;
      }

      for (const infoId of selectedDelayIndices.keys()) {
        if (didInputEnd(gesture, infoId)) {
          selectedDelayIndices.delete(infoId);
        }
      }

      tx.commit();
      shouldRender.set();
    }
  };

  const render: WidgetRender = (canvas, canvasWidth, canvasHeight) => {
    const { bodyRect } = getShapes(canvasWidth, canvasHeight);

    return [
      canvas,
      { width: canvasWidth, height: canvasHeight },
      sky(ctx, { x: 0, y: 0, w: canvasWidth, h: canvasHeight }, getMoonRect()),
      stars(ctx, bodyRect, [...selectedDelayIndices.values()]),
    ];
  };

  return defWidget(gestureHandler, render, shouldRender);
};
