import { fit01, fitClamped } from "@thi.ng/math";
import { blob } from "./blob";

import { GestureInfo } from "@thi.ng/rstream-gestures";
import { IRect } from "../../../../../../api";
import { findDo } from "../../../../../../utils/findDo";
import { didInputEnd, GestureHandler } from "../../../../../../utils/gestureCanvasFactory";
import { IVowelState, WidgetFactory } from "../../../../app-main/api";
import { defShouldRenderForPlayback, defWidget, WidgetRender } from "../defWidget";
import { vowelSlider } from "./vowelSlider";

const getModelFromNorm = (oldModel: Readonly<IVowelState>, normValue: number): IVowelState => {
  const newModel = structuredClone(oldModel);
  const step = 1 / oldModel.vowels.length;
  const halfStep = step / 2;
  const scaledNorm = fit01(normValue, halfStep, 1 - halfStep);
  newModel.vowels.forEach((vowelModel, i) => {
    const midPoint = i * step + halfStep;
    const dist = Math.abs(scaledNorm - midPoint);
    vowelModel.gain = fitClamped(dist, 0, step, 1, 0);
  });

  return newModel;
};

export const vowelFactory: WidgetFactory = (ctx, audio) => {
  const shouldRender = defShouldRenderForPlayback(audio);

  const getVowelSize = (state: IVowelState, canvasHeight: number) => {
    const vowelSize = canvasHeight / state.vowels.length;
    return vowelSize;
  };

  const getRects = (canvasWidth: number, canvasHeight: number, vowelSize: number) => {
    const sliderRect: IRect = { x: canvasWidth - vowelSize, y: 0, w: vowelSize, h: canvasHeight };
    const bodyRect: IRect = { x: 0, y: 0, w: canvasWidth - sliderRect.w, h: canvasHeight - 1 };

    return { bodyRect, sliderRect };
  };

  let infoId: GestureInfo["id"] | undefined = undefined;
  let sliderY_n = 0.0;

  const gestureHandler: GestureHandler = (gesture, canvasWidth, canvasHeight) => {
    const state = ctx.state.deref().audio.vowel;
    const vowelSize = getVowelSize(state, canvasHeight);
    const { sliderRect } = getRects(canvasWidth, canvasHeight, vowelSize);

    const sliderToPoint = (y: number) => {
      sliderY_n = fitClamped(
        y,
        sliderRect.y + vowelSize / 2,
        sliderRect.y + sliderRect.h - vowelSize / 2,
        0,
        1,
      );
      const newModel = getModelFromNorm(state, sliderY_n);
      ctx.state.resetIn(["audio", "vowel"], newModel);
      shouldRender.set();
    };

    if (gesture.type === "start") {
      const info = gesture.active[0];
      if (infoId === undefined && info.pos[0] > sliderRect.x) {
        infoId = info.id;
        sliderToPoint(info.pos[1]);
      }
    } else if (gesture.type === "drag") {
      findDo(
        gesture.active,
        (info) => info.id === infoId,
        (info) => sliderToPoint(info.pos[1]),
      );
    } else if (didInputEnd(gesture, infoId)) {
      infoId = undefined;
    }
  };

  const render: WidgetRender = (canvas, canvasWidth, canvasHeight) => {
    const state = ctx.state.deref().audio.vowel;
    const vowelSize = getVowelSize(state, canvasHeight);
    const { bodyRect, sliderRect } = getRects(canvasWidth, canvasHeight, vowelSize);

    const playbackInfo = audio.getPlaybackInfo();

    return [
      canvas,
      { width: canvasWidth, height: canvasHeight, style: { border: "1px black solid" } },
      vowelSlider(state, sliderRect, vowelSize, sliderY_n),
      blob(state, bodyRect, sliderY_n, playbackInfo.level),
    ];
  };

  return defWidget(gestureHandler, render, shouldRender);
};
