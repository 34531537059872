// TODO split necessary polyfill parts into modules and import as needed
import { audioPolyfill } from "../audio/audioPolyfill";
audioPolyfill();

export class ReverbNode {
  public context: BaseAudioContext;
  public inputNode: GainNode;
  public outputNode: GainNode;

  protected convolverNode!: ConvolverNode;
  protected fadeNode: GainNode;

  protected _decayS: number;
  protected _preDelayS: number;

  constructor(context: BaseAudioContext) {
    this.context = context;
    this._decayS = 3;
    this._preDelayS = 0.001;

    this.inputNode = this.context.createGain();
    this.outputNode = this.context.createGain();
    this.fadeNode = this.context.createGain();

    this.generateImpulseResponse();
  }

  get decayS() {
    return this._decayS;
  }
  set decayS(decayS) {
    if (decayS === this.decayS) {
      return;
    }
    this._decayS = decayS;
    this.generateImpulseResponse();
  }

  get preDelayS() {
    return this._preDelayS;
  }
  set preDelayS(preDelayS) {
    const safePreDelayS = Math.min(preDelayS, this.decayS);
    if (safePreDelayS === this.preDelayS) {
      return;
    }
    this._preDelayS = safePreDelayS;
    this.generateImpulseResponse();
  }

  public connect(destination: AudioNode | AudioParam, output?: number, input?: number) {
    if (destination instanceof AudioNode) {
      this.outputNode.connect(destination, output, input);
      return destination;
    }

    this.outputNode.connect(destination, output);
    return undefined;
  }

  public disconnect(
    outputOrDestination?: number | AudioNode | AudioParam,
    output?: number,
    input?: number,
  ): void {
    this.inputNode.disconnect();
    this.convolverNode.disconnect();
    this.fadeNode.disconnect();

    if (outputOrDestination === undefined) {
      this.outputNode.disconnect();
    } else if (typeof outputOrDestination === "number") {
      this.outputNode.disconnect(outputOrDestination);
    } else if (outputOrDestination instanceof AudioParam) {
      this.outputNode.disconnect(outputOrDestination);
    } else if (outputOrDestination instanceof AudioNode) {
      if (output !== undefined && input !== undefined) {
        this.outputNode.disconnect(outputOrDestination, output, input);
      } else if (output !== undefined && input === undefined) {
        this.outputNode.disconnect(outputOrDestination, output);
      } else {
        this.outputNode.disconnect(outputOrDestination);
      }
    }
  }

  protected generateImpulseResponse() {
    const numSamples = Math.max(1, this.decayS * this.context.sampleRate);
    const buffer = this.context.createBuffer(1, numSamples, this.context.sampleRate);

    const attackDurationS = 0.003;
    const attackStartIndex = Math.max(Math.round(this.preDelayS * numSamples), 1);
    const attackEndIndex = attackStartIndex + Math.round(attackDurationS * numSamples);
    const attackStep = 1.0 / (attackEndIndex - attackStartIndex);
    const tailLength = numSamples * 3 - attackEndIndex;
    const data = buffer.getChannelData(0);
    for (let i = 0; i < numSamples; i++) {
      const noise = Math.random() * 2 - 1;
      const gain =
        i < attackStartIndex
          ? 0
          : i < attackEndIndex
          ? (i - attackStartIndex) * attackStep
          : 1 * Math.pow(0.0001 / 1, (i - attackEndIndex) / tailLength);
      data[i] = noise * gain;
    }
    data[numSamples - 1] = 0;

    // TODO cross fade
    if (this.convolverNode !== undefined) {
      this.convolverNode.disconnect();
      this.inputNode.disconnect(this.convolverNode);
    }

    this.convolverNode = this.context.createConvolver();
    this.convolverNode.buffer = buffer;

    this.inputNode.connect(this.convolverNode);
    this.convolverNode.connect(this.outputNode);
  }
}
