import { IRect } from "../../../../../../api";
import { IAppContext } from "../../../../app-main/api";
import { getStarRect, star } from "./star";

export const stars = (ctx: IAppContext, rect: IRect, selectedIndices: number[]) => {
  const state = ctx.state.deref().audio.delayVerb;
  const delays = state.delays;

  return delays.map((delay, i) => {
    const starRect = getStarRect(delay, state.maxTimeS, rect);
    return [
      star(starRect, {
        fill: selectedIndices.includes(i) ? "rgb(255, 255, 255)" : "rgb(200, 200, 200)",
        stroke: "black",
        weight: 1,
      }),
      [
        "text",
        {
          fill: "white",
          baseline: "bottom",
          align: "center",
          font: "17px -apple-system, BlinkMacSystemFont, sans-serif",
        },
        [starRect.x + starRect.w * 0.5, starRect.y - starRect.h * 0.2],
        `${delay.delayTimeS.toFixed(2)}s`,
      ],
    ];
  });
};
