export const findDo = <T>(
  array: T[],
  predicate: (x: T, i: number) => boolean,
  action: (x: T, i: number) => void,
) => {
  const index = array.findIndex(predicate);
  if (index !== -1) {
    action(array[index], index);
  }
};
