export const chorusOverlay = (width: number, heights: { creation: number; waves: number }) => {
  const marginY = 5;

  return [
    ["rect", { fill: "rgb(43, 156, 212)" }, [0, heights.waves], width, heights.creation],
    ["rect", { fill: "black" }, [0, heights.waves], width, 1],
    [
      "g",
      { font: "17px -apple-system, BlinkMacSystemFont, sans-serif" },
      ["text", { fill: "white", baseline: "bottom" }, [3, heights.waves - marginY], "0%"],
      ["text", { fill: "white", baseline: "top" }, [3, marginY], "100%"],
      [
        "text",
        { fill: "white", baseline: "middle", align: "center" },
        [width * 0.5, heights.waves + heights.creation * 0.5],
        "Drag to add ↑",
      ],
    ],
  ];
};
