import { IVowelState } from "../../app-main/api";

const NUM_FORMANTS = 3;

interface IFormantParams {
  [key: string]: number[];

  frequency: number[];
  q: number[];
  gain: number[];
}

interface IFilterParams {
  frequency: number;
  q: number;
  gain: number;
}

interface IFormantFilter {
  filter: BiquadFilterNode;
  filterGain: GainNode;
}

type Vowel =
  | "i"
  | "y"
  | "e"
  | "ø"
  | "ɛ"
  | "œ"
  | "a"
  | "ɶ"
  | "ɑ"
  | "ɒ"
  | "ʌ"
  | "ɔ"
  | "ɤ"
  | "o"
  | "ɯ"
  | "u";

type FormantParamsCollection = { [V in Vowel]: IFormantParams };

let filters: IFormantFilter[];
// let osc: OscillatorNode;
// let vibOsc: OscillatorNode;
// let vibGain: GainNode;
// let vibBaseFreq: ConstantSourceNode;

const formantFrequencies: FormantParamsCollection = {
  i: { frequency: [240, 2400, 2160], q: [10, 10, 10], gain: [0.8, 0.2, 0.3] },
  y: { frequency: [235, 2100, 1865], q: [10, 10, 10], gain: [0.8, 0.2, 0.3] },
  e: { frequency: [390, 2300, 1910], q: [10, 10, 10], gain: [0.8, 0.4, 0.5] },
  ø: { frequency: [370, 1900, 1530], q: [10, 10, 10], gain: [0.8, 0.4, 0.5] },
  ɛ: { frequency: [610, 1900, 1290], q: [10, 10, 10], gain: [0.8, 0.4, 0.5] },
  œ: { frequency: [585, 1710, 1125], q: [10, 10, 10], gain: [0.8, 0.4, 0.5] },
  a: { frequency: [850, 1610, 760], q: [10, 10, 10], gain: [0.8, 0.2, 0.8] },
  ɶ: { frequency: [820, 1530, 710], q: [10, 10, 10], gain: [0.8, 0.2, 0.8] },
  ɑ: { frequency: [750, 940, 190], q: [10, 10, 10], gain: [0.8, 0.2, 0.8] },
  ɒ: { frequency: [700, 760, 60], q: [10, 10, 10], gain: [0.8, 0.2, 0.8] },
  ʌ: { frequency: [600, 1170, 570], q: [10, 10, 10], gain: [0.7, 0.5, 0.2] },
  ɔ: { frequency: [500, 700, 200], q: [10, 10, 10], gain: [0.7, 0.5, 0.2] },
  ɤ: { frequency: [460, 1310, 850], q: [10, 10, 10], gain: [0.7, 0.5, 0.2] },
  o: { frequency: [360, 640, 280], q: [10, 10, 10], gain: [0.7, 0.5, 0.2] },
  ɯ: { frequency: [300, 1390, 1090], q: [10, 10, 10], gain: [0.7, 0.5, 0.2] },
  u: { frequency: [250, 595, 345], q: [10, 10, 10], gain: [0.7, 0.5, 0.2] },
};

const vowelIPA: { [key: string]: "a" | "ɛ" | "i" | "ɔ" | "u" } = {
  a: "a",
  e: "ɛ",
  i: "i",
  o: "ɔ",
  u: "u",
};

export const getFilterParams = (model: IVowelState): IFilterParams[] => {
  const vowelNames: string[] = model.vowels.map(({ name }) => name);
  const vowelWeights = model.vowels.map(({ gain }) => gain);
  const summedWeights = vowelWeights.reduce((v1, v2) => v1 + v2, 0);
  const params = ["frequency", "q", "gain"];
  const groupedParams = params.map((param) => {
    const weightedTotals = vowelNames.reduce((acc, vowelName, i) => {
      const nameIPA = vowelIPA[vowelName];
      const vowelWeight = vowelWeights[i];
      const paramValue = formantFrequencies[nameIPA][param];
      return acc.map((v, j) => v + vowelWeight * paramValue[j]);
    }, Array(NUM_FORMANTS).fill(0));

    const weightedAverage = weightedTotals.map((v) => v / summedWeights);
    return weightedAverage;
  });

  const filterParams = Array.from(
    { length: NUM_FORMANTS },
    (_, i): IFilterParams =>
      params.reduce((acc, p, pIdx) => {
        return Object.assign({}, acc, { [p]: groupedParams[pIdx][i] });
      }, {} as IFilterParams),
  );

  return filterParams;
};

export const updateVowelGraph = (audioContext: BaseAudioContext, model: IVowelState) => {
  const filterParams = getFilterParams(model);
  filters.forEach(({ filter, filterGain }, i) => {
    const whenS = audioContext.currentTime + 0.02;
    filter.frequency.linearRampToValueAtTime(filterParams[i].frequency, whenS);
    filter.Q.linearRampToValueAtTime(filterParams[i].q, whenS);
    filterGain.gain.linearRampToValueAtTime(filterParams[i].gain, whenS);
  });
};

export const createVowelGraph = (
  audioContext: BaseAudioContext,
  inputNode: AudioNode,
  outputNode: AudioNode,
  state: IVowelState,
) => {
  const masterGain = audioContext.createGain();
  masterGain.gain.value = 2;
  masterGain.connect(outputNode);

  filters = Array.from({ length: NUM_FORMANTS }, () => {
    const filter = audioContext.createBiquadFilter();
    const filterGain = audioContext.createGain();
    filter.type = "bandpass";
    filterGain.gain.value = 1;
    inputNode.connect(filter);
    filter.connect(filterGain);
    filterGain.connect(masterGain);

    return { filter, filterGain };
  });

  updateVowelGraph(audioContext, state);
};

// export const setOscFreq = (freq: number) => {
//   if (vibBaseFreq === undefined) { return; }

//   osc.frequency.value = freq / 2;
//   vibBaseFreq.offset.value = freq / 2;
// };
