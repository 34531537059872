import { mapIndexed } from "@thi.ng/transducers";

import { IRect } from "../../../../../../api";
import { IVowelState } from "../../../../app-main/api";
import { marker } from "./marker";
import { vowel } from "./vowel";

export const vowelSlider = (
  model: IVowelState,
  rect: IRect,
  vowelSize: number,
  sliderY_n: number,
) => {
  return [
    ...Array.from(
      mapIndexed(
        (i, vowelModel) =>
          vowel(vowelModel, {
            x: rect.x,
            y: rect.y + i * vowelSize,
            w: vowelSize,
            h: vowelSize,
          }),
        model.vowels,
      ),
    ),
    marker(sliderY_n, rect),
  ];
};
