import { fit01 } from "@thi.ng/math";
import { mapcat, range } from "@thi.ng/transducers";
import { IRect, Point } from "../../../../../../api";
import { ILFOState } from "../../../../app-main/api";

export const vertComponent = (
  state: ILFOState,
  bodyRect: IRect,
  lineWeight: number,
  halfLineWeight: number,
  getRelPoint: (point: Point, bodyRect_: IRect) => Point,
) => {
  // TODO refactor
  return [
    [
      "path",
      { stroke: "rgb(43, 156, 212)", weight: lineWeight, lineCap: "square" },
      [
        ...Array.from(
          mapcat((i) => {
            const step = 1 / state.frequency;
            const width = 0.5;
            const stepWidth = step * width;
            const point = getRelPoint([i * step + stepWidth, 0.0], bodyRect);

            const y = fit01(
              state.depth,
              bodyRect.y + halfLineWeight,
              bodyRect.y + bodyRect.h - halfLineWeight,
            );

            point[1] += halfLineWeight;

            const parts: Array<[string, Point]> = [];
            if (point[0] < bodyRect.x + bodyRect.w) {
              parts.push(["M", point], ["L", [point[0], y]]);
            }

            return parts;
          }, range(state.frequency)),
        ),
      ],
    ],
    [
      "path",
      { stroke: "rgb(43, 212, 156)", weight: lineWeight, lineCap: "square" },
      [
        ...Array.from(
          mapcat((i) => {
            const step = 1 / state.frequency;
            const point = getRelPoint([(i + 1) * step, 0.0], bodyRect);

            const y = fit01(
              state.depth,
              bodyRect.y + halfLineWeight,
              bodyRect.y + bodyRect.h - halfLineWeight,
            );

            point[0] -= halfLineWeight;
            point[1] += halfLineWeight;

            const parts: Array<[string, Point]> = [];
            if (point[0] < bodyRect.x + bodyRect.w) {
              parts.push(["M", point], ["L", [point[0], y]]);
            }

            return parts;
          }, range(state.frequency)),
        ),
      ],
    ],
  ];
};
