import { IRect } from "../../../../../../api";
import { font } from "../../../../../../utils/font";
import { IVowel } from "../../../../app-main/api";

const VOWEL_GUI_NAMES: { [key: string]: string } = {
  a: "AH",
  e: "EH",
  i: "EE",
  o: "OH",
  u: "OO",
};

export const vowel = (model: IVowel, rect: IRect) => {
  return [
    [
      "line",
      { stroke: "rgba(255, 255, 255, 0.5)", weight: 0.5 },
      [rect.x, rect.y],
      [rect.x, rect.y + rect.h],
    ],
    [
      "line",
      { stroke: "rgba(255, 255, 255, 0.5)", weight: 0.5 },
      [rect.x, rect.y + rect.h],
      [rect.x + rect.w, rect.y + rect.h],
    ],
    [
      "text",
      {
        fill: "white",
        font: font(rect.h * 0.3),
        align: "center",
        baseline: "middle",
      },
      [rect.x + rect.w / 2, rect.y + rect.h / 2],
      VOWEL_GUI_NAMES[model.name] || model.name,
    ],
  ];
};
