/* eslint-disable no-console */

export const handleWebSocketPayload = (data: string | Blob, handler: (object: unknown) => void) => {
  if (data instanceof Blob) {
    const reader = new FileReader();

    reader.onload = () => {
      if (typeof reader.result !== "string") {
        throw new TypeError("FileReader result is not a string");
      }
      handler(JSON.parse(reader.result));
    };

    reader.readAsText(data);
  } else {
    try {
      const message = JSON.parse(data);
      handler(message);
    } catch (error) {
      console.error("could not parse message");
      console.error(error);
    }
  }
};
