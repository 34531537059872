export type WaveformFunction = (index: number, length: number) => number;

export const sineFunction: WaveformFunction = (i, length) =>
  Math.sin(2 * Math.PI * i * (1 / (length - 1)));
export const squareFunction: WaveformFunction = (i, length) => (i <= length / 2 ? 1 : -1);

export class WaveformNode {
  public static MIN_HZ = 20;
  public static getBufferLength(sampleRate: number) {
    return Math.round(sampleRate / WaveformNode.MIN_HZ);
  }

  public static createWaveformData(waveformFunction: WaveformFunction, sampleRate: number) {
    const length = WaveformNode.getBufferLength(sampleRate);

    const data = new Float32Array(length);
    for (let i = 0; i < length; i++) {
      data[i] = waveformFunction(i, length);
    }

    return data;
  }

  public outputNode: GainNode;

  protected context: BaseAudioContext;
  protected bufferLength: number;
  protected sourceNode!: AudioBufferSourceNode;
  protected fadeNode!: GainNode;
  protected _frequency: number;
  protected _waveformFunction?: WaveformFunction;
  protected isPlaying: boolean;

  constructor(context: BaseAudioContext, waveformData?: Float32Array) {
    this.context = context;

    this._frequency = 440;
    this.outputNode = this.context.createGain();
    this.isPlaying = false;

    this.bufferLength = WaveformNode.getBufferLength(context.sampleRate);

    this.sourceNode = this.context.createBufferSource();
    this.sourceNode.buffer = context.createBuffer(1, this.bufferLength, context.sampleRate);

    if (waveformData === undefined) {
      this._waveformFunction = sineFunction;
      this.updateBuffer(
        WaveformNode.createWaveformData(this._waveformFunction, context.sampleRate),
      );
    } else {
      if (waveformData.length !== this.bufferLength) {
        throw new RangeError(
          `data length mismatch: ${waveformData.length}, should be ${this.bufferLength}`,
        );
      }
      this.updateBuffer(waveformData);
    }

    this.sourceNode.loop = true;
    this.updateFrequency();

    this.fadeNode = this.context.createGain();

    this.sourceNode.connect(this.fadeNode);
    this.fadeNode.connect(this.outputNode);
  }

  // overrides
  /* eslint-disable @typescript-eslint/no-empty-function */
  public onended() {}
  /* eslint-enable @typescript-eslint/no-empty-function */

  // TODO be able to access the rate AudioParam for scheduling
  get frequency() {
    return this._frequency;
  }
  set frequency(frequency) {
    if (frequency === this.frequency) {
      return;
    }
    this._frequency = frequency;
    this.updateFrequency();
  }

  get waveformFunction() {
    return this._waveformFunction;
  }
  set waveformFunction(waveformFunction) {
    if (waveformFunction === this.waveformFunction) {
      return;
    }
    this._waveformFunction = waveformFunction;
    if (waveformFunction === undefined) {
      return;
    }

    this.updateBuffer(WaveformNode.createWaveformData(waveformFunction, this.context.sampleRate));

    // if (!this.isPlaying) {
    //   this.updateBuffer();
    //   return;
    // }

    // fade out
    // const prevFadeNode = this.fadeNode;
    // const endTimeS = this.context.currentTime + 0.02;

    // prevFadeNode.gain.linearRampToValueAtTime(0, endTimeS);

    // create new nodes, fade in
    // this.fadeNode.gain.linearRampToValueAtTime(1.0, endTimeS);
  }

  public start(when?: number, offset?: number, duration?: number) {
    this.isPlaying = true;
    this.sourceNode.start(when, offset, duration);
  }

  public stop(when?: number) {
    this.isPlaying = false;
    this.sourceNode.onended = () => this.onended();
    this.sourceNode.stop(when);
  }

  public connect(destination: AudioNode | AudioParam, output?: number, input?: number) {
    if (destination instanceof AudioNode) {
      this.outputNode.connect(destination, output, input);
      return destination;
    }

    this.outputNode.connect(destination, output);
    return undefined;
  }

  public disconnect(
    outputOrDestination?: number | AudioNode | AudioParam,
    output?: number,
    input?: number,
  ): void {
    this.sourceNode.disconnect();
    // this.gainNode.disconnect(); // this is aliased as outputNode

    if (outputOrDestination === undefined) {
      this.outputNode.disconnect();
    } else if (typeof outputOrDestination === "number") {
      this.outputNode.disconnect(outputOrDestination);
    } else if (outputOrDestination instanceof AudioParam) {
      this.outputNode.disconnect(outputOrDestination);
    } else if (outputOrDestination instanceof AudioNode) {
      if (output !== undefined && input !== undefined) {
        this.outputNode.disconnect(outputOrDestination, output, input);
      } else if (output !== undefined && input === undefined) {
        this.outputNode.disconnect(outputOrDestination, output);
      } else {
        this.outputNode.disconnect(outputOrDestination);
      }
    }
  }

  protected updateBuffer(data: Float32Array) {
    if (this.sourceNode.buffer === null) {
      throw new Error("sourceNode buffer is null");
    }
    this.sourceNode.buffer.copyToChannel(data, 0);
  }

  protected updateFrequency(waitS = 0.001) {
    const rate = this.frequency / WaveformNode.MIN_HZ;
    const when = this.context.currentTime + waitS;
    this.sourceNode.playbackRate.linearRampToValueAtTime(rate, when);
  }
}
