import { fit01 } from "@thi.ng/math";

import { IRect } from "../../../../../../api";

export const marker = (sliderY_n: number, parentRect: IRect) => {
  const x = parentRect.x;
  const height = parentRect.w;
  const bottom = parentRect.y + parentRect.h;

  const y = fit01(sliderY_n, parentRect.y, bottom - height);
  const width = parentRect.w;

  return ["rect", { fill: "rgba(212, 100, 100, 0.5)" }, [x, y], width, height];
};
