import { Atom } from "@thi.ng/atom";

import { ClientMode, IRoom, Point } from "../../../api";
import { ChorusNode } from "../../../audio/ChorusNode";
import { FeedbackDelayNode } from "../../../audio/FeedbackDelayNode";
import { IMusicianState } from "../../../sync/api";
import { Musician } from "../../../sync/Musician";
import { ICredit } from "../api";
import { AudioHandler } from "../src/audio/AudioHandler";
import { playback } from "../src/components/widgets/playback";

export let WIDGET_WIDTH = window.innerWidth - 20;
export let WIDGET_HEIGHT = WIDGET_WIDTH / 1.75;

export const MAX_HEIGHT = window.innerHeight - 300;
if (WIDGET_HEIGHT > MAX_HEIGHT) {
  WIDGET_HEIGHT = MAX_HEIGHT;
  WIDGET_WIDTH = Math.round(WIDGET_HEIGHT * 1.75);
}

// Audio
// -----------------------------------------------------------------------------

// Chorus
// -----------------------------------------------------------------------------
export interface IChorusVoice {
  id: number;
  gain: number;
  depth: number;
  speed: number;
}

export interface IChorusVoiceNodes {
  chorusNode: ChorusNode;
  gainNode: GainNode;
  inputNode: AudioNode;
}

export interface IChorusState {
  voices: IChorusVoice[];
  startValues: IChorusVoice[];
}

// Harmonics
// -----------------------------------------------------------------------------

export interface IHarmonicsState {
  gains: number[];
  minValue: number;
  maxGain: number;
}

// DelayVerb
// -----------------------------------------------------------------------------

export interface IDelayVoice {
  id: number;
  gain: number;
  delayTimeS: number;
}

export interface IDelayNodes {
  delayNode: FeedbackDelayNode;
  gainNode: GainNode;
  inputNode: AudioNode;
}

export interface IDelayVerbState {
  delays: IDelayVoice[];
  reverbGain: number;
  reverbDecayS: number;
  isSettingReverb: boolean;
  maxTimeS: number;
  isCreatingDelay: boolean;
  canDeleteDelay: boolean;
  nextDelayId: number;
}

// Envelope
// -----------------------------------------------------------------------------
export type Envelope = Point[];

export interface IEnvelopeState {
  envelope: Envelope;
}

// Waveform
// -----------------------------------------------------------------------------

export interface IWaveformHandle {
  waveformPoint: Point;
  size: number;
  isGrabbed: boolean;
  isHovered: boolean;
}

export interface IWaveformSegment {
  handleIdxA: number;
  handleIdxB: number;
  isHovered: boolean;
  isGrabbed: boolean;
  control: Point;
}

export type WaveformData = number[];
export interface IWaveformState {
  handles: IWaveformHandle[];
  segments: IWaveformSegment[];
}

// LFO
// -----------------------------------------------------------------------------
export interface ILFOState {
  frequency: number;
  depth: number;
  minFrequency: number;
  maxFrequency: number;
}

// Vowels
// -----------------------------------------------------------------------------
export interface IVowel {
  name: string;
  gain: number;
}

export interface IVowelState {
  vowels: IVowel[];
}

export interface IAudioState {
  noteDurationS: number;
  waveform: IWaveformState;
  envelope: IEnvelopeState;
  chorus: IChorusState;
  harmonics: IHarmonicsState;
  delayVerb: IDelayVerbState;
  lfo: ILFOState;
  vowel: IVowelState;
}

export interface IPreset {
  name: string;
  preset: IAudioState;
}

// App
// -----------------------------------------------------------------------------

export interface INamedPreset {
  name: string;
  preset: IAudioState;
}

export interface IAppState {
  audio: IAudioState;
  musicianState: IMusicianState;
  presets: INamedPreset[];
}

export interface ISavedState {
  version: string;
  state: IAppState;
}

export interface IAppConfig {
  localStorageKey: string;
  localStorageVersion: string;
  isSaveEnabled: boolean;
  domRoot: string | Element;
  title: string;
  credits: ICredit[];
  room: IRoom;
  initialState: IAppState;
  isLive: boolean;
  serverUrl: string;
}

export interface IAppContext {
  audioContext: AudioContext;
  state: Atom<IAppState>;
  mode: Atom<ClientMode>;
  config: IAppConfig;
  musician?: Musician;
  loadPreset: (preset: IPreset) => void;
}

// Interaction
// -----------------------------------------------------------------------------
export interface Widget {
  triggerRender: () => void;
  cmp: (width: number, height: number) => unknown;
}
export type WidgetFactory = (ctx: IAppContext, audio: AudioHandler) => Widget;

export interface WrappedWidget {
  cmp: () => unknown;
  triggerRender: () => void;
}

export const defWrappedWidget = (
  ctx: IAppContext,
  widget: Widget,
  title: string,
  audioHandler: AudioHandler,
): WrappedWidget => {
  const wrappedWidget: WrappedWidget = {
    cmp: () => {
      return [
        "div",
        ["h3", { style: { "margin-top": "100px" } }, title],
        widget.cmp(WIDGET_WIDTH, WIDGET_HEIGHT),
        playback(ctx, audioHandler),
      ];
    },

    triggerRender: () => widget.triggerRender(),
  };

  return wrappedWidget;
};
