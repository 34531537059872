import { audioPolyfill } from "../audio/audioPolyfill";

audioPolyfill();

export class FeedbackDelayNode {
  public context: BaseAudioContext;

  protected delayNode: DelayNode;
  protected feedbackNode: GainNode;

  protected maxDelayTimeS: number;
  protected _delayTimeS: number;
  protected _feedbackGain: number;

  constructor(context: BaseAudioContext, maxDelayTimeS: number) {
    this.context = context;

    this.maxDelayTimeS = maxDelayTimeS;
    this._delayTimeS = 0.75;
    this._feedbackGain = 0.5;

    this.delayNode = context.createDelay(maxDelayTimeS);
    this.delayNode.delayTime.value = this._delayTimeS;

    this.feedbackNode = context.createGain();
    this.feedbackNode.gain.value = this._feedbackGain;

    this.delayNode.connect(this.feedbackNode);
    this.feedbackNode.connect(this.delayNode);
  }

  get inputNode() {
    return this.delayNode;
  }
  get outputNode() {
    return this.delayNode;
  }

  get delayTimeS() {
    return this.delayNode.delayTime;
  }

  get feedbackGain() {
    return this._feedbackGain;
  }
  set feedbackGain(gain) {
    if (gain === this.feedbackGain) {
      return;
    }
    this._feedbackGain = gain;
    this.feedbackNode.gain.setValueAtTime(this._feedbackGain, 0.001);
  }

  public connect(destination: AudioNode | AudioParam, output?: number, input?: number) {
    if (destination instanceof AudioNode) {
      this.outputNode.connect(destination, output, input);
      return destination;
    }

    this.outputNode.connect(destination, output);
    return undefined;
  }

  public disconnect(
    outputOrDestination?: number | AudioNode | AudioParam,
    output?: number,
    input?: number,
  ): void {
    // this.delayNode.disconnect(); // this is this.outputNode, disconnected below
    this.feedbackNode.disconnect();

    if (outputOrDestination === undefined) {
      this.outputNode.disconnect();
    } else if (typeof outputOrDestination === "number") {
      this.outputNode.disconnect(outputOrDestination);
    } else if (outputOrDestination instanceof AudioParam) {
      this.outputNode.disconnect(outputOrDestination);
    } else if (outputOrDestination instanceof AudioNode) {
      if (output !== undefined && input !== undefined) {
        this.outputNode.disconnect(outputOrDestination, output, input);
      } else if (output !== undefined && input === undefined) {
        this.outputNode.disconnect(outputOrDestination, output);
      } else {
        this.outputNode.disconnect(outputOrDestination);
      }
    }
  }
}
