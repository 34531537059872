import { IRect } from "../../../../../../api";
import { font } from "../../../../../../utils/font";
import { ILFOState } from "../../../../app-main/api";

export const freqLabelCmp = (state: ILFOState, bodyRect: IRect) => {
  const fontSize = bodyRect.w * 0.03;
  const textMargin = bodyRect.w * 0.01;
  return [
    "text",
    {
      fill: "rgb(43, 212, 156)",
      font: font(fontSize),
      align: "right",
      baseline: "top",
    },
    [bodyRect.x + bodyRect.w - textMargin, bodyRect.y + bodyRect.h - fontSize - textMargin],
    `${state.frequency.toFixed(2)}hz`,
  ];
};
