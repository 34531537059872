import { Syncer } from "../../../../../sync/Syncer";
import { IAppContext } from "../../../app-main/api";

let debug = false;

export const status = (ctx: IAppContext) => {
  if (ctx.musician === undefined) {
    return [];
  }

  const states = Object.values(ctx.musician.bufferLoadingStates);
  const total = states.length;
  const numDone = states.filter((state) => state).length;
  const isDone = numDone === total;
  // prettier-ignore
  /* eslint-disable no-multi-spaces */
  const label = !ctx.musician.isConnected     ? "Connecting..."
              : !ctx.musician.syncer.isSynced ? `Syncing... ${ctx.musician.syncer.syncCount} / ${Syncer.syncLimit}`
              : isDone                        ? `Ready ${ctx.musician.syncer.serverOffsetMs}`
              :                                 `Loading audio ${numDone} / ${total}`;
  /* eslint-enable no-multi-spaces */

  return [
    "div",
    {
      style: {
        "margin-bottom": "20px",
      },

      onclick: () => (debug = !debug),
    },
    [
      "span",
      {
        style: {
          color: "black",
          padding: "5px 10px",
          "border-radius": "3px",
          // prettier-ignore
          /* eslint-disable no-multi-spaces */
          "background-color": !ctx.musician.isConnected     ? "rgb(249, 182, 118)" // connecting
                            : !ctx.musician.syncer.isSynced ? "rgb(43, 156, 212)"  // syncing/loading
                            : isDone                        ? "rgb(43, 212, 156)"  // done
                                                            : "rgb(43, 156, 212)", // syncing/loading
          /* eslint-enable no-multi-spaces */
        },
      },
      `Status: ${label} ${debug ? `${ctx.musician.serverUrl}` : ""}`,
    ],
  ];
};
