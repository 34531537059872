import { IAudioState } from "../../app-main/api";

export const preset: IAudioState = {
  noteDurationS: 1.5,
  chorus: {
    voices: [
      {
        id: 2,
        gain: 0.1074523396880416,
        depth: 0.1074523396880416,
        speed: 0.5148514851485149,
      },
      {
        id: 1,
        gain: 0,
        depth: 0,
        speed: 0.013861386138613862,
      },
      {
        id: 3,
        gain: 0,
        depth: 0,
        speed: 1,
      },
    ],
    startValues: [
      {
        id: 1,
        gain: 0,
        depth: 0,
        speed: 0.013861386138613862,
      },
      {
        id: 3,
        gain: 0,
        depth: 0,
        speed: 1,
      },
      {
        id: 2,
        gain: 0.0831889081455806,
        depth: 0.0831889081455806,
        speed: 0.5148514851485149,
      },
    ],
  },
  harmonics: {
    gains: [
      0.5148938474870017, 0.14227686308492205, 0.13534445407279028, 0.11628032928942811,
      0.11628032928942811, 0.11628032928942811, 0.12321273830155977, 0,
    ],
    minValue: 0.1,
    maxGain: 0.5,
  },
  delayVerb: {
    delays: [
      {
        delayTimeS: 0.0,
        gain: 0.0,
        id: 0,
      },
    ],
    nextDelayId: 4,
    reverbGain: 0.7209163778162911,
    reverbDecayS: 0.799009900990099,
    maxTimeS: 2,
    isSettingReverb: false,
    isCreatingDelay: false,
    canDeleteDelay: false,
  },
  envelope: {
    envelope: [
      [0, 0],
      [0, 0],
      [0.0025773195876288655, 0],
      [0.006494845360824744, 0.011662011173184352],
      [0.020144329896907218, 0.0924022346368715],
      [0.044053608247422674, 0.20565083798882677],
      [0.06977896907216492, 0.30190540037243946],
      [0.09572008247422677, 0.380514748603352],
      [0.11729771546391748, 0.43065987337057726],
      [0.13497219298969068, 0.4614918271880819],
      [0.14931796057731955, 0.4799909994785847],
      [0.16100076021443296, 0.49109050285288636],
      [0.1703469999241237, 0.49849508383463687],
      [0.1780301772588866, 0.5029378324236872],
      [0.18458909026071751, 0.5056034815771173],
      [0.1925166330333163, 0.5072028710691754],
      [0.20236382189057056, 0.5103971416359186],
      [0.2174580678217348, 0.5167829777189813],
      [0.2386056295151198, 0.5228491162403274],
      [0.2658329572203433, 0.526488799353135],
      [0.29771791216802723, 0.5271828513064806],
      [0.3302361854045249, 0.5231300087354712],
      [0.3634672988390838, 0.5162290294498488],
      [0.3935573442259062, 0.5091089260497976],
      [0.41907267950443633, 0.503347106095428],
      [0.440515875562312, 0.49840025620846723],
      [0.4582889891096434, 0.4954321462762908],
      [0.4727136655145188, 0.4936512803169849],
      [0.4848719633394501, 0.4925827607414014],
      [0.4966604572694982, 0.4919416489960513],
      [0.5085654792176604, 0.49155698194884123],
      [0.5290173318277366, 0.4808978763201427],
      [0.5532138654621892, 0.4640741075425512],
      [0.578962844947071, 0.4435515408756238],
      [0.6003867708030176, 0.4304931219182979],
      [0.618969210456847, 0.42265807054390236],
      [0.6381650590871271, 0.41274288701907885],
      [0.6640372019088769, 0.3941308346323039],
      [0.7016421326611222, 0.3561479607421384],
      [0.7599734999433307, 0.27451279879165175],
      [0.8079787999546646, 0.2039302118634454],
      [0.8463830399637317, 0.12235812711806723],
      [0.8771064319709854, 0.07341487627084034],
      [0.9016851455767882, 0.0440489257625042],
      [0.9213481164614306, 0.02642935545750252],
      [0.9370784931691445, 0.01585761327450151],
      [0.9496627945353155, 0.009514567964700906],
      [0.9597302356282524, 0.005708740778820543],
      [0.967784188502602, 0.003425244467292326],
      [0.9742273508020816, 0.0020551466803753957],
      [1, 0],
    ],
  },
  waveform: {
    handles: [
      {
        waveformPoint: [0, 0],
        size: 40,
        isGrabbed: false,
        isHovered: false,
      },
      {
        waveformPoint: [0.25, 1],
        size: 40,
        isGrabbed: false,
        isHovered: false,
      },
      {
        waveformPoint: [0.5, 0],
        size: 40,
        isGrabbed: false,
        isHovered: false,
      },
      {
        waveformPoint: [0.75, -1],
        size: 40,
        isGrabbed: false,
        isHovered: false,
      },
      {
        waveformPoint: [1, 0],
        size: 40,
        isGrabbed: false,
        isHovered: false,
      },
    ],
    segments: [
      {
        handleIdxA: 0,
        handleIdxB: 1,
        isHovered: true,
        isGrabbed: false,
        control: [0.125, 0.5],
      },
      {
        handleIdxA: 1,
        handleIdxB: 2,
        isHovered: true,
        isGrabbed: false,
        control: [0.375, 0.5],
      },
      {
        handleIdxA: 2,
        handleIdxB: 3,
        isHovered: true,
        isGrabbed: false,
        control: [0.625, -0.5],
      },
      {
        handleIdxA: 3,
        handleIdxB: 4,
        isHovered: true,
        isGrabbed: false,
        control: [0.75, -1],
      },
    ],
  },
  lfo: {
    frequency: 7.192519251925193,
    depth: 0.10084033613445378,
    minFrequency: 1,
    maxFrequency: 20,
  },
  vowel: {
    vowels: [
      {
        name: "a",
        gain: 0,
      },
      {
        name: "e",
        gain: 0,
      },
      {
        name: "i",
        gain: 0,
      },
      {
        name: "o",
        gain: 0.5988951473136913,
      },
      {
        name: "u",
        gain: 0.4011048526863089,
      },
    ],
  },
};
