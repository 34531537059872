import {
  GestureCanvas,
  gestureCanvasFactory,
  GestureHandler,
} from "../../../../../utils/gestureCanvasFactory";
import { Widget } from "../../../app-main/api";
import { AudioHandler } from "../../audio/AudioHandler";

export type WidgetRender = (canvas: GestureCanvas, width: number, height: number) => unknown;

export interface ShouldRender {
  get: () => boolean;
  set: () => void;
  reset: () => void;
}

export const defShouldRender = (): ShouldRender => {
  let shouldRender = true;
  return {
    get: () => shouldRender,
    set: () => (shouldRender = true),
    reset: () => (shouldRender = false),
  };
};

export const defShouldRenderForPlayback = (audio: AudioHandler): ShouldRender => {
  let shouldRenderState = true;
  let prevNumberTimes = 0;

  const shouldRender: ShouldRender = {
    get: () => {
      const numEvents = audio.getPlaybackEvents().length;
      const didChange = numEvents !== prevNumberTimes;
      prevNumberTimes = numEvents;
      return shouldRenderState || numEvents > 0 || didChange;
    },
    set: () => (shouldRenderState = true),
    reset: () => (shouldRenderState = false),
  };

  return shouldRender;
};

export const defWidget = (
  gestureHandler: GestureHandler,
  render: WidgetRender,
  shouldRender: ShouldRender,
): Widget => {
  const canvas = gestureCanvasFactory(gestureHandler);
  let cachedCmp: ReturnType<typeof render> | undefined = undefined;

  const widget: Widget = {
    cmp: (width: number, height: number) => {
      if (cachedCmp === undefined || shouldRender.get()) {
        cachedCmp = render(canvas, width, height);
        shouldRender.reset();
      }

      return cachedCmp;
    },
    triggerRender: () => shouldRender.set(),
  };

  return widget;
};
