import { map } from "@thi.ng/transducers";
import { IRect } from "../../../../../../api";
import { IChorusVoice } from "../../../../app-main/api";
import { chorusWave } from "./chorus-wave";

const DEBUG = false;

export const chorusWaves = (
  voices: Readonly<IChorusVoice[]>,
  rect: IRect,
  selectedIds: number[],
  hoveredId: number | undefined,
) => {
  const sorted = [...structuredClone(voices)].sort((voiceA, voiceB) => {
    // normal first, then hovered, then selected

    // prettier-ignore
    /* eslint-disable no-multi-spaces */
    return selectedIds.includes(voiceA.id) ?  1
         : selectedIds.includes(voiceB.id) ? -1
         : hoveredId === voiceA.id    ?  1
         : hoveredId === voiceB.id    ? -1
         :                                    0;
    /* eslint-enable no-multi-spaces */
  });

  return [
    ...map((voice: IChorusVoice) => {
      // prettier-ignore
      /* eslint-disable no-multi-spaces */
      const state = selectedIds.includes(voice.id) ? "grab" 
                  : hoveredId === voice.id    ? "hover" 
                  :                                  "normal";
      /* eslint-enable no-multi-spaces */
      return [
        chorusWave(rect, voice.gain, voice.speed, state),
        DEBUG
          ? [
              [
                "text",
                {
                  fill: "white",
                  baseline: "bottom",
                  font: "12px -apple-system, BlinkMacSystemFont, sans-serif",
                },
                [rect.x, rect.y + (1 - voice.gain) * rect.h],
                `${Object.entries(voice).reduce((accum, [key, value]) => {
                  return `${accum} | ${key}: ${value.toFixed(key === "id" ? 0 : 2)}`;
                }, "")}`,
              ],
            ]
          : null,
      ];
    }, sorted),
  ];
};
