import { ISyncDoneMessageDef, ISyncMessageDef, ISyncResponseMessageDef } from "./api";
import { StayAliveSocket } from "./StayAliveSocket";

interface ITiming {
  offsetMs: number;
  roundtripMs: number;
}

const SERVER_OFFSET_THRESHOLD_MS = 30;

export class Syncer {
  public static get syncLimit() {
    return 10;
  }

  protected readonly socket: StayAliveSocket;
  protected clientRequestTime: number; // client's request packet transmission,
  protected _syncCount: number;
  protected _isSynced: boolean;
  protected timings: ITiming[];
  protected _serverOffsetMs: number;

  constructor(socket: StayAliveSocket) {
    this.socket = socket;
    this.clientRequestTime = 0;
    this._syncCount = 0;
    this._serverOffsetMs = 0;
    this._isSynced = false;
    this.timings = [];

    this.syncIteration = this.syncIteration.bind(this);
  }

  get syncCount() {
    return this._syncCount;
  }

  get isSynced() {
    return this._isSynced;
  }

  get serverOffsetMs() {
    return this._serverOffsetMs;
  }

  // overrides
  /* eslint-disable @typescript-eslint/no-empty-function */
  // prettier-ignore
  public onSyncDone() {}
  /* eslint-enable @typescript-eslint/no-empty-function */

  public startSync() {
    this.syncIteration();
  }

  public handleMessage(timeNowMs: number, message: ISyncResponseMessageDef) {
    const clientResponseTime = timeNowMs; // client's response packet reception.
    const serverReceiveTime = message.data.serverResponseTime; // server's request packet reception,
    const serverResponseTime = message.data.serverResponseTime; // server's response packet transmission and

    const offsetMs =
      (serverReceiveTime - this.clientRequestTime + (serverResponseTime - clientResponseTime)) / 2;

    const roundtripMs =
      clientResponseTime - this.clientRequestTime - (serverResponseTime - serverReceiveTime);

    this.timings.push({ offsetMs, roundtripMs });
    this.syncIteration();
  }

  public reset() {
    this._syncCount = 0;
    this.timings = [];
    this._isSynced = false;
  }

  protected syncIteration() {
    if (this._syncCount < Syncer.syncLimit) {
      this.clientRequestTime = Date.now();
      this.socket.sendMessage<ISyncMessageDef["type"], ISyncMessageDef["data"]>({
        type: "Sync",
        data: undefined,
      });
      this._syncCount++;
      return;
    }

    // When finished
    this._isSynced = true;

    const fastestTrip = this.timings.slice(1).reduce((previous, current) => {
      return current.roundtripMs < previous.roundtripMs ? current : previous;
    }, this.timings[0]);

    this._serverOffsetMs =
      Math.abs(fastestTrip.offsetMs) <= SERVER_OFFSET_THRESHOLD_MS ? 0 : fastestTrip.offsetMs;

    this.socket.sendMessage<ISyncDoneMessageDef["type"], ISyncDoneMessageDef["data"]>({
      type: "SyncDone",
      data: { serverOffsetMs: this._serverOffsetMs },
    });

    this.onSyncDone();
  }
}
