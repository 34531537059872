import { IObjectOf } from "@thi.ng/api";
import { Atom } from "@thi.ng/atom";

import { ClientMode, Synth } from "../api";
import { addThrottledWatch } from "../utils/addThrottledWatch";
import { Device, getDevice } from "../utils/getDevice";
import { getServerUrl } from "../utils/getServerUrl";
import { handleWebSocketPayload } from "../utils/handleWebSocketPayload";
import { reset } from "../utils/reset";
import {
  IChannel,
  IMessage,
  IMessageDef,
  IMusicianState,
  isChannelsMessage,
  isClientModeMessage,
  isControlsVisibleMessage,
  isIMessage,
  isLoadBuffersMessage,
  isMusicianNameMessage,
  isNoteMessage,
  isPlayBufferMessage,
  isReloadMessage,
  isStopBufferMessage,
  isSyncResponseMessage,
  MusicianMessageType,
} from "./api";
import { StayAliveSocket } from "./StayAliveSocket";
import { Syncer } from "./Syncer";

export class Musician {
  public readonly device: Device;
  public readonly state: Atom<IMusicianState>;

  public readonly syncer: Syncer;

  protected getElapsedTimeS: () => number;
  protected socket: StayAliveSocket;

  constructor(
    serverUrl: string,
    getElapsedTimeS: Musician["getElapsedTimeS"],
    initialState: IMusicianState,
  ) {
    this.state = new Atom(initialState);
    this.device = getDevice(navigator.userAgent);
    this.getElapsedTimeS = getElapsedTimeS;

    this.socket = new StayAliveSocket(getServerUrl("musician", serverUrl));
    this.socket.onMessage = this.handleMessage.bind(this);
    this.socket.onOpen = () => this.syncer.startSync();

    const update = (state: IMusicianState) => {
      this.sendMessage<"Update", IMusicianState>({
        type: "Update",
        data: state,
      });
    };

    this.syncer = new Syncer(this.socket);
    this.syncer.onSyncDone = () => update(this.state.deref());

    addThrottledWatch(this.state, "update", 250, (_, oldState, state) => {
      update(state);
      // Reload on channel change to clear AudioBuffer leak RAM on mobile safari
      if (oldState.channelId !== state.channelId) {
        location.reload();
      }
    });

    const onProblem = () => this.syncer.reset();
    this.socket.onClose = onProblem;
    this.socket.onError = onProblem;

    this.socket.openConnection();
  }

  public get serverUrl() {
    return this.socket.serverUrl;
  }

  get isConnected() {
    return this.socket.isConnected;
  }

  get visualLatencyMs() {
    return this.state.deref().visualLatencyMs;
  }
  set visualLatencyMs(visualLatencyMs) {
    reset(this.state, { visualLatencyMs });
  }

  get audioLatencyMs() {
    return this.state.deref().audioLatencyMs;
  }
  set audioLatencyMs(audioLatencyMs) {
    reset(this.state, { audioLatencyMs });
  }

  get bufferLoadingStates() {
    return this.state.deref().bufferLoadingStates;
  }
  set bufferLoadingStates(bufferLoadingStates: IObjectOf<number>) {
    reset(this.state, { bufferLoadingStates });
  }

  get isArmed() {
    return this.state.deref().isArmed;
  }
  set isArmed(isArmed) {
    reset(this.state, { isArmed });
  }

  // overrides
  /* eslint-disable @typescript-eslint/no-empty-function */
  // prettier-ignore
  public onNote( _synth: Synth, _note: number, _startTimeS: number, _durationS: number, _gain: number) {}
  public onClientModeMessage(_mode: ClientMode) {}
  public onControlsVisibleMessage(_visible: boolean) {}
  public onChannelsMessage(_channels: IChannel[]) {}
  public onLoadBuffersMessage(_urls: string[]) {}
  // prettier-ignore
  public onPlayBuffer(_messageId: IMessage["id"], _url: string, _absoluteStartTimeS: number, _gain: number) {}
  public onStopBuffer(_playMessageId: IMessage["id"]) {}
  /* eslint-enable @typescript-eslint/no-empty-function */

  protected sendMessage<T_type extends MusicianMessageType, T_data>(
    def: IMessageDef<T_type, T_data>,
  ) {
    if (this.isConnected && this.syncer.isSynced) {
      this.socket.sendMessage<T_type, T_data>(def);
    }
  }

  protected handleMessage(event: MessageEvent) {
    const timeNowMs = Date.now();

    handleWebSocketPayload(event.data, (message: unknown) => {
      if (!isIMessage(message)) {
        return;
      }

      if (isSyncResponseMessage(message)) {
        this.syncer.handleMessage(timeNowMs, message);
        reset(this.state, { serverOffsetMs: this.syncer.serverOffsetMs });
      } else if (isNoteMessage(message)) {
        if (!this.syncer.isSynced) {
          return;
        }

        const { timestampMs, data } = message;
        const { synth, note, durationS, gain } = data.note;
        const noteStartTimeS = this.calculateStartTimeS(timeNowMs, timestampMs, data.startTimeS);

        this.onNote(synth, note, noteStartTimeS, durationS, gain);
      } else if (isClientModeMessage(message)) {
        this.onClientModeMessage(message.data.mode);
      } else if (isChannelsMessage(message)) {
        this.onChannelsMessage(message.data.channels);
      } else if (isControlsVisibleMessage(message)) {
        this.onControlsVisibleMessage(message.data.visible);
      } else if (isLoadBuffersMessage(message)) {
        this.onLoadBuffersMessage(message.data.urls);
      } else if (isPlayBufferMessage(message)) {
        const { timestampMs, data } = message;
        const absoluteStartTimeS = this.calculateStartTimeS(
          timeNowMs,
          timestampMs,
          data.startTimeS,
        );
        if (absoluteStartTimeS === undefined) {
          return;
        }
        const { url, gain } = message.data;
        this.onPlayBuffer(message.id, url, absoluteStartTimeS, gain);
      } else if (isStopBufferMessage(message)) {
        this.onStopBuffer(message.data.playMessageId);
      } else if (isMusicianNameMessage(message)) {
        if (this.state.deref().name.trim() === "") {
          reset(this.state, { name: message.data.name });
        }
      } else if (isReloadMessage(message)) {
        location.reload();
      } else {
        // console.log("unknown message");
        // console.log("message:", message);
      }
    });
  }

  protected calculateStartTimeS(
    timeNowMs: number,
    timestampMs: number,
    startTimeS: number,
  ): number {
    const latencyOffsetS = (timeNowMs - timestampMs) / 1000;
    const relativeTimeS = startTimeS - latencyOffsetS;
    const serverOffsetS = this.syncer.serverOffsetMs / 1000;
    const offsetTimeS = relativeTimeS - serverOffsetS;
    const deviceLatencyTimeS = this.audioLatencyMs / 1000;

    const elapsedTimeS = this.getElapsedTimeS();
    const noteStartTimeS = elapsedTimeS + offsetTimeS - deviceLatencyTimeS;
    return noteStartTimeS;
  }
}
