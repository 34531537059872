import { mapcat, range } from "@thi.ng/transducers";
import { IRect, Point } from "../../../../../../api";
import { ILFOState } from "../../../../app-main/api";

export const topComponent = (
  state: ILFOState,
  bodyRect: IRect,
  lineWeight: number,
  halfLineWeight: number,
  clampPoint: (point: Point, bodyRect_: IRect) => Point,
) => {
  return [
    "path",
    { stroke: "rgb(43, 156, 212)", weight: lineWeight },
    [
      ...Array.from(
        mapcat((i) => {
          const step = 1 / state.frequency;
          const width = 0.5;
          const stepWidth = step * width;
          const movePoint = clampPoint([i * step, 0.0], bodyRect);
          const linePoint = clampPoint([i * step + stepWidth, 0.0], bodyRect);

          movePoint[1] += halfLineWeight;
          linePoint[1] += halfLineWeight;
          return [
            ["M", movePoint],
            ["L", linePoint],
          ];
        }, range(state.frequency)),
      ),
    ],
  ];
};
